import React from "react";
import "./herosection.style.css";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const navigateToSubjects = () => {
    navigate("/all-doctor");
  };

  return (
    <section id="landing-banner">
      <div
        className="landing-container"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <h1>
          Not Just a Doctor - <span className="mobile-header">Your Doctor</span>
        </h1>
        <h2>Save time. Save money. Live healthier. That's Becurable</h2>
        <div className="d-flex">
          <button className="btn btn-find-doctor" onClick={navigateToSubjects}>
            Find Your Doctor
          </button>
        </div>
      </div>
    </section>
  );
};
export default HeroSection;
