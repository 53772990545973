import React from "react";
import "./profile-status.style.css";

const ProfileStatus = () => {
  const completeness = 60;
  return (
    <div className="profile-status">
      <div className="profile-info">
        <div className="center-content">
          <img
            className="statusImage"
            src="/assets/AsadWali.jpg"
            alt="doctor"
          />
          <h5 className="mt-2">Dr. Asad Wali</h5>
          <p className="tight">Doctor ID : 123456 </p>
        </div>
        <div className="profile-completeness">
          <div className="status-bar">
            <div className="filled" style={{ width: `${completeness}%` }}></div>
          </div>
          <span className="percentage">60%</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileStatus;
