import React from "react";
import './our-vision.style.css';

const OurVision = () => {
    return (
        <section id="our-vision" className="mt-5 mb-5">
            <div className="container">
                <h1>Our Vision</h1>
                <p>
                    Lack of access to convenient healthcare is a growing problem across the world. To see a capable
                    physician, one may have to wait weeks at a time. To see your average General Physician, the waiting time
                    can extend to hours. The time frame to see a psychiatrist is even longer. These inconveniences result in
                    one ignoring healthcare. Becurable understand that patients are not getting the care they need
                    and we are here to change that.
                </p>
                <p>
                    We make it our mission to accomplish this with uncompromised quality and variety of services. At ITechia
                    Care, our Doctors undergo a thorough screening before they are inducted into our problem so we can
                    offer the best consultations regarding your health. Our Doctors are professional, verified and as such are
                    capable of treating any medical problem.
                </p>
            </div>
        </section>
    )
}
export default OurVision;