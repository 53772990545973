import React from "react";
import { Link } from "react-router-dom";
import './ongoing-care.style.css';

const OngoingCare = () => {
    return (
        <section id="ongoing-care">
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-lg-6 order-1 order-lg-1 img" style={{ backgroundImage: `url(${"/assets/webImages/ongoingcare.jpg"})` }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                    <div className="col-lg-6 order-2 order-lg-2">
                        <div className="content">
                            <h2>We believe holistic,
                                ongoing care keeps
                                you healthier</h2>
                            <p>
                                Your personal doctor leads a
                                comprehensive care team to
                                ensure you always get the help
                                you need, from managing
                                ongoing conditions and prescriptions to prevention and referrals.
                            </p>
                            <div className="d-flex">
                                <Link to="/specializations" className="link-button">See what we treat</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OngoingCare;