import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EducationModal = () => {
  const initialValues = {
    title: "",
    provider: "",
    date: "",
  };

  const onSubmit = () => {};
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required!"),
    provider: Yup.string().required("Provider is required!"),
    date: Yup.string().required("Date is required!"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form
          className="modal fade"
          id="CertificateModal"
          tabindex="-1"
          aria-labelledby="AppModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AppModalLabel">
                  Add Your Education
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Title</label>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Orthopedic"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="title" />
                  </div>
                </div>

                <div className="form-group">
                  <label>Provider</label>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="provider"
                    id="provider"
                    placeholder="Fatima Memorial Hospital"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="provider" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Date Completed</label>
                  <Field
                    className="form-input placeholder"
                    type="Date"
                    name="date"
                    id="date"
                    placeholder="01/01/2021"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="date" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" className="btn modal_btn btn-sm">
                  Add
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default EducationModal;
