import React from "react";
import { Link } from "react-router-dom";
import { MdDashboard, MdEditNote } from "react-icons/md";
import { FaUserAlt, FaCalendarAlt, FaListAlt, FaUsers } from "react-icons/fa";

const ProviderSidebar = () => {
    const isActive = (path) => window.location.pathname === path;

    return (
      <div className="sidebar">
        <div className="mb-4">
        <Link to="/">
          <img className="iconlogoimage" src="/assets/logo2.png" alt="logo" />
          <img className="iconlogoimage" src="/assets/iconlogo2.png" alt="logo" />
          </Link>
        </div>
        <nav className="d-flex flex-column gap-2">
          <Link className={`sidebar-link ${isActive('/dashboard') ? 'active' : ''}`} to="/dashboard">
            <MdDashboard />
            <span className="sidebar-text d-none d-lg-inline-block">
              Dashboard
            </span>
          </Link>
  
          <Link className={`sidebar-link ${isActive('/profile') ? 'active' : ''}`} to="/profile">
            <FaUserAlt />
            <span className="sidebar-text d-none d-lg-inline-block">Profile</span>
          </Link>
  
          <Link className={`sidebar-link ${isActive('/appointments') ? 'active' : ''}`} to="/appointments">
            <FaListAlt />
            <span className="sidebar-text d-none d-lg-inline-block">
              Appointment
            </span>
          </Link>
  
          <Link className={`sidebar-link ${isActive('/mypatients') ? 'active' : ''}`} to="/mypatients">
            <FaUsers />
            <span className="sidebar-text d-none d-lg-inline-block">
              My Patients
            </span>
          </Link>
  
          <Link className={`sidebar-link ${isActive('/calender') ? 'active' : ''}`} to="/calender">
            <FaCalendarAlt />
            <span className="sidebar-text d-none d-lg-inline-block">
              Calender
            </span>
          </Link>
  
          <Link className={`sidebar-link ${isActive('/earnings') ? 'active' : ''}`} to="/earnings">
            <MdEditNote />
            <span className="sidebar-text d-none d-lg-inline-block">
              My Earnings
            </span>
          </Link>
        </nav>
      </div>
    );
}

export default ProviderSidebar;