import React from "react";
import "./patient-wallet.style.css";

const TransactionList = () => {
  return (
    <div className="mt-5 transaction-list">
      <h2>Transactions</h2>
      <div className="list-card">
        <div className="list-card-content d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              className="transactionimage me-3"
              src="/assets/AsadWali.jpg"
              alt="tasks"
            />
            <div>
              <h6>Appointment Booking</h6>
              <small>Dr. Asad Wali</small>
              <small className="ms-4"> 26 Aug,2023</small>
            </div>
          </div>
          <div className="">
            <h5>PKR 5000</h5>
            <small>by VISA</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionList;
