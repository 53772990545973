import React from "react";
import { Link } from "react-router-dom";
import './provider-banner.style.css';

const ProviderBanner = () => {
    return (
        <section id="provider-banner">
            <div className="container provider-container" data-aos="fade-up" data-aos-delay="150">
                <h2>We are always on the lookout for healthcare professionals
                    to become a part of our telehealth platform. A platform that
                    connects you with patients using cutting-edge technology</h2>
                <div className="d-flex">
                    <Link to="/login" className="btn btn-find-doctor">Register Now</Link>
                </div>
            </div>
        </section>

    )
}

export default ProviderBanner;
