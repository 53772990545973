import React from "react";
import './works-steps.style.css';
import workingsteps from '../../shared/worksteps.json'

const WorksSteps = () => {
    return (
        <section className="wroking-steps">
            <div className="container text-center justify-content-center align-items-center">
                <h1>How it Works</h1>
                <div className="row mt-5 text-center justify-content-center align-items-center">
                    {workingsteps.map((data) => {
                        return (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="card">
                                    <p className="counting">{data.number}</p>
                                    <div className="card-body">
                                        <h5 className="card-title">{data.heading}</h5>
                                        <p className="card-text">{data.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default WorksSteps;