import React from "react";
import TermsBanner from "./terms-banner/terms-banner.component";
import TermsServices from "./terms-n-services/terms-n-services.component";

const TermsOfService = () => {
    return (
        <>
            <TermsBanner />
            <TermsServices />
        </>

    )
}

export default TermsOfService;