import React from 'react'
import './diseases-banner.style.css';

const DiseasesBanner = () => {
    return (
        <section className='container'>
        <div className="diseases-container" data-aos="fade-up" data-aos-delay="150">
          <h1>Our Healthcare <span>Service </span></h1>
          <p>Explore the healthcare services. Be Curable provides as Pakistan’s first 
              Healthcare-as-a-Service Provider.</p>
        </div>
      </section>
    )
} 

export default DiseasesBanner;