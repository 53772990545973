import React from "react";
import "./step2.style.css";

const Step2 = () => {
  return (
    <section id="step-2">
      <div className="container">
        <div className="row pt-5 pb-5">
          <div
            className="col-lg-6 order-1 order-lg-1 img"
            style={{
              backgroundImage: `url(${"/assets/webImages/doctors.png"})`,
            }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
          <div className="col-lg-6 order-2 order-lg-2">
            <div className="content">
              <div className="number-center">
                <p className="counting">2</p>
              </div>
              <h2>Schedule An Appointment</h2>
              <p>
                Browse through the doctor’s database and choose an appropriate
                doctor. Alternatively, simply click “Book Appointment” to
                schedule an appointment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step2;
