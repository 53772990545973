import React, { useCallback, useState, useEffect } from "react";
import { Card, Grid } from "@material-ui/core";
import { Box, styled } from "@mui/system";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../auth.style.css";
import { useDispatch } from "react-redux";
import Loader from "../../../shared/loader";
// import Toaster from "../../../shared/toaster";
import { useLoginMutation } from "../../../shared/api/rtk";
import { updateUser } from "../../../shared/redux/reducers/users.reducer";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format!").required("Required!"),
  password: Yup.string().required("Required!"),
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0)",
}));
const Section = styled(JustifyBox)(() => ({
  background: "#011F4B",
  minHeight: "100vh !important",
  position: "relative",
  "& .card": {
    maxWidth: 1013,
    borderRadius: 20,
    margin: "1rem",
  },
}));

const LoginComponent = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [login, { isLoading, isSuccess, isError, error }] = useLoginMutation();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/otp");
    }
  }, [navigate, isSuccess]);

  const onSubmit = useCallback(
    async values => {
      try {
        const formData = new FormData();
        formData.append("Email", values.email);
        formData.append("Password", values.password)
        await login(formData).unwrap();
        dispatch(updateUser({ email: values.email, password: values.password}))
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, login]
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.message || error?.data || "Something Went Wrong!")
    }
  }, [isError, error])

  return (
    <Section>
      <Link
        to="/"
        style={{
          position: "absolute",
          top: 20,
          left: 30,
          textDecoration: "none",
          color: "#ffffff",
        }}
        className="landing-link"
      >
        <img
          src="/assets/icons/back_arrow.svg"
          className="me-3"
          alt="back arrow"
          style={{
            height: "30px",
            width: "30px",
            filter: "brightness(0) invert(1)",
          }}
        />
        Go to Main Website
      </Link>
      <Card className="card">
        <Grid container>
          <Grid
            item
            lg={5}
            md={5}
            sm={5}
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <JustifyBox>
              <div className="auth-logo-container">
                <img
                  src="./assets/logo.png"
                  alt="logo"
                  className="auth-logo"
                />
              </div>
            </JustifyBox>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <ContentBox>
              <h1>Login To Your Account</h1>
              <p>Please enter your login credentials below</p>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form className="">
                      <Field
                        className="form-input placeholder"
                        type="text"
                        name="email"
                        id="name"
                        placeholder="Email Address"
                      />
                      <div className="text-danger text-start">
                        <ErrorMessage name="email" />
                      </div>
                      {/* Password Field */}
                      <div className="auth-passwordfield">
                        <Field
                          className="form-input placeholder"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                        />
                        <img
                          className="eyeicon"
                          src={
                            showPassword
                              ? "/assets/icons/eyeopen.svg"
                              : "/assets/icons/eyeclose.svg"
                          }
                          alt="Toggle password visibility"
                          onClick={handlePasswordVisibility}
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="password" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="content col-sm">
                          <p>
                            Don’t have an account
                            <Link to="/signup">
                              <span className="login_span ms-1">Sign Up</span>
                            </Link>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Link to="/forgot-password">
                            <p>Forgot Password ?</p>
                          </Link>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button className="btn form_btn" type="submit" disabled={isLoading}>
                            Login
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
      <Loader loading={isLoading} />
    </Section>
  );
};

export default LoginComponent;
