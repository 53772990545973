import React from "react";
import { Link } from "react-router-dom";
import "./mobile-navbar.style.css";
import { MdDashboard } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";

const AdminMobileMenu = ({ toggleMenu }) => {
  return (
    <div className="overlay">
      <div className="menu">
        <div className="d-flex justify-content-between align-items-center mx-3 my-2 mb-4">
          <img className="logoimage" src="/assets/logo.png" alt="logo" />
          <img
            className="crossimage"
            src="/assets/icons/X.svg"
            alt="close"
            onClick={toggleMenu}
          />
        </div>
        <div className="menu-content-wrapper">
          <div className="user-info d-flex">
            <img className="userImage" src="/assets/AsadWali.jpg" alt="user" />
            <div className="ms-3">
              <h5>Admin Asad Wali</h5>
              {/* <p>Doctor ID: 12345678</p> */}
            </div>
          </div>
          <nav className="d-flex flex-column gap-2">
            <a className="menu-link" href="/#/dashboard" onClick={toggleMenu}>
              <MdDashboard />
              <span className="menu-text">Dashboard</span>
            </a>
            <a className="menu-link" href="/#/allusers" onClick={toggleMenu}>
              <FaUserAlt />
              <span className="menu-text">All Users</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AdminMobileMenu;
