import React from "react";
import "./vitals.style.css";
import { Link } from "react-router-dom";
const Vitals = () => {
  return (
    <>
      <Link to="/bp">
        <div className="row mb-4">
          <div className="col-2">
            <div className="d-flex">
              <div className="card vitalscard">
                <img src="/assets/vitals/bloodpressure.png" alt="disease" />
              </div>
            </div>
          </div>
          <div className="col-10 d-flex align-items-center justify-content-between">
            <div>
              <h2>Blood Pressure</h2>
              <h3>169/79 mm Hg</h3>
              <small>25 min ago</small>
            </div>
            <img src="/assets/icons/next.png" alt="next" className="nexticon" />
          </div>
        </div>
      </Link>
      <div className="row">
        <div className="col-2">
          <div className="d-flex">
            <div className="card vitalscard">
              <img src="/assets/vitals/bloodpressure.png" alt="disease" />
            </div>
          </div>
        </div>
        <div className="col-10 d-flex align-items-center justify-content-between">
          <div>
            <h2>Blood Sugar</h2>
            <h3>169 mg/dL</h3>
            <small>4 days ago</small>
          </div>
          <img src="/assets/icons/next.png" alt="next" className="nexticon" />
        </div>
      </div>
    </>
  );
};

export default Vitals;
