import React from "react";
import "./step1.style.css";

const Step1 = () => {
  return (
    <section id="step-1">
      <div className="container">
        <div className="row pt-5 pb-5">
          <div
            className="col-lg-6 order-1 order-lg-2 img"
            style={{
              backgroundImage: `url(${"/assets/webImages/signup.jpg"})`,
            }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="content">
              <div className="number-center">
                <p className="counting">1</p>
              </div>
              <h2>Create Your Free Account</h2>
              <p>
                Register your free account at www.becurable.com or download the
                app from the Google Play Store or App Store.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step1;
