import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./specialization.style.css";
import specilaization from "../../shared/specializations.json";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Specialization = () => {
  const navigate = useNavigate();

  const navigateToSubjects = () => {
    navigate("/specializations");
  };

  // State to track the current screen width
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Update the isMobile state when the screen size changes
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Number of slides to show in the slider
    slidesToScroll: 2, // Number of slides to scroll when navigating
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="main-subjects">
      <div className="container mt-5 mb-5">
        <div className="content text-center justify-content-center align-items-center">
          <h1>Care for you and your family</h1>
          <p>
            Your personal doctor can help with everything from chronic illness
            to family health, prescription needs and more.
          </p>
        </div>
        <div>
          {isMobile ? (
            <Slider {...settings}>
              {specilaization.slice(0, 6).map((data) => (
                <div key={data.name} className="d-flex justify-content-center">
                  <div className="card servicesCards">
                    <div className="d-flex align-items-center">
                      <img
                        src={data.image}
                        alt="specializations"
                        className="card-image"
                      />
                      <div className="card-text-content">
                        <p className="card-title tight"> {data.name} </p>
                        <p className="tight">{data.count}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="row mt-5 g-3">
              {specilaization.slice(0, 6).map((data) => (
                <div key={data.name} className="d-flex justify-content-center col-lg-2 col-md-4 col-sm-6">
                <Link to="/all-doctor">
                  <div className="card servicesCards">
                    <div className="d-flex align-items-center">
                      <img
                        src={data.image}
                        alt="specializations"
                        className="card-image"
                      />
                      <div className="card-text-content">
                        <p className="card-title tight"> {data.name} </p>
                        <p className="tight">{data.count}</p>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="text-center mt-5">
          <button className="btn btn-find-doctor" onClick={navigateToSubjects}>
            See More
          </button>
        </div>
      </div>
    </section>
  );
};

export default Specialization;
