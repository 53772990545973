import React from "react";
import DashboardCalendar from "./calender/calender.component";
import GreetingCard from "./greetings-card/greetings-card.component";
import ChartInfo from "./info-chart/info-chart.component";

const AdminDashboard = () => {
  return (
    <>
      <GreetingCard />
      <div className="row">
        <div className="col-lg-8">
          <div className="list-container">
            <ChartInfo />
          </div>
        </div>
        <div className="col-lg-4">
          <DashboardCalendar />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
