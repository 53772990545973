import React from "react";
import "./my-earnings.style.css";

const MyEarning = () => {
  return (
    <>
      {/* <div className='card earning-card mb-4'>
                <span>
                    <small>Net Income</small>
                    20000 PKR
                </span>
                <span>
                    <small>Withdrawn</small>
                    2000 PKR
                </span>
                <span>
                    <small>Pending Clearnace</small>
                    2000 PKR
                </span>
                <span>
                    <small>Available For Withdraw</small>
                    2000 PKR
                </span>
            </div> */}

      <div className="row gy-3 mb-5">
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="net-income">
            <h5>Net Income</h5>
            <h3>PKR 150,000</h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="net-income">
            <h5>Withdrawn</h5>
            <h3>PKR 10,000</h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="net-income">
            <h5>Pending Clearnace</h5>
            <h3>PKR 50,000</h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6">
          <div className="net-income">
            <h5>Available For Withdrawn</h5>
            <h3>PKR 150,000</h3>
          </div>
        </div>
      </div>

      {/* <div className="withdraw d-flex mb-5">
        <h3>WITHDRAW METHOD:</h3>
        <button className="btn btn-view ms-3">Paypal Account</button>
        <button className="btn btn-view ms-3">Bank Account</button>
      </div> */}

      <div className="transaction-details">
        <h5>Transactions</h5>
        <div className="transaction-lists">
          <div className="list-content">
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img src="/assets/AsadWali.jpg" alt="user" />
                <div className="ms-3">
                  <h6>Asad Wali</h6>
                  <p className="tight">23 March, 2023</p>
                </div>
              </div>
              <div>
                <h6>PKR 500</h6>
                <p className="tight completed">Completed</p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img src="/assets/AsadWali.jpg" alt="user" />
                <div className="ms-3">
                  <h6>Asad Wali</h6>
                  <p className="tight">12 September, 2023</p>
                </div>
              </div>
              <div>
                <h6>PKR 500</h6>
                <p className="tight completed">Completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEarning;
