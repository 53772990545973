import React from "react";
import "./copyrights.style.css";

const Copyright = () => {
  return (
    <footer id="footer-nav">
      <div className="container">
        <h5 className="mb-0 text-center">
          Copyright © Design and Develop By ITechia Solutions
        </h5>
      </div>
    </footer>
  );
};

export default Copyright;
