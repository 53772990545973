import React, { useState } from "react";
import "./my-doctors.style.css";
import doctorsData from "../../components/shared/alldoctors.json";
import { Link } from "react-router-dom";
const AllDoctors = () => {
  return (
    <div className="my-doctors">
      <div className="my-doctors-card">
        <div className="card-info d-flex justify-content-between">
          <div className="d-flex">
            <img className="cardimage" src="/assets/AsadWali.jpg" alt="user" />
            <div className="ms-3">
            <div className="d-flex justify-content-between">
              <h5>Dr. Asad Wali</h5>
              </div>
              <p className="tight">Cardiologist</p>
              <p className="tight">Lahore, Pakistan</p>
              <p className="tight">
                <span className="tight me-3">⭐ 5.0</span>
                (135 reviews)
              </p>
              <p className="tight fw-bolder">
                Trusted And Verified By Us
                <img
                  className="verified-icon"
                  src="/assets/icons/verified.png"
                  alt="verified"
                />
              </p>
            </div>
          </div>
          <div>
            <p className="tight">Fee: 500 PKR</p>
          </div>
        </div>
        <div className="mt-3 d-inline-flex">
          <p>Specializations:</p>
          <p className="badge ms-2 text-dark rounded-3 ps-2 pe-2">MBBS</p>
          <p className="badge ms-2 text-dark rounded-3 ps-2 pe-2">FCPS</p>
        </div>
        <p>
          I have over 5 years of experience as a Specialized Cardiologist. I can
          treat my patients with extra care and provide better solutions. I have
          over 5 years of experience as a Specialized Cardiologist. I can treat
          my patients with extra care and provide better solutions. I have over
          5 years of experience as a Specialized Cardiologist.
        </p>
        <div className="d-flex">
          <button className="btn btn_join">View Profile</button>
          <button className="btn btn_join ms-3">Re-Book Appointment</button>
        </div>
      </div>
    </div>
  );
};

export default AllDoctors;
