import React from "react";
import "./contact-details.style.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const initialValues = {
  username: "",
  email: "",
  number: "",
  message: "",
};
const onSubmit = () => {};
const validationSchema = Yup.object({
  username: Yup.string().required("username required!"),
  email: Yup.string()
    .email("Invalid email format!")
    .required("Email is required!"),
  number: Yup.string().required("Mobile number is required!"),
  message: Yup.string().required("Message is required"),
});

const ContactDetails = () => {
  return (
    <section className="contact-details contact-card">
      <div className="container">
        <div className="py-5">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 left_side">
              <div className="mt-4">
                <img className="logo2image" src="/assets/logo2.png" alt="logo"/>
              </div>
              <div>
                <h4>Customer Service</h4>
                <p>+92 306 4077 401</p>
              </div>
              <div>
                <h4>Headquater</h4>
                <p>
                  Johar Town, <br /> Lahore, Pakistan
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 right_side">
              <span className="h1">Contact Us</span>
              <p className="text-dark fw-bolder ms-1">we are here to serve</p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="name" />
                  </div>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="email"
                    id="name"
                    placeholder="Email"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="email" />
                  </div>
                  <Field
                    className="form-input placeholder"
                    type="number"
                    name="phoneNumber"
                    id="name"
                    placeholder="Mobile Number"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="phoneNumber" />
                  </div>
                  <Field
                    className="form-input placeholder textarea"
                    id="name"
                    as="textarea"
                    rows="4"
                    type="text"
                    name="message"
                    placeholder="Your Message"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="message" />
                  </div>
                </Form>
              </Formik>
              <button className="form_btn mt-4">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails;
