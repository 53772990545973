import React, { useState, useEffect } from "react";
import "./bmi-calculation.style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import doctors from "../../shared/alldoctors.json";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

const BmiCalculator = () => {
  const [measurement, setMeasurement] = useState("cm"); // "cm" or "ft-in"
  const [heightFt, setHeightFt] = useState("");
  const [heightIn, setHeightIn] = useState("");

  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState(null);
  const [animate, setAnimate] = useState(false);

  const calculateHealthyWeightRange = () => {
    let heightInMeters;

    if (measurement === "ft-in") {
      // Convert feet & inches to meters
      heightInMeters = (heightFt * 12 + Number(heightIn)) * 0.0254;
    } else {
      heightInMeters = height / 100;
    }

    const weightMin = (18.5 * heightInMeters * heightInMeters).toFixed(2);
    const weightMax = (24.9 * heightInMeters * heightInMeters).toFixed(2);
    return [weightMin, weightMax];
  };

  const switchMeasurement = () => {
    if (measurement === "cm") {
      setMeasurement("ft-in");
    } else {
      setMeasurement("cm");
    }
  };

  const calculateBMI = () => {
    let heightInMeters;

    if (measurement === "ft-in") {
      // Convert feet & inches to meters
      heightInMeters = (heightFt * 12 + Number(heightIn)) * 0.0254;
    } else {
      heightInMeters = height / 100;
    }

    const calculatedBmi = (weight / (heightInMeters * heightInMeters)).toFixed(
      2
    );
    setAnimate(false); // reset animation state
    setTimeout(() => {
      setBmi(calculatedBmi);
      setAnimate(true); // trigger the animation
    }, 50);
  };

  const getPointerPosition = () => {
    if (bmi < 18.5) return "5%"; // Adjust the starting position based on your design
    if (bmi < 25) return `${5 + ((bmi - 18.5) * 60) / 6.5}%`; // Linear interpolation
    if (bmi < 30) return `${65 + ((bmi - 25) * 27) / 5}%`;
    return "92%"; // For BMI >= 30
  };

  const getBMIStatus = () => {
    if (bmi < 18.5) return "Underweight";
    if (bmi >= 18.5 && bmi < 24.9) return "Healthy weight";
    if (bmi >= 24.9 && bmi < 30) return "Overweight";
    return "Obese";
  };

  const [weightMin, weightMax] = calculateHealthyWeightRange();

  const [displayMode, setDisplayMode] = useState(() => {
    if (window.innerWidth <= 768) return 'mobile';
    if (window.innerWidth <= 1024) return 'tablet';
    return 'desktop';
  });
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) setDisplayMode('mobile');
      else if (window.innerWidth <= 1024) setDisplayMode('tablet');
      else setDisplayMode('desktop');
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

   const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
};


  return (
    <div className="container bmi mt-4">
      <h2 className="text-center pt-3 pb-4">
        Body Mass Index (BMI) Calculator
      </h2>
      <div className="row pb-5 h-100">
        <div className="col-lg-6 order-1 order-lg-1 d-flex align-items-center justify-content-center">
          <div>
            <h5>What is BMI ?</h5>
            <p>
              The body mass index (BMI) is a measure that uses your height and
              weight to work out if your weight is healthy or not.
            </p>
            {/* <img src="/assets/BMI.png" alt="BMI" className="bmi-image"/> */}
          </div>
        </div>
        <div className="col-lg-6 order-2 order-lg-2">
          <div>
            <p>
              If you have or think you might have an{" "}
              <strong>eating disorder</strong>, we advise you not to use the BMI
              Calculator and get further advice from your GP.
            </p>
            <div className="d-flex justify-content-between">
              <label className="label">
                Height {measurement === "cm" ? "(in cm):" : "(in ft, in):"}
              </label>
              <button className="swtichbutton" onClick={switchMeasurement}>
                Switch to {measurement === "cm" ? "ft, in" : "cm"}
              </button>
            </div>

            {measurement === "cm" ? (
              <input
                className="form-input-field placeholder"
                type="number"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                placeholder="Enter your height in cm"
              />
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <input
                    className="form-input-field placeholder mr-2"
                    type="number"
                    value={heightFt}
                    onChange={(e) => setHeightFt(e.target.value)}
                    placeholder="Feet"
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    className="form-input-field placeholder"
                    type="number"
                    value={heightIn}
                    onChange={(e) => setHeightIn(e.target.value)}
                    placeholder="Inches"
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label className="label">Weight (in kg):</label>
            <input
              className="form-input-field placeholder"
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="Enter your weight in kg"
            />
          </div>

          <button className="btn form_btn mt-3" onClick={calculateBMI}>
            Calculate BMI
          </button>

          {bmi && (
            <>
              <div className="mt-3">
                <h6>
                  Your BMI scroe is: <strong>{bmi}</strong>
                </h6>
                <h6>
                  Your result suggests you are a{" "}
                  <strong>{getBMIStatus()}</strong>
                </h6>
              </div>
              <div id="antbits-bmi-results_bar">
                <div
                  id="antbits-bmi-results_bar_pointer"
                  style={{ left: animate ? getPointerPosition() : "0%" }}
                ></div>
                <div id="antbits-bmi-results_bar_bg"></div>
                <div id="antbits-bmi-results_bar_labels">
                  <div style={{ left: "14.7%" }}>
                    <div>18.5</div>
                  </div>
                  <div style={{ left: "65.7%" }}>
                    <div>25</div>
                  </div>
                  <div style={{ left: "92%" }}>
                    <div>30</div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h6>
                  Healthy weight for your height: <strong>{weightMin}kg</strong>{" "}
                  to <strong>{weightMax}kg</strong>
                </h6>
              </div>
            </>
          )}
        </div>
      </div>
      {bmi && (
        <>
          <div className="pb-3">
            <p className="text-center">
              We suggests you to check yourself with our TOP Nutritionist and
              Ditiensit in order to maintain your health.
            </p>
            <div className="container mt-4">
            {displayMode !== 'desktop' ? (
                <Slider {...settings}>
                    {doctors
                      .filter((doc) =>
                        ["Nutritionist", "Dietitian"].includes(doc.specialist)
                      )
                      .map((data, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-center"
                        >
                          <div className="bmi-card">
                            <div className="d-flex">
                              <img src={data.image} alt="doctor" />
                              <div className="ms-3">
                                <h5>{data.name}</h5>
                                <p className="tight">{data.specialist}</p>
                                <p className="tight">
                                  ⭐ {data.rating} ({data.reviews_count})
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </Slider>
              ) : (
                <div className="row">
                  {doctors
                    .filter((doc) =>
                      ["Nutritionist", "Dietitian"].includes(doc.specialist)
                    )
                    .map((data, index) => (
                      <div key={index} className="col-lg-3 col-md-3 col-sm-12">
                        <div className="bmi-card me-3">
                          <div className="d-flex">
                            <img src={data.image} alt="doctor" />
                            <div className="ms-3">
                              <h5>{data.name}</h5>
                              <p className="tight">{data.specialist}</p>
                              <p className="tight">
                                ⭐ {data.rating} ({data.reviews_count})
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BmiCalculator;
