import React from "react";
import PrivacyBanner from "./privacy-banner/privacy-banner.component";
import PrivactStatements from "./privacy-terms/privacy-terms.component";


const PrivacyPolicy = () => {
    return (
        <>
            <PrivacyBanner />
            <PrivactStatements />
        </>

    )
}

export default PrivacyPolicy;