import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const BPForm = () => {
  const initialValues = {
    systolic: "",
    diastolic: "",
    date: "",
    time: "",
  };
  const onSubmit = () => {};
  const validationSchema = Yup.object({
    // firstname: Yup.string().required("firstname required!"),
    // email: Yup.string().email("Invalid email format!").required("Email is required!"),
    // phonenumber: Yup.string().required("Mobile number is required!"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="mx-5">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5 className="text-black">Systolic</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="systolic"
                id="systolic"
                placeholder="Add systolic reading"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="systolic" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5 className="text-black">Diastolic</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="diastolic"
                id="diastolic"
                placeholder="Add diastolic reading"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="diastolic" />
              </div>
            </div>
          </div>
          <div className="row">
            {/* DOB Column */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="text-black">Date</h5>
              <Field
                className="form-input placeholder"
                type="date"
                name="date"
                id="date"
                placeholder="Date Of Report"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="date" />
              </div>
            </div>

            {/* Gender Column */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="text-black">Time</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="time"
                id="time"
                placeholder="Time"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="time" />
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
          <button className="btn form_btn">Add</button>
        </div>
        </div>

       
      </Form>
    </Formik>
  );
};

export default BPForm;
