import React from "react";

const PrivactStatements = () => {
  return (
    <section className="terms-service mt-5 mb-5">
      <div className="container">
        <p>
          Below are the measures Becurable implements to safeguard your privacy:
          Becurable, referred to as "we", "us", or "our", has formulated this
          Privacy Policy in recognition of the importance you place on the
          collection, receipt, handling, sharing, and retention of your
          information. This Policy is applicable to data gathered or received
          through our website (https://Becurable.com) ("the Website") and
          services accessed via our Website and Mobile App ("Services"). This
          Policy is pertinent to website users ("visitors") and those who
          purchase our services. By accessing our Website and/or utilizing our
          Services, Operators and Service Recipients confirm they've reviewed,
          comprehended, and accepted the terms within this Privacy Policy and
          our Terms of Use, which are integrated here.
        </p>

        <h5>Information We Collect</h5>
        <p>
          Becurable collects the following types of information via the Website,
          both directly from you and indirectly through our third party service
          providers (our “Providers“).
        </p>

        <h5>Personal Information</h5>
        <p>
          "Personal Information" refers to any data or collection of data that
          can be used to recognize an individual. When availing the Services,
          you'll need to provide Personal Information like your name, address,
          contact number, and email address. Service Recipients might also need
          to share details about their medical history, encompassing current and
          past health conditions, medications, and illnesses ("Health
          Information"). While we use a third-party service to handle credit and
          debit card transactions, which includes details like expiration dates,
          billing addresses, and activation codes essential for online
          purchases, we don't retain this data. We don't gather Personal
          Information from Visitors, Operators, or Service Recipients unless
          shared willingly. Personal Information excludes details related to
          businesses, encoded or anonymized data, or public details not mixed
          with private Personal Information.
        </p>

        <h5>Other Information</h5>
        <p>
          We gather additional details via the Website and/or the Services that
          don't personally identify you. This includes, but isn't limited to:
          Data like your static or dynamic IP address, which might specify a
          particular computer or device and its operating system. We also track
          the Internet service provider you use, browser specifics, referring
          and exit web pages, timestamps, duration spent on certain pages,
          sections of the Website you explore, number of links clicked during
          Service use, as well as search phrases and keywords. Information
          linked to your mobile device, covering aspects like its universally
          unique ID ("UUID"), platform type (like iOS or Android), model
          details, network provider, geographical location, operating system
          version, hardware specifics (storage capacity, chip speed, camera
          quality, NFC capability) and connection type (WiFi, 2G, 3G, 4G).
          Generic Mobile App Data, such as exam outcomes, medical records, and
          demographic info. If this data aligns with a Service Recipient, it's
          added to their profile for reference.
        </p>

        <h5>How we Use and Share Information</h5>
        <p>
          Becurable utilizes your Personal Information in the following
          ways: Addressing your queries and requests; Enhancing the Website and
          the Services we offer; Notifying you about updates or modifications to
          the Website and/or Services; Dispatching communications (like emails,
          postal mails, etc.) related to the Services you avail, as well as for
          invoicing and collecting payments; Catering to other specific
          intentions mentioned at the point of data collection, which you've
          agreed to; and Any other methods detailed in this Privacy Policy.
        </p>

        <h5>How We Protect your Information</h5>
        <p>
          We employ commercially viable measures to safeguard the Information
          against loss, unauthorized access, misuse, and unauthorized amendments
          or destruction. However, it's essential to recognize that no security
          mechanism is foolproof. The safety of our databases, as well as those
          of third parties we might share Information with, cannot be wholly
          assured. Furthermore, while transmitting data over the Internet,
          there's a possibility of interception. Emails sent to us, in
          particular, may lack robust security. Hence, exercise caution
          regarding the information you communicate to us via email. Should you
          have inquiries, grievances, or concerns about this Privacy Policy,
          please reach out to Becurable. at support@becurable.com. Mention
          "Privacy Policy" in the email subject for prompt attention.
        </p>
      </div>
    </section>
  );
};

export default PrivactStatements;
