import React from "react";
import { HashRouter , Routes, Route } from "react-router-dom";
import LoginComponent from "./components/auth/login/login.component";
import SignUpComponent from "./components/auth/signup/signup.component";
import LandingPage from "./components/landingPage/landingpage.component";
import OTPVerfication from "./components/auth/otp-verification/otp-verification.component";
import DashboardComponent from "./providers-components/dashboard/dashboard.component";
import Aboutus from "./components/about-us/about-us.component";
import BlogDetails from "./components/blogs-detail/blogs-detail.component";
import DoctorsProfile from "./components/doctors-profile/doctors-profile.component";
import AllDoctorList from "./components/all-doctors/all-doctors.componet";
import AllBlogs from "./components/all-blogs/all-blogs.component";
import AllSpecialization from "./components/all-specializations/all-specializations.component";
import ContactUs from "./components/contact-us/contact-us.component";
import MyPatientsList from "./providers-components/my-patients/my-patients.component";
import Profile from "./providers-components/my-profile/my-profile.component";
import Appointments from "./providers-components/appointments/appointments.component";
import MyEarning from "./providers-components/my-earnings/my-earnings.component";
import WhatWeProvide from "./components/pages/what-we-provide/what-we-provide.component";
import BecomeProvider from "./components/pages/become-a-provider/become-a-provider.component";
import HowItWorks from "./components/pages/how-it-works/how-it-works.component";
import TermsOfService from "./components/pages/terms-of-service/terms-of-service.component";
import PatientDashboard from "./patients-components/dashboard/dashboard.component";
import CompleteProfile from "./components/auth/complete-profile/complete-profile.component";
import ForgotPassword from "./components/auth/forgot-password/forgot-password.component";
import PatientAppointments from "./patients-components/appointments/appointments.component";
import AllDoctors from "./patients-components/my-doctors/my-doctors.component";
import AppointmentDetails from "./patients-components/appointment-details/appointment-details.component";
import PatientProfile from "./patients-components/my-profile/my-profile.component";
import MyCalender from "./providers-components/my-calender/my-calender.component";
import Calender from "./patients-components/my-calender/my-calender.component";
import Prescription from "./patients-components/prescriptions/prescriptions-list.component";
import PrescriptionDetails from "./patients-components/prescriptions/prescription-datails.component";
//import DoctorProfile from "./patients-components/my-doctors/doctor-profile.component";
import Setting from "./patients-components/settings/settings.component";
import ProviderSetting from "./providers-components/settings/settings.component";
import PatientHistoryView from "./providers-components/patient-history/patient-history.component";
import AdminDashboard from "./admin-components/dashboard/dashboard.component";
import ScrollToTop from "./shared/scrolltoTop";
import AllUsers from "./admin-components/all-users/all-users.component";
import ProviderLayout from "./components/HOC/provider-hoc/providerLayout";
import PatientLayout from "./components/HOC/patient-hoc/patientLayout";
import AdminLayout from "./components/HOC/admin-hoc/adminLayout";
import WithHeaderLayout from "./components/HOC/withHeader-hoc/withHeaderLayout";
import AllDiseases from "./components/all-diseases/all-diseases.component";
import OTPCODEMAIL from "./components/mail-pages/otp-code";
import ResetPassword from "./components/mail-pages/resend-password";
import ChangePassword from "./components/auth/change-password/change-password.component";
import MedicalRecords from "./patients-components/medical-records/medical-records.component";
import PatientWallet from "./patients-components/patient-wallet/patient-wallet.component";
import ReportForm from "./patients-components/medical-records/reports/report-form.component";
import BloodPressureDetails from "./patients-components/medical-records/vitals/vitals-forms/blood-pressure/blood-pressure-list.component";
import BPForm from "./patients-components/medical-records/vitals/vitals-forms/blood-pressure/blood-pressure-form.component";
import PrivacyPolicy from "./components/pages/privacy-policy/privacy-policy.component";
import AllNotifications from "./patients-components/all-notifications/all-notifications.componen";
import { useSelector } from "react-redux";
import Toaster from "./shared/toaster"
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from "./shared/redux/store/store";

const App = () => {
  const { user } = useSelector((state) => state.users);

  return (
    <>
      <HashRouter >
        <ScrollToTop />
        <PersistGate loading={null} persistor={persistor}>
        <Routes path="/">
          {/* Auth Pages */}
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/signup" element={<SignUpComponent />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/complete-profile" element={<CompleteProfile />} />
          <Route path="/otp" element={<OTPVerfication />} />

          {/* Component Pages */}
          <Route
            path="/"
            element={<WithHeaderLayout Component={LandingPage} />}
          />
          <Route path="/otpmailcode" element={<OTPCODEMAIL />} />
          <Route path="/resetpassword" element={<ResetPassword />} />

          <Route
            path="/about-us"
            element={<WithHeaderLayout Component={Aboutus} />}
          />
          <Route
            path="/contact-us"
            element={<WithHeaderLayout Component={ContactUs} />}
          />
          <Route
            path="/blogs"
            element={<WithHeaderLayout Component={AllBlogs} />}
          />
          <Route
            path="/specializations"
            element={<WithHeaderLayout Component={AllSpecialization} />}
          />
          <Route
            path="/diseases"
            element={<WithHeaderLayout Component={AllDiseases} />}
          />

          <Route
            path="/blog-details"
            element={<WithHeaderLayout Component={BlogDetails} />}
          />
          <Route
            path="/all-doctor"
            element={<WithHeaderLayout Component={AllDoctorList} />}
          />
          <Route
            path="/doctor-profile"
            element={<WithHeaderLayout Component={DoctorsProfile} />}
          />
          {/* Pages */}
          <Route
            path="/what-we-provide"
            element={<WithHeaderLayout Component={WhatWeProvide} />}
          />
          <Route
            path="/become-a-provider"
            element={<WithHeaderLayout Component={BecomeProvider} />}
          />
          <Route
            path="/how-it-works"
            element={<WithHeaderLayout Component={HowItWorks} />}
          />
          <Route
            path="/terms-of-service"
            element={<WithHeaderLayout Component={TermsOfService} />}
          />
          <Route
            path="/privacy-policy"
            element={<WithHeaderLayout Component={PrivacyPolicy} />}
          />

          {user.role === "Provider" && (
            <Route path="/">
              <Route
                path="/dashboard"
                element={<ProviderLayout Component={DashboardComponent} />}
              />
              <Route
                path="/mypatients"
                element={<ProviderLayout Component={MyPatientsList} />}
              />
              <Route
                path="/profile"
                element={<ProviderLayout Component={Profile} />}
              />
              <Route
                path="/patient-profile"
                element={<ProviderLayout Component={PatientProfile} />}
              />
              <Route
                path="/appointments"
                element={<ProviderLayout Component={Appointments} />}
              />
              <Route
                path="/appointment-details"
                element={<ProviderLayout Component={AppointmentDetails} />}
              />
              <Route
                path="/earnings"
                element={<ProviderLayout Component={MyEarning} />}
              />
              <Route
                path="/calender"
                element={<ProviderLayout Component={MyCalender} />}
              />
              <Route
                path="/settings"
                element={<ProviderLayout Component={ProviderSetting} />}
              />
              <Route
                path="/patient-history"
                element={<ProviderLayout Component={PatientHistoryView} />}
              />
            </Route>
          )}
          {user.role === "Patient" && (
            <Route path="/">
              <Route
                path="/dashboard"
                element={<PatientLayout Component={PatientDashboard} />}
              />
              <Route
                path="/appointments"
                element={<PatientLayout Component={PatientAppointments} />}
              />
              <Route
                path="/my-doctors"
                element={<PatientLayout Component={AllDoctors} />}
              />
              {/* <Route
                path="/doctor-profile"
                element={<PatientLayout Component={DoctorProfile} />}
              /> */}
              <Route
                path="/appointment-details"
                element={<PatientLayout Component={AppointmentDetails} />}
              />
              <Route
                path="/profile"
                element={<PatientLayout Component={PatientProfile} />}
              />
              <Route
                path="/calender"
                element={<PatientLayout Component={Calender} />}
              />
              <Route
                path="/prescriptions"
                element={<PatientLayout Component={Prescription} />}
              />
              <Route
                path="/prescription-details"
                element={<PatientLayout Component={PrescriptionDetails} />}
              />
              <Route
                path="/medical-records"
                element={<PatientLayout Component={MedicalRecords} />}
              />
              <Route
                path="/report-form"
                element={<PatientLayout Component={ReportForm} />}
              />
              <Route
                path="/bp"
                element={<PatientLayout Component={BloodPressureDetails} />}
              />
              <Route
                path="/bp-form"
                element={<PatientLayout Component={BPForm} />}
              />
              <Route
                path="/my-wallet"
                element={<PatientLayout Component={PatientWallet} />}
              />
              <Route
                path="/notifications"
                element={<PatientLayout Component={AllNotifications} />}
              />

              <Route
                path="/settings"
                element={<PatientLayout Component={Setting} />}
              />
            </Route>
          )}
          {user.role === "Admin" && (
            <Route path="/">
              <Route
                path="/dashboard"
                element={<AdminLayout Component={AdminDashboard} />}
              />
              <Route
                path="/allusers"
                element={<AdminLayout Component={AllUsers} />}
              />
            </Route>
          )}
        </Routes>
        </PersistGate>
      </HashRouter >
      <Toaster />
    </>
  );
};

export default App;
