import axios from "axios";

axios.defaults.baseURL =
  "https://2iyyiu1pe0.execute-api.us-east-1.amazonaws.com/Prod/api";


  // OnBoarding APIS

export const login = formData => axios.post("/Auth/login", formData);

export const signup = formData => axios.post("/Auth/register", formData);

export const OTPCode = formData => axios.post("/Auth/verify-otp", formData);

export const resendOTP = formData => axios.post("/Auth/resend-otp", formData);

export const forgotPassword = formData => axios.post("/Auth/forgot-password", formData);

export const changePassword = formData => axios.post("/Auth/verify-fp-otp", formData);

export const refreshToken = formData => axios.post("/Auth/refresh-token", formData);

export const completeProfile = formData => axios.put("/Provider", formData);

export const allQualification = () => axios.get("/Qualification");

export const allSpecialization = () => axios.get("/Specialization");
