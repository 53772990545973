import React from "react";
import { Link } from "react-router-dom";
import { useGetUserQuery } from "../../../shared/api/rtk";

const Patients = () => {
  const { data } = useGetUserQuery();
  const patientData = data?.patients || [];
  return (
    <>
      <div className="tables-design">
        <div className="desktop-table">
          <div className="table-responsive">
            <table className="table">
              <thead className="header-table">
                <tr>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Patient ID</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th scope="col" />
                </tr>
              </thead>

              <tbody>
                {patientData.map((patient) => (
                  <tr key={patient.id}>
                    <td>
                      <img
                        className="table-img"
                        src="/assets/AsadWali.jpg"
                        alt="userPic"
                      />
                      {patient.fullName}
                    </td>
                    <td>{patient.uniqueId}</td>
                    <td>{patient.phoneNo}</td>
                    <td>{patient.email}</td>
                    <td className={`status-cell ${patient.status.toLowerCase()}`}>{patient.status}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Link to="#" className="btn details">
                          View Details
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>

        {/* Mobile View Starts */}
        <div className="table-mobile">
          {patientData.map((patient) => (
            <div key={patient.id} className="mb-4 mobile-card">
              <div className="top-section">
                <div className="img">
                  <img src="/assets/AsadWali.jpg" alt="userPic" />
                  <p className="title mt-2">{patient.fullName}</p>
                </div>
                <div className="appointment-content">
                  <p className="title">Status: {patient.status}</p>
                </div>
              </div>
              <div className="middle">
                <div className="spec">
                  <p className="title tight">Patient ID</p>
                  <p className="tight">{patient.uniqueId}</p>
                </div>
                <div className="date">
                  <p className="title tight">Phone Number</p>
                  <p className="tight">{patient.phoneNo}</p>
                </div>
                {/* <div className="rating">
                  <p className="title tight">Email</p>
                  <p className="tight">{patient.email}</p>
                </div> */}
              </div>
              <div className="bottom">
                <Link to="/appointment-details" className="details">
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* Mobile View Ends */}
      </div>
    </>
  );
};

export default Patients;
