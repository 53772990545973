import React from "react";
import DiseasesBanner from "./diseases-banner/diseases-banner.component";
import DiseasesDetails from "./diseases-details/diseases-details.component";

const AllDiseases= () => {
    return (
        <>
          <DiseasesBanner />
          <DiseasesDetails />
        </>
    )
}

export default AllDiseases;