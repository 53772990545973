import React from "react";
import "./terms-n-services.style.css";

const TermsServices = () => {
  return (
    <section className="terms-service mt-5 mb-5">
      <div className="container">
        <p>
          Welcome to Becurable! Our primary focus is to empower you to
          prioritize your health and make informed decisions. We strive to offer
          a platform that tailors health guidance specifically for you,
          enhancing the bond between you and your healthcare provider by making
          it more accessible, cost-effective, and intuitive. For clarity and
          transparency, our terms of use, ensuring we deliver to our top-notch
          standards, are available here. Please note, to utilize any of our
          platforms, you need to be 18 years or older. We only process
          prescription orders from individuals who are at least 18, though they
          can order for those younger. Confirming an order indicates you meet
          this age requirement.
        </p>

        <h5 className="text-center">
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY. THESE
          CONTAIN IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS, AS
          WELL AS LIMITATIONS AND EXCLUSIONS THAT APPLY TO OUR SERVICES.
        </h5>
        <h5>GENERAL USAGE OF SITE</h5>
        <p>
          Becurable, referred to as "we," "our," or "us," is the proprietor of
          the www.becurable.com website and its associated mobile applications
          (jointly recognized as the "Platform"), which are linked to these User
          Guidelines. Our primary role is to assist users in connecting with and
          scheduling sessions with medical experts, ordering and receiving
          prescription drugs as well as legal over-the-counter medicines, and
          liaising with labs via our Platform. All medical specialists,
          pharmacies, and labs affiliated or listed with Becurable are
          collectively named "Healthcare Affiliates." Our Services are
          exclusively available in specific regions of Pakistan. By engaging
          with any aspect of our Platform, you acknowledge and accept these User
          Guidelines and all related regulations available on the site. If you
          disagree with any part of these guidelines, it's recommended not to
          use the Platform or our Services. Declining these terms also means you
          won't be eligible to set up a profile or access our services. If
          you're not in agreement, it's advised to stop using our services and
          deactivate your account. We reserve the right to modify these terms at
          our discretion and without prior notification. It's emphasized that by
          accessing our services or registering with us, you consent to these
          terms, our privacy practices, and agree to abide by Pakistan's legal
          resolutions for disputes. We don't assure the professional expertise,
          service quality, diagnostic accuracy, or any content related to our
          services. We won't be accountable for decisions or actions based on
          such content. We don't explicitly endorse any specific healthcare
          affiliates listed on our platform. Information about medications or
          healthcare providers on our platform is designed to be general and
          informative. It doesn't encompass all drug attributes or interactions
          and shouldn't be viewed as personalized medical advice. Consult a
          licensed medical practitioner for advice and decisions related to
          health.
        </p>

        <h5>PRIVACY STATEMENT</h5>
        <p>
          Your privacy is of paramount importance to us. Only authorized
          personnel within the Company access information gathered from our
          users, and that too, strictly on a 'need-to-know' basis. We
          continuously refine our systems and data management practices to offer
          our clients unparalleled service. Becurable is dedicated to
          safeguarding user personal details, ensuring it's not exposed to
          unauthorized entities. Details about our management of health and
          other personal data can be found in our Privacy Policy, which all
          users must consent to before creating an account and accessing the
          services on our Platform.
        </p>

        <h5>
          BECURABLE DOES NOT PROVIDE MEDICAL ADVICE AND EMERGENCY FACILITIES
        </h5>
        <p>
          Any information or content you access from Becurable, inclusive of our
          team, associates, advertisers, partners, or licensors via our Site's
          services is purely for informational and scheduling reasons. Medical
          details, inclusive of insights from healthcare professionals' blogs,
          emails, and messages, originates from independent medical entities and
          is strictly for information. Content from Becurable, regardless of the
          medium, isn't a replacement for expert medical counsel, diagnosis, or
          care. Avoid substituting or postponing professional medical
          consultations based on the site's content. We emphasize that our
          Services shouldn't be your go-to for urgent medical needs. In
          emergencies, contact a medical specialist or head to the closest
          medical facility. Your engagement with our content is entirely your
          responsibility. No aspect of the site or our services should be
          interpreted as practicing medicine or providing medical opinions. It's
          crucial to evaluate the accuracy and relevance of the information
          before any reliance. Although Becurable confirms the registration of
          medical professionals on our platform, we can't vouch for their
          credentials, quality, or expertise mentioned in their profiles. We
          don't specifically advocate any health professionals, tests,
          medicines, or treatments showcased on our platforms. Any dependence on
          our Services' content is strictly at your discretion. Appointments can
          be altered or annulled based on the pertinent terms, conditions, and
          the availability of the healthcare provider.
        </p>

        <h5>DISCLAIMER AND EXCLUSION OF WARRANTIES AND LIABILITIES</h5>
        <p>
          Becurable has no direct control over the accessibility of any
          particular Healthcare Provider, pharmacy, or lab. We are not
          accountable for any harm or personal injury linked to our Services or
          any injuries from the use of our Platform. The responsibility of
          selecting a Healthcare Provider rests solely with you. Becurable does
          not vouch for the caliber of services or products from these
          providers. Even though our platform fosters doctor-patient
          connections, we neither establish nor take accountability for such
          relationships. The data on this site is presented "as is." To the
          maximum extent allowed by law, this Company: (a) refrains from all
          claims and guarantees regarding this website and its content,
          including content from any third parties or affiliates, especially
          concerning any discrepancies; and (b) denies all responsibility for
          damages from your interaction with this site, covering, without
          restriction, business losses, profit losses, or damages to your
          technology. These restrictions and denials are within the bounds of
          the law and do not infringe upon your consumer rights. UNDER
          APPLICABLE LAWS, Becurable OR ITS REPRESENTATIVES WILL NOT BE LIABLE
          FOR ANY INDIRECT OR CONSEQUENTIAL LOSSES OR DAMAGES RELATED TO YOUR
          USE OF OUR SITE OR SERVICES. Further, Becurable won't be liable for
          any disruptions, suspensions, or halts in the Services. Where legal
          bounds prevent complete disclaimer or liability restrictions, our
          warranty and liability will match the least allowed by such law. We
          don’t guarantee continuous, punctual, or error-free services, though
          we do our best. By availing this service, you safeguard the Company
          and its associates against any harm or losses, no matter the cause.
          Any redistribution or reuse of any segment of this site is forbidden
          without the Company's explicit approval. By using our platform, you
          commit to defend and shield Becurable, its affiliates, and associated
          medical professionals from claims or damages resulting from (a) site
          usage; (b) breach of these terms; and (c) medical misunderstandings
          due to miscommunication, misleading information, or failure to heed
          advice; or (d) invalid financial information you provide; or (e) using
          financial tools you don't own lawfully; or (f) if your account is
          accessed by someone else due to your negligence.
        </p>

        <h5>REGISTRATION AND USER INFORMATION</h5>
        <p>
          To sign up, it's mandatory to provide some basic personal details.
          Additionally, you can choose to disclose extra information to fully
          utilize the range of Services we offer. It's your responsibility to
          ensure that the data you submit is accurate, recent, and complete.
          It's crucial to regularly update us with any changes to maintain its
          correctness and relevancy. Our Privacy Policy elaborates on our
          practices concerning the handling, sharing, and preservation of your
          details. This can encompass, but isn't limited to, your name, address,
          professional registration number/ID, and other pertinent data. By
          inputting this information, you grant permission to Becurable, along
          with its staff and designated agents, to process and share it in line
          with our Privacy Policy. It's beneficial for you to update us on any
          alterations in your personal details that might impact the services we
          offer to you. Familiarize yourself with our Privacy Policy, as
          engaging with our Services indicates your acceptance of its terms.
        </p>

        <h5>USER RESPONSIBILITIES</h5>
        <p>
          While users may access the Services for free, customary charges for
          any services and products given by Healthcare Providers still stand
          and fall squarely on your shoulders. If there's any disagreement
          between you and a Healthcare Provider due to any transaction stemming
          from these services, you'll need to settle it directly with the said
          Provider. You're accountable for all activities linked to your
          credentials, even if shared with others. The site and services should
          only be engaged with for lawful, non-business objectives. Actions that
          could compromise our servers or networks or hinder others from using
          the site or services are prohibited. Trying to access services, user
          accounts, or systems without permission, like hacking, is not allowed.
          By using our platform, you provide Becurable a global, perpetual,
          non-exclusive, and royalty-free permission to manage, modify,
          publicize, and share your user content across any media form. This
          also means Becurable can allow others to use this content and can act
          against any infringement. Your content shouldn't violate laws,
          infringe on rights, or cause potential legal issues either for you,
          Becurable, or others. We reserve every right outlined in these terms
          and can pursue legal routes or apply technological solutions to
          counteract any breaches of these guidelines.
        </p>

        <h5>COPYRIGHT AND TRADEMARK NOTICE</h5>
        <p>
          Every piece of content, encompassing trademarks, taglines that
          identify us or associated entities, graphics, visuals, icons, and
          service designations, exclusively belongs to us. All content present
          on this platform is safeguarded by both national and global copyright
          regulations and agreements. The site doesn't grant any permissions to
          utilize any showcased trademark, unless there's explicit written
          consent from its rightful owner. Apart from accessing and using the
          site for its designated use, you are prohibited from replicating,
          altering, publishing, transmitting, or manipulating any site content,
          whether partially or entirely, without a clear written authorization
          from Becurable. Our logo shouldn't be used in any manner, including as
          a hyperlink to our platform, unless we've previously provided written
          consent for its specific use. For any breaches of these terms,
          Becurable retains the right to exercise all remedies at its disposal,
          whether legal, equitable, or under international copyright laws. This
          may include restricting access from certain IP addresses. Copyright
          and associated intellectual property rights pertain to all textual
          content about the Company's offerings and the entirety of this site's
          content. The logo of this Company is a trademark registered in the
          name of the Company.
        </p>

        <h5>CONFIDENTIALITY</h5>
        <p>
          Information about you and your records may be disclosed to third
          parties. However, your records are confidential and will only be
          shared with relevant Healthcare Providers or legally mandated
          authorities. You have the right to access and obtain copies of your
          records with reasonable notice. Please maintain copies of any provided
          literature related to our services. We will furnish appropriate
          written materials or records according to our agreement, benefiting
          both parties. We won't sell, share, or lease your personal information
          to third parties or send unsolicited emails to your provided email
          address. Any emails sent by this Company will pertain solely to the
          agreed-upon services and products we offer.
        </p>

        <h5>PROHIBITION</h5>
        <p>
          You are not allowed to upload or send any content to this site that's
          illegal, threatening, defamatory, obscene, inflammatory, or
          pornographic. Moreover, you mustn't post anything that might result in
          civil or criminal responsibility under current laws or international
          agreements. Becurable retains the right to utilize all available legal
          and equitable solutions for breaches of these terms, which may include
          barring access from specific Internet addresses to our site.
        </p>

        <h5>LIMITATION OF LIABILITY</h5>
        <p>
          Practices in medicine, commerce, and law often undergo changes.
          Content found on this website, in our newsletters, and other advisory
          materials are presented for general informational purposes. They
          shouldn't replace specific medical, legal, or other expert guidance.
          Becurable holds no responsibility for any damage or loss, whether
          direct, indirect, incidental, special, consequential, or any other
          type, arising from the Services or the use (or inability to use) this
          site. This includes losses like profit, operational downtime,
          goodwill, contracts, revenue, or anticipated savings. Even if advised
          about potential damages, under no circumstances will Becurable be
          liable for losses stemming from a user's dependence on information
          sourced from this site.
        </p>

        <h5>GOVERNING LAW AND JURISDICTION</h5>
        <p>
          The regulations are shaped by and interpreted under the laws of
          Pakistan. For any conflicts arising directly from the Services, you
          agree to the exclusive jurisdiction of the Pakistani courts.
        </p>

        <h5>LINKS TO THIS WEBSITE</h5>
        <p>
          Before linking to any page of this website, you must obtain our prior
          written permission. Should you establish a link without approval,
          you're doing so at your own peril. The aforementioned exclusions and
          limitations will apply to your use of this website through such a
          link.
        </p>

        <h5>LINKS FROM THIS WEBSITE</h5>
        <p>
          We don't oversee the content of other websites, including those of
          Healthcare Providers, linked to or from our website. Any views or
          material on such sites don't necessarily reflect our stance, and we
          aren't to be considered endorsers or publishers of such content.
          Please note that we aren't accountable for the privacy measures or
          content of these external sites. Whenever you navigate away from our
          site, we urge you to peruse the privacy policies of those sites. It's
          crucial for you to personally assess the reliability and security of
          any other website linked to our platform or accessed via our platform
          before sharing any personal data. We disclaim any liability for
          potential losses or damages arising from your sharing of personal
          information with third parties.
        </p>

        <h5>COMMUNICATION</h5>
        <p>
          Should you have queries or reservations about this policy or how we
          handle your information, or if you wish to alter or update information
          we possess, please reach out to us. We maintain various email
          addresses tailored for distinct inquiries. You can find these email
          addresses and other relevant contact details on our "Contact Us" page.
        </p>

        <h5>FORCE MAJEURE</h5>
        <p>
          No party will be held accountable for not fulfilling any obligation
          under these Terms if the failure results from events reasonably beyond
          their control. This includes, but is not restricted to, natural
          disasters like earthquakes and floods, acts of God, war, terrorism,
          political upheavals, riots, actions by civil or military authorities,
          and other uncontrollable or unforeseen events. Should any party be
          impacted by such occurrences, they should promptly notify the other
          and make all possible efforts to adhere to these Terms.
        </p>

        <h5>WAIVER</h5>
        <p>
          If either party doesn't enforce strict adherence to any clause of this
          or any related Agreement, or if either party doesn't utilize any
          rights or remedies available to them, it doesn't equate to waiving
          those provisions or rights. The obligations under this or any
          Agreement remain intact. For a waiver of any provision of this or any
          related Agreement to be valid, it must be explicitly declared as a
          waiver and endorsed by both parties.
        </p>

        <h5>GENERAL</h5>
        <p>
          When you engage with our Services via the Platform, you are agreeing
          to these Terms. Should any portion of these terms be considered void
          or unenforceable for any reason (this includes the exclusions and
          limitations previously mentioned), that specific clause will be
          detached from these terms, while the rest will remain effective. The
          Company's failure to act upon or enforce any part of these Terms, or
          to utilize any option to terminate, should not be interpreted as a
          waiver of those terms. The integrity of these Terms isn't impacted by
          such inaction. Any changes or alterations to these Terms will only be
          acknowledged if done in writing and endorsed by authorized personnel
          of the Company. Becurable reserves the right to transfer, delegate, or
          manage its rights or obligations under these terms without informing
          you or seeking your approval. However, you cannot transfer, delegate,
          or manage your rights or obligations under these terms. This Agreement
          encompasses the entirety of the mutual understanding between parties,
          overriding and nullifying all prior agreements, communications, or
          understandings, both verbal and written, related to this Agreement's
          purpose.
        </p>

        <h5>NOTIFICATION OF CHANGES </h5>
        <p>
          The Company retains the prerogative to modify these Terms periodically
          as deemed necessary. Your ongoing interaction with the site indicates
          your approval of any modifications to these terms. In the event of
          alterations to our Privacy Policy or Terms, we will showcase these
          updates on the Platform. Should there be changes concerning how we
          manage our site users' "Personally Identifiable Information", those
          impacted might receive notifications via email or standard mail.
          Changes to our privacy policy and/or Terms will be made available on
          our website one week before their implementation. Hence, it's
          recommended that you revisit this statement regularly. These Terms
          represent a contract between you and us. Engaging with the Services on
          the Platform and/or committing to a booking or agreement implies your
          acknowledgment and consent to the entirety of the stipulations
          presented herein.
        </p>

        <h5>PAYMENT</h5>
        <p>
          You commit to settling all fees and charges as outlined. Becurable is
          authorized to instantly bill your credit card or any provided payment
          method for all due amounts, without needing further notification or
          your additional consent. All fees charged by Becurable are exclusive
          of any relevant taxes. You'll bear any such taxes and any related
          penalties if applicable in your jurisdiction. You also pledge to
          shield Becurable from any liabilities related to these taxes. Unless
          specified otherwise by the Company in written form, all fees once paid
          are non-refundable. Your credit card details and other financial data
          for payment processing are held by third-party payment processors. We
          might gather certain information, but the use of this data by these
          third-party processors adheres to their respective privacy policies.
          The Company retains the right to alter, halt, or cease a subscription
          service you're enrolled in without prior notice if payments aren't
          made or are unprocessable by the due date.
        </p>

        <h5>REFUND POLICY</h5>
        <p>
          If you wish to request a refund, please email your claim to
          accounts@becurable.com. Ensure you submit your claim within 3 days of
          the payment, in line with the Company's refund policy. Claims made
          after this 3-day window will not be entertained. Refunds processed
          within the valid period should be completed within 15 business days.
          Be aware that extenuating circumstances might extend the refund
          processing time, and you'll be informed if that happens.
        </p>
      </div>
    </section>
  );
};

export default TermsServices;
