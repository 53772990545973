import React from 'react'
import Step1 from './step1/step1.component';
import Step2 from './step2/step2.component';
import Step3 from './step3/step3.component';
import Step4 from './step4/step4.component';
import WorkBanner from './works-banner/works-banner.component';

const HowItWorks = () => {
    return (
        <>
            <WorkBanner />
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
        </>
    )
}

export default HowItWorks;