import React from "react";

const PatientHistoryView = () => {
  return (
    <div className="list-container">
        <div className="p-3 mt-3">
                <h2>Patient History</h2>
            </div>
      <div className="row">
        <div className="ms-5 col-12 col-md-5">
          <div className="py-3">
            <h5 className="mb-3 text-black">Disease</h5>
            <p>High Blood Pressure</p>
          </div>
          <div className="py-3">
            <h5 className="mb-3 text-black">Timings:</h5>
            <p>09:15 AM - July 20, 2022</p>
          </div>
          <div className="py-3">
            <h5 className="mb-3 text-black">Location:</h5>
            <p>Online</p>
          </div>
        </div>
        <div className="ms-5 col-12 col-md-5">
          <div className="py-3">
            <h5 className="mb-3 text-black">Medicene Prescribe</h5>
            <p>ALP 2mg, Maxit 50mg</p>
          </div>
          <div className="py-3">
            <h5 className="mb-3 text-black">Doctor Name:</h5>
            <p>Dr. Asad Wali</p>
          </div>
          <div className="py-3">
            <h5 className="mb-3 text-black">Fee:</h5>
            <p>Rs 500</p>
          </div>
          <div className="py-3">
            <h5 className="mb-3 text-black">Reason For Cancelled:</h5>
            <p>Network Issue</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PatientHistoryView;
