import React from "react";
import Select from "react-select";
import "./doctors-card.style.css";
import doctorsData from "../../shared/alldoctors.json";
import { Link } from "react-router-dom";

const AllDoctors = () => {
  const Specializations = [
    { value: "English", label: "English" },
    { value: "Mathematics", label: "Mathematics" },
    { value: "Physics", label: "Physics" },
  ];
  const Qulifications = [
    { value: "Good", label: "Good" },
    { value: "Excellent", label: "Excellent" },
    { value: "Best", label: "Best" },
  ];
  const locationOptions = [
    { value: "Lahore", label: "Lahore" },
    { value: "Islamabad", label: "Islamabad" },
    { value: "Karachi", label: "Karachi" },
  ];
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="filter-card">
            <div className="mb-3">
              <div className="text-start h6">Specializations</div>
              <Select
                className="form-input-field"
                options={Specializations}
                placeholder={`All Specializations`}
                isClearable
                isMulti
              />
            </div>
            <div className="mb-3">
              <div className="text-start h6">Qulifications</div>

              <Select
                className="form-input-field"
                options={Qulifications}
                placeholder={`All Qulifications`}
                isClearable
                isMulti
              />
            </div>
            <div className="mb-3">
              <div className="text-start h6">Location</div>

              <Select
                className="form-input-field"
                options={locationOptions}
                placeholder={`All `}
                isClearable
                isMulti
              />
            </div>
            <div className="mb-3">
              <div className="text-start h6">Gender</div>

              <Select
                className="form-input-field"
                options={genderOptions}
                placeholder={`Select Gender`}
                isClearable
                isMulti
              />
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-12 col-sm-12">
          {doctorsData.map((data) => {
            return (
              <>
                <div className="my-doctors mb-3">
                  <div className="my-doctors-card">
                    <div className="card-info d-flex justify-content-between">
                      <div className="d-flex">
                        <img
                          className="cardimage"
                          src={data.image}
                          alt="user"
                        />
                        <div className="ms-3">
                          <h5>{data.name}</h5>
                          <p className="tight">{data.specialist}</p>
                          <p className="tight">Lahore, Pakistan</p>
                          <p className="tight">
                            <span className="me-3">⭐ {data.rating}</span>(
                            {data.reviews_count})
                          </p>
                          <p className="tight fw-bolder">
                            Trusted And Verified By Us
                            <img
                              className="verified-icon"
                              src="/assets/icons/verified.png"
                              alt="verified"
                            />
                          </p>
                        </div>
                      </div>
                      <div>
                        <p>Fee : {data.fee}</p>
                      </div>
                    </div>
                    <div className="mt-3 d-inline-flex">
                      <p>Specializations:</p>
                      <p className="badge ms-2 text-dark rounded-3 ps-2 pe-2">
                        {`${data.specializations[0].title}`}
                      </p>
                      <p className="badge ms-2 text-dark rounded-3 ps-2 pe-2">
                        {`${data.specializations[1].title}`}
                      </p>
                    </div>
                    <p>{data.bio.slice(0, 250)}</p>
                    <div className="d-flex">
                      <Link className="btn btn-view" to="/doctor-profile">
                        View Profile
                      </Link>
                      <Link className="btn btn-view ms-3" to="/doctor-profile">
                        Book Appointment
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AllDoctors;
