import React, { useCallback, useState, useEffect } from "react";
import "./signup.style.css";
import { Card, Grid } from "@material-ui/core";
import { Box, styled } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import TermsAndConditions from "./T&CModal";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../../shared/redux/actions/users.actions";
import Loader from "../../../shared/loader";
import Toaster from "../../../shared/toaster";
import { useSignUpMutation } from "../../../shared/api/rtk"
import { updateUser } from "../../../shared/redux/reducers/users.reducer";
import { toast } from "react-toastify";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  cpassword: "",
  number: "",
  role: "",
};
const validationSchema = Yup.object({
  firstname: Yup.string().required("First Name is required!"),
  lastname: Yup.string().required("Last Name is required!"),
  email: Yup.string()
    .email("Invalid email format!")
    .required("Email is required!"),
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character."
    ),
  cpassword: Yup.string()
    .required("Confirm Password is required!")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  number: Yup.string().required("Mobile number is required!"),
  role: Yup.string().required("Role is required"),
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const Section = styled(JustifyBox)(() => ({
  background: "#011F4B",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 1013,
    borderRadius: 20,
    margin: "1rem",
  },
}));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0)",
}));

const SignUpComponent = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSuccessfull } = useSelector((state) => state.users);
  // const isLoading = useSelector((state) => state.users.isLoading);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("+92");
  const [showPassword, setShowPassword] = useState(false);

  const [signUp, { isLoading, data, isError, error }] = useSignUpMutation();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showCPassword, setShowCPassword] = useState(false);
  const handleCPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };



  const onSubmit = useCallback(
    async values => {
      try {
        const formData = new FormData();
        formData.append("FirstName", values.firstname);
        formData.append("LastName", values.lastname)
        formData.append("Email", values.email)
        formData.append("Password", values.password)
        formData.append("PhoneNo", values.number)
        formData.append("Role", values.role)
        const data = await signUp(formData).unwrap();
        if (values.role === "Provider") {
          dispatch(updateUser(data))
          navigate("/complete-profile");
        } else {
          navigate("/login");
        }
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, signUp]
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.message || error?.data || "Something Went Wrong!")
    }
  }, [isError, error])


  return (
    <>
      <Section>
        <Card className="card">
          <Grid container>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <JustifyBox>
                <div className="auth-logo-container">
                  <img
                    src="./assets/logo.png"
                    alt="logo"
                    className="auth-logo"
                  />
                </div>
              </JustifyBox>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <ContentBox>
                <h1>Create Your Account</h1>
                <p>Please fill the form below</p>
                {/* form-start */}
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue }) => (
                        <Form className="">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <Field
                                className="form-input placeholder"
                                type="text"
                                name="firstname"
                                id="firstname"
                                placeholder="First Name"
                              />
                              <div className="text-danger text-start">
                                <ErrorMessage name="firstname" />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <Field
                                className="form-input placeholder"
                                type="text"
                                name="lastname"
                                id="lastname"
                                placeholder="Last Name"
                              />
                              <div className="text-danger text-start">
                                <ErrorMessage name="lastname" />
                              </div>
                            </div>
                          </div>
                          <Field
                            className="form-input placeholder"
                            type="text"
                            name="email"
                            id="name"
                            placeholder="Email Address"
                          />
                          <div className="text-danger text-start">
                            <ErrorMessage name="email" />
                          </div>
                          {/* Password Field */}
                          <div className="auth-passwordfield">
                            <Field
                              className="form-input placeholder"
                              id="password"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                            />
                            <img
                              className="eyeicon"
                              src={
                                showPassword
                                  ? "/assets/icons/eyeopen.svg"
                                  : "/assets/icons/eyeclose.svg"
                              }
                              alt="Toggle password visibility"
                              onClick={handlePasswordVisibility}
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="password" />
                            </div>
                          </div>
                          <div className="auth-passwordfield">
                            <Field
                              className="form-input placeholder"
                              id="cpassword"
                              type={showCPassword ? "text" : "password"}
                              name="cpassword"
                              placeholder="Confirm Password"
                            />
                            <img
                              className="eyeicon"
                              src={
                                showCPassword
                                  ? "/assets/icons/eyeopen.svg"
                                  : "/assets/icons/eyeclose.svg"
                              }
                              alt="Toggle password visibility"
                              onClick={handleCPasswordVisibility}
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="cpassword" />
                            </div>
                          </div>

                          <PhoneInput
                            className="form-input placeholder"
                            placeholder="Mobile Number"
                            defaultCountry="PK"
                            countryCallingCodeEditable={false}
                            value={phoneNumber}
                            onChange={(value) => {
                              setPhoneNumber(value);
                              setFieldValue("number", value);
                            }}
                            countriesonly={true}
                            international={false}
                          />
                          <div className="text-danger text-start">
                            <ErrorMessage name="number" />
                          </div>

                          <Field
                            as="select"
                            name="role"
                            className="form-input placeholder"
                            id="role"
                          >
                            <option value="" select disabled hidden>
                              Role
                            </option>
                            <option value="Provider">Provider</option>
                            <option value="Patient">Patient</option>
                          </Field>
                          <div className="text-danger text-start">
                            <ErrorMessage name="role" />
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <p className="signUp_para">
                                <input
                                  className="me-1"
                                  type="checkbox"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "agreeTerms",
                                      e.target.checked
                                    );
                                    setIsCheckboxChecked(e.target.checked);
                                  }}
                                />
                                I agree the{" "}
                                <button
                                  type="button"
                                  className="btn p-1 ms-1"
                                  data-bs-toggle="modal"
                                  data-bs-target="#termsandconditions"
                                >
                                  <span>Terms & Conditions</span>
                                </button>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="content col-sm">
                              <p>
                                Already have an account
                                <Link to="/login">
                                  <span className="login_span ms-1">
                                    Sign In
                                  </span>
                                </Link>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <button
                                className="btn form_btn"
                                type="submit"
                                disabled={!isCheckboxChecked}
                              >
                                Register
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

                {/* form-end */}
              </ContentBox>
            </Grid>
          </Grid>
        </Card>
      </Section>
      <Toaster />
      <Loader loading={isLoading} />
      <TermsAndConditions />
    </>
  );
};

export default SignUpComponent;
