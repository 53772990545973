import React from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./my-patients.style.css";

const Dashboard = () => {
  return (
    <div>
      <div className="row mb-2 justify-content-between">
        <div className="col-md-6">
          <div className="input-group search-group">
            <button className="btn btn-outline-secondary" type="submit">
              <FaSearch />
            </button>
            <input
              className="form-control"
              type="search"
              placeholder="Search user"
              aria-label="Search"
            />
          </div>
        </div>
      </div>

      <div className="tables-design">
        <div className="desktop-table">
          <div className="table-responsive">
            <table className="table">
              <thead className="header-table">
                <tr>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Age</th>
                  <th scope="col">Check-up Date</th>
                  <th scope="col">Disease</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      className="table-img"
                      src="/assets/AsadWali.jpg"
                      alt="Dr. Asad"
                    />
                    Asad Wali
                  </td>
                  <td>26</td>
                  <td>13 Aug, 12:00 PM</td>
                  <td>Sore Throat</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Link to="/appointment-details" className="btn details">
                        View Profile
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      className="table-img"
                      src="/assets/AsadWali.jpg"
                      alt="Dr. Asad"
                    />
                    Sidra Behzad Qureshi
                  </td>
                  <td>34</td>
                  <td>13 Aug, 12:00 PM</td>
                  <td>Head Ache</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Link to="/appointment-details" className="btn details">
                        View Profile
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>

        {/* Mobile View Starts */}
        <div className="table-mobile">
          <div className="mb-4">
            <div className="top-section">
              <div className="img">
                <img src="/assets/AsadWali.jpg" alt="user" />
                <p className="title mt-2">Asad Wali</p>
              </div>
              <div className="appointment-content">
                <p className="title">Age : 26</p>
              </div>
            </div>
            <div className="middle">
              {/* <div className="spec">
                <p className="title tight">Age</p>
                <p className="tight">26</p>
              </div> */}
              <div className="date">
                <p className="title tight">Check-up Date</p>
                <p className="tight">09 Dec, 2023, 4:00 PM</p>
              </div>
              <div className="rating">
                <p className="title tight">Disease</p>
                <p className="tight">Sore Throat</p>
              </div>
            </div>
            <div className="bottom">
              <Link to="/appointment-details" className="details">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        {/* Mobile View Ends */}
      </div>
    </div>
  );
};

export default Dashboard;
