import React from "react";
import "./step4.style.css";

const Step4 = () => {
  return (
    <section id="step-4">
      <div className="container">
        <div className="row pt-5 pb-5">
          <div
            className="col-lg-6 order-1 order-lg-1 img"
            style={{
              backgroundImage: `url(${"/assets/webImages/Prescription.png"})`,
            }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
          <div className="col-lg-6 order-2 order-lg-2">
            <div className="content">
              <div className="number-center">
                <p className="counting">4</p>
              </div>
              <h2>Get Solution</h2>
              <p>
                That’s it. You’re now connected with a doctor of your choosing.
                Each consultation lasts 15 minutes. After a consultation, you
                will be given a prescription. Stay healthy!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step4;
