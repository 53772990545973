import React, { useEffect } from "react";
import { Modal, Backdrop, Fade, makeStyles } from "@mui/material";
import "./success-modal.style.css";

const SuccessModal = ({ isOpen }) => {

  useEffect(() => {
    // You can add any additional logic here when the modal opens or closes
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
     <Fade in={isOpen}>
      <div className="modal-overlay">
        <div className="success-modal">
          <img
            className="success-icon"
            src="./assets/icons/success.gif"
            alt="wait"
          />
          <p>
            <strong className="congrats">Congratulations !!! </strong> <br></br>
            Your request has been submitted for approval to Admin. Please wait
            for 2-3 Days.
          </p>
        </div>
      </div>
      </Fade>
    </Modal>
  );
};

export default SuccessModal;
