import React, { useEffect, useState } from "react";
import "./diseases.style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import diseases from "../../shared/diseases.json";

const Diseases = () => {
  const navigate = useNavigate();

  const navigateTodisease = () => {
      navigate('/diseases');
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // show 3 slides in the default view (desktop)
    slidesToScroll: 3, // scroll 3 slides at a time
    responsive: [
        {
            breakpoint: 1024, // tablet view
            settings: {
                slidesToShow: 5, // show 2 slides
                slidesToScroll: 1, // scroll 2 slides at a time
            },
        },
        {
            breakpoint: 767, // mobile view
            settings: {
                slidesToShow: 2, // show 1 slide
                slidesToScroll: 1, // scroll 1 slide at a time
            },
        },
    ],
};

  return (
    <section className="disease-subjects">
      <div className="container pt-5 pb-5">
        <div className="content text-center justify-content-center align-items-center">
          <h1>Search Doctor By Disease</h1>
          <p>
            Your personal doctor can help with everything from chronic illness
            to family health, prescription needs and more.
          </p>
        </div>
        <div>
          {isMobile ? (
            <Slider {...settings}>
              {diseases.slice(0, 6).map((data) => (
                <div key={data.name} className="d-flex justify-content-center">
                  <div className="card diseasesCards">
                    <img src={data.image} alt="specializations" />
                    <p className="card-title"> {data.name} </p>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="row mt-5 g-3">
              {diseases.slice(0, 8).map((data) => (
                <div
                  key={data.name}
                  className="col-lg custom-col col-md-4 col-sm-12 d-flex justify-content-center"
                >
                  <div className="card diseasesCards">
                    <img src={data.image} alt="specializations" />
                    <p className="card-title"> {data.name} </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="text-center mt-5">
          <button className="btn btn-find-doctor" onClick={navigateTodisease}>View All</button>
        </div>
      </div>
    </section>
  );
};

export default Diseases;
