import React from "react";
import PatientInfo from "./patient-profile/patient-profile.component";
import PatientHistory from "./patient-history/patient-history.component";

const PatientProfile = () => {
  const userRole = "patient";
  return (
    <>
     {userRole === "provider" && (
      <PatientHistory />
     )}
      <div className="patientprofile">
        <PatientInfo />
      </div>
    </>
  );
};

export default PatientProfile;
