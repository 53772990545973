import React from "react";
import AllDoctorBanner from "./doctor-banner/doctor-banner.component";
import AllDoctors from "./doctors-card/all-doctors.component";
//import DoctorsCard from "./doctors-card/doctors-card.component";

const AllDoctorList = () => {
    return (
        <>
        <AllDoctorBanner />
        <AllDoctors />
        {/* <DoctorsCard /> */}
        </>
    )
}

export default AllDoctorList;