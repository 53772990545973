import React from "react";
import './all-users.style.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Providers from "./providers/providers.component";
import Patients from "./patients/patients.component";


const AllUsers = () => {
    return (
        <Tabs>
            <div className="list-container">
                <TabList className="px-3">
                    <Tab>Providers</Tab>
                    <Tab>Patients</Tab>
                    {/* <Tab>All Users</Tab> */}
                </TabList>

                <TabPanel>
                    <Providers />
                </TabPanel>
                <TabPanel>
                    <Patients />
                </TabPanel>
                {/* <TabPanel>
                    <AppointmentHistory />
                </TabPanel> */}
            </div>
        </Tabs>
    )
}

export default AllUsers;