import React from 'react';
import './provide-banner.style.css';

const ProvideBanner = () => {
    return (
        <section id="provide-hero">
            <div className="provide-container" data-aos="fade-up" data-aos-delay="150">
                <p>What We Provide</p>
                <h1>Find expert care that <span>fits your needs</span></h1>
            </div>
        </section>

    )
}

export default ProvideBanner;