import React, { useState, useEffect } from "react";
import "../hoc.style.css";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../../admin-components/admin-navbar/admin-navbar.component";
import AdminSidebar from "../../../admin-components/admin-sidebar/admin-sidebar.component";
import AdminMobileMenu from "../../../admin-components/admin-mobile-navbar/mobile-menu.component";
import AdminMobileNavbar from "../../../admin-components/admin-mobile-navbar/mobile-navbar.component";

const AdminLayout = ({ Component }) => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ localStorage.getItem(token):", localStorage.getItem("token"))
    if (!localStorage.getItem("token")) navigate("/login");
  }, [navigate]);

  const toggleMenu = () => {
    setMenuVisible(prevMenuVisible => !prevMenuVisible);
  };

  return (
    <>
      <div className="d-flex hoc-container">
        <div className="d-none d-md-flex">
          <AdminSidebar />
        </div>
        <div className="second-col">
          <AdminMobileNavbar toggleMenu={toggleMenu} />
          <AdminNavbar />
          <div className="main-wrapper">
            <Component />
          </div>
        </div>
      </div>
      {menuVisible && <AdminMobileMenu toggleMenu={toggleMenu} />}
    </>
  );
};

export default AdminLayout;
