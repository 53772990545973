import React from "react";
import SpecializationBanner from "./specialization-banner/specialization-banner.component";
import SpecializationCards from "./specializations-cards/specializations-cards.component";

const AllSpecialization = () => {
    return (
        <>
           <SpecializationBanner />
            <SpecializationCards />
        </>
    )
}

export default AllSpecialization;