import React from "react";
import Blogs from "./blogs/blogs.component";
import CTA from "../common-components/call-to-action/cta.component";
import HeroSection from "./heroSection/herosection.component";
import WorksSteps from "./how-it-works/works-steps.component";
import NextGeneration from "./next-generation/next-generation.component";
import MedicalTeam from "./our-medical-team/medical-team.component";
import Specialization from "./specializations/specialization.componet";
import Diseases from "./diseases/diseases.component";
import WhyBecurable from "./why-becurable/why-becurable.component";
import BmiCalculator from "../common-components/bmi-calculation/bmi-calculation.component";

const LandingPage = () => {
    return (
        <>
            <HeroSection />
            <WorksSteps />
            <BmiCalculator />
            <MedicalTeam />
            <NextGeneration />
            <Specialization />
            <Diseases />
            <WhyBecurable />
            <CTA />
            <Blogs />
        </>
    )
}

export default LandingPage;