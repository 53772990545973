// import usersReducer from "../reducers/users.reducer";
// import { persistReducer, persistStore } from 'redux-persist';
// import { configureStore } from "@reduxjs/toolkit";
// import { API } from "../../api/rtk"

// export const store = configureStore({
//     reducer: {
//         users: usersReducer,
//         [API.reducerPath]: API.reducer
//     },
//     middleware: getDefaultMiddleWare => getDefaultMiddleWare().concat(API.middleware)
// })

import usersReducer from "../reducers/users.reducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { API } from "../../api/rtk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  users: persistReducer(persistConfig, usersReducer),
  [API.reducerPath]: API.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(API.middleware),
});

export const persistor = persistStore(store);
