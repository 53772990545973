import React from "react";
import Blogs from "../landingPage/blogs/blogs.component";
import BlogBanner from "./blog-banner/blog-banner.component";
import BlogDescriptions from "./blog-descriptions/blog-descriptions.component";

const BlogDetails = () => {
    return (
        <>
            <BlogBanner />
            <BlogDescriptions />
            <Blogs />
        </>
    )
}

export default BlogDetails;