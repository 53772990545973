import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./prescriptions-form-modal.style.css";

const PrescriptionForm = () => {
  const initialValues = {
    clinicName: "",
    providerName: "",
    patientName: "",
  };

  const onSubmit = () => {};
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required!"),
    provider: Yup.string().required("Provider is required!"),
    date: Yup.string().required("Date is required!"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form
          className="modal fade"
          id="PrescriptionModal"
          tabindex="-1"
          aria-labelledby="AppModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AppModalLabel">
                  Prescription
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                   <div className="row">
                       <h5 className="text-center">Becurable Medical Complex </h5> 
                   </div> 
                <div className="d-flex justify-content-between">
                    <div className="">
                        <h6>Provider Name</h6>
                        <p>Dr. Iqra Idrees</p>
                    </div>
                    <div className="">
                    <h6>Patient Name</h6>
                        <p>Asad Wali</p>
                    </div>    
                </div>
                <div className="form-group">
                  <label>Medicine Names</label>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="names"
                    id="names"
                    placeholder="Medicine Names"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="names" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Timing for medicine</label>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="timing"
                    id="timing"
                    placeholder="Mornning | Evening"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="timing" />
                  </div>
                </div>

                <div className="form-group">
                  <label>Instructions</label>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="instructions"
                    id="instructions"
                    placeholder="instructions how to take medicine"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="instructions" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Lab Test</label>
                  <Field
                    className="form-input placeholder"
                    type="text"
                    name="test"
                    id="test"
                    placeholder="CBC"
                  />
                  <div className="text-danger text-start">
                    <ErrorMessage name="test" />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" className="btn modal_btn btn-sm">
                  Add
                </button>
              </div>

            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default PrescriptionForm;
