import React from 'react'
import './specialization-banner.style.css';

const SpecializationBanner = () => {
    return (

        <section className='container'>
        <div className="services-container" data-aos="fade-up" data-aos-delay="150">
          <h1>Our Healthcare <span>Services</span></h1>
          <p>Explore the healthcare services. Becurable provides as Pakistan’s first 
              Healthcare-as-a-Service Provider.</p>
        </div>
      </section>
    )
} 

export default SpecializationBanner;