import React from "react";
import './blog-descriptions.style.css';

const BlogDescriptions = () => {
    return (
        <section id="blog-detail">
            <div className="container">
                <div className='content-wrapper mb-5'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='user-column text-center mt-5'>
                                <img className="mb-3" src="/assets/AsadWali.jpg" alt="author" />
                                <p className="mb-0">Written By</p>
                                <h2 className="m-0 lignheight-26">Asad Wali</h2>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-12 col-sm-12'>
                            <div className='details-panel mt-5'>
                                <p>12 November, 2022</p>
                                <h1 className="mb-3">Breast Cancer</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12 mt-5 mb-5">
                            <p className="blogs-description">
                                Almost everyone nowadays has heard of breast cancer, which develops when cells in the breast proliferate
                                unchecked. Several factors contribute to the difficulty of treating breast cancer. Statistically speaking,
                                women are more likely to contract it than males. It represents one of the most often diagnosed cancers in
                                women and most often affects the milk ducts or the lobules. Every woman has to know the causes of this
                                terrible disease so that she may take the necessary precautions to avoid it. Breast cancer comes in a variety
                                of forms and can be treated in a wide variety of ways. While many patients may share the same diagnosis or
                                undergo the same course of therapy, everyone's individual story is different.
                            </p>
                            <h4>What is Breast Cancer ?</h4>
                            <p className="blogs-description">
                                In its earliest stages, breast cancer presents as a palpable lump or other abnormality in the breast. If it is
                                not caught early, it has the potential to spread to the lymph nodes and other organs if left untreated. Although
                                breast cancer is more common in women, men are not immune to the illness.
                            </p>
                            <h4>How does it begin, and what factors contribute to its progression, in the breast?</h4>
                            <p className="blogs-description">
                                Breast cancer causes and development have been studied extensively. Unfortunately, researchers have not identified
                                all breast cancer risk factors, making it difficult to predict who would be impacted. However, some conditions may
                                put certain people at danger. The likelihood of developing breast cancer increases with age, being female, having
                                undergone radiation or hormone therapy, being a heavy drinker, and having a personal or family history of the disease.
                            </p>
                            <h4>Causes:</h4>
                            <p className="blogs-description">
                                While we don't know what causes breast cancer in women, we can do something about it. Nevertheless, there are things like:
                                <ul>
                                    <div className="lists">
                                        <li>Aging</li>
                                        <p className="blogs-description">
                                            Unbelievably, one of the prevalent elements is just the passage of time. The likelihood of
                                            being diagnosed with this illness also rises with age.
                                        </p>
                                    </div>
                                    <div className="lists">
                                        <li>The Effects of Drinking Alcohol</li>
                                        <p className="blogs-description">
                                            Limiting your intake of alcohol can help you prevent this risk, as excessive drinking
                                            increases it by a factor of 2.
                                        </p>
                                    </div>
                                    <div className="lists">
                                        <li>Heavy Breast Tissue</li>
                                        <p className="blogs-description">
                                            Getting heavy breast tissue is another factor that likely contributes to the development
                                            of the illness. A possible side effect is a rise in the proliferation of disease-causing cells.
                                        </p>
                                    </div>
                                    <div className="lists">
                                        <li>Abnormally early menstruation</li>
                                        <p className="blogs-description">
                                            Girls who start menstruating before the age of 12 are at a higher risk of developing the disease.
                                        </p>
                                    </div>
                                    <div className="lists">
                                        <li>Negative Effects of a Delayed Birth or Lack of Pregnancy</li>
                                        <p className="blogs-description">
                                            Women who, for whatever reason, wait until later in life to start a family are statistically more
                                            likely to get breast cancer. As a result, it has an impact on women who have fertility problems.
                                        </p>
                                    </div>
                                    <div className="lists">
                                        <li>Prior or ancestry-related</li>
                                        <p className="blogs-description">
                                            Incidence of breast cancer in the other breast increases after a previous diagnosis in one breast.
                                            If you have a close relative who has breast cancer, your chance of developing the disease is significantly increased.
                                        </p>
                                    </div>
                                </ul>
                            </p>
                            <p className="blogs-description">
                                Pepsinogen C, a cancer-causing aspartic protease, is secreted by gastric main cells, according to research published in
                                "Cancer Cell International" (PGC). New research reveals that cancer cells' infiltration of the mammary glands basement membrane
                                is a crucial stage in the development of this illness. These results demonstrate that Peptidyl Arginine Deiminase 2
                                is essential for the translocation and motility of cancer cells (PAD2). Inhibition of peptidyl arginine deiminase 2
                                preserves basement membranes in ductal carcinoma in situ xenograft tumors. Reducing cell migration and altering their
                                shape by inhibiting or eliminating PAD2 may have therapeutic benefits.
                            </p>
                            <h4>Types of Breast Cancer</h4>
                            <p className="blogs-description">
                                Each kind of breast cancer is differentiated by its location, extent, and behavior. Your breast cancer kind will help
                                you and your doctor choose treatments.
                            </p>
                            <p className="blogs-description">
                                Here, you'll find information about a variety of breast cancers, from the various morphological subtypes to male
                                breast cancer and phyllodes tumors of the breast.
                                <ol className="lists">
                                    <li>Benign breast cancer</li>
                                    <li>Malignant lobular breast disease</li>
                                    <li>Malignant breast phyllodes tumors</li>
                                </ol>
                            </p>
                            <h4>Benign breast cancer</h4>
                            <p className="blogs-description">
                                Invasive breast cancer has spread into adjacent healthy tissue. Invasive breast cancers are classified by
                                where they start in the breast.
                                <ul>
                                    <li className="blogs-description"><strong>Invasive ductal carcinomas</strong> are breast tumors that start in the milk ducts,
                                        which connect the mammary glands lobules to the lactation glands (IDC). . About 80% of breast cancer diagnoses
                                        are of the invasive ductal carcinoma kind.</li>

                                    <li className="blogs-description"><strong>Invasive lobular carcinoma</strong> occurs when breast lobules, which produce
                                        milk, acquire cancer (ILC). Invasive lobular carcinoma is the second most frequent kind of breast cancer, making up
                                        10% of all cases.</li>

                                </ul>
                            </p>
                            <p className="blogs-description">
                                Benign breast cancer subtypes have distinct features that affect progression and therapy.
                            </p>
                            <ul className="blogs-description">
                                <li><p>Triple-negative rapidly spreading invasive breast tumors are deficient in the female hormones estrogen and progesterone and also lack the HER2 proteins. Benign breast cancers are about as likely to be triple-negative as other types.</p></li>
                                <li><p>The return of invasive breast cancer following therapy is considered repeated. Local recurrence occurs when the cancer returns to the original site, while regional recurrence occurs when it spreads to lymph nodes in the chest wall or under the arm (metastatic or distant recurrence).</p></li>
                                <li><p>Men can get breast cancer, however rarely. Men account for less than 1% of breast cancer cases. The most frequent male breast cancer is invasive ductal carcinoma.</p></li>
                                <li><p>Paget disease, a rare form of breast cancer, can occur in the nipple.</p></li>
                            </ul>

                            <p className="blogs-description">
                                Invasive breast cancer has five molecular subgroups based on tumor gene expression. The
                                molecular subtype of breast cancer can affect treatment and prognosis.
                            </p>
                            <h4>Malignant lobular breast disease</h4>
                            <p className="blogs-description">
                                Non-invasive breast cancer is in situ. Breast precancers do not spread. Non-invasive breast cancer has two types:
                                <ul className="blogs-description">
                                    <li><p>Ductal carcinoma in situ is cancer that remains in the milk ducts (DCIS). Although DCIS is not fatal on its own, it does raise the odds that the patient may develop cancer later in life future on. DCIS is seen in 16 percent of all breast cancers.</p></li>
                                    <li><p>Lobular carcinoma in situ is non-metastatic cancer (LCIS). LCIS is not breast cancer, despite common assumption.</p></li>
                                </ul>
                            </p>
                            <h4>Malignant breast phyllodes tumors</h4>
                            <p className="blogs-description">
                                Cancer of the phyllodes occurs in less than one percent of breasts. About 25% of phyllodes tumors are malignant, while the vast majorities are benign.
                            </p>
                            <h4>Treatment of Breast cancer</h4>
                            <p className="blogs-description">
                                All therapies may be broken down into two broad categories: gold standard and experimental. Current treatments for breast cancer include both standard and experimental strategies. Surgical removal of the tumor, hormone therapy, chemotherapy, radiation therapy, and targeted therapy are the most often used therapies for breast cancer.
                            </p>
                            <h4>Breast Cancer Surgical Treatment</h4>
                            <p className="blogs-description">Surgery may remove malignant cells. Depending on illness stage and distribution, the entire breast or part of it is removed. Three types of operations are categorized by the amount of surgery needed to eradicate malignant cells.</p>
                            <p className="blogs-description">It is possible to save the affected breast by breast-conserving surgery. While a lumpectomy just eliminates the affected tissue and the tumor, a partial mastectomy removes a larger portion of the breast and a greater amount of healthy tissue. lymph nodes under the arm may be removed during these procedures. It's common to practice removing lymph nodes either before or after surgery.</p>
                            <p className="blogs-description">
                                Total mastectomy removes the whole malignant breast. Biopsy involves lymph node removal.
                                <ul className="blogs-description">
                                    <li><p>Modified radical mastectomy eliminates large amounts of malignant tissue. This procedure removes the cancerous breast, arm lymph nodes, and chest muscle lining. This procedure may potentially eliminate damaged muscles of the Chest.</p></li>
                                    <li><p>The lymph nodes in the armpits and the chest wall are also removed during a radical mastectomy. Halsted radical mastectomy treats breast cancer.</p></li>
                                    <li><p>Chemotherapy, hormone therapy, or radiation therapy can eliminate cancer cells after these procedures. Adjuvant therapy prevents cancer recurrence. Post-mastectomy breast implants may be an option for certain people.</p></li>
                                </ul>
                            </p>
                            <h4>Conclusion</h4>
                            <p className="blogs-description">
                                Preventing breast cancer requires understanding and its causes. Men can get breast cancer too. Alcohol consumption over recommended limits reduces this risk by a factor of 2. The chance of developing breast cancer in the other breast appears to be higher if you have a first-degree relative who has already been diagnosed with the illness. Most breast cancers are invasive ductal carcinoma. It is from this area that invasive breast cancers often spread. While breast cancer is more common in women, men are much less likely to be diagnosed with it. IDC is a common kind of cancer in male breasts. Tumors originating in the phyllodes are usually harmless (75%). Surgery, hormone therapy, chemotherapy, radiation, and targeted therapies are all used to treat breast cancer. The decision to remove a breast is made in the relation to cancer's stage and location. If a woman gets a mastectomy, she may decide to reconstruct her breasts using implants.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDescriptions;