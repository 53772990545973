import React from 'react'
import { Link } from 'react-router-dom';
import "./mobile-navbar.style.css";
import { MdDashboard, MdEditNote } from "react-icons/md";
import { FaUserAlt, FaCalendarAlt, FaListAlt, FaUsers } from "react-icons/fa";

const ProviderMobileMenu = ({ toggleMenu }) => {

  return (
    <div className='overlay'>
      <div className='menu'>
        <div className='d-flex justify-content-between align-items-center mx-3 my-2 mb-4'>
        <img className="logoimage" src="/assets/logo.png" alt="logo" />
          <img className="crossimage" src="/assets/icons/X.svg" alt='close' onClick={toggleMenu} />
        </div>
        <div className="menu-content-wrapper">
        {/* <div className='mx-3 mb-5'>
          <div className="menu-title mb-3">Guest User</div>
          <div className="menu-detail text-gray-900 mb-3">You are using account as Guest User. To book appointment you need to login or sign up to continue</div>
          <button className='secondary-btn me-2'>Login</button>
          <button className='primary-btn'>Signup</button>
        </div> */}
        <div className='user-info d-flex'>
          <img className='userImage' src='/assets/AsadWali.jpg' alt='user'/>
          <div className='ms-3'>
          <h5>Asad Wali</h5>
          <p>Doctor ID: 12345678</p>
          </div>
        </div>
        <nav className="d-flex flex-column gap-2">
          <a className="menu-link" href="/#/dashboard" onClick={toggleMenu}>
          <MdDashboard />
            <span className="menu-text">Dashboard</span>
          </a>
          <a className="menu-link" href="/#/profile" onClick={toggleMenu}>
          <FaUserAlt />
            <span className="menu-text">Profile</span>
          </a>

          <a className="menu-link" href="/#/appointments" onClick={toggleMenu}>
          <FaListAlt />
            <span className="menu-text">Appointment</span>
          </a>

          <a className="menu-link" href="/#/mypatients" onClick={toggleMenu}>
          <FaUsers />
            <span className="menu-text">My Patients</span>
          </a>

          <a className="menu-link" href="/#/calender" onClick={toggleMenu}>
          <FaCalendarAlt />
            <span className="menu-text">Calender</span>
          </a>

          <a className="menu-link" href="/#/earnings" onClick={toggleMenu}>
          <MdEditNote />
            <span className="menu-text">My Earnings</span>
          </a>

        </nav>
        </div>
      </div>
    </div>
  )
}

export default ProviderMobileMenu;
