import { 
    login, 
    signup, 
    OTPCode, 
    resendOTP, 
    forgotPassword, 
    changePassword, 
    refreshToken, 
    completeProfile, } 
from "../../api/index";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const loginUser = createAsyncThunk("users/login", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("Email", payload.email);
        formData.append("Password", payload.password)
        const { data } = await login(formData)
        return {...data, email: payload.email, password: payload.password};
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const otpVerification = createAsyncThunk("users/otpCode", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("Email", payload.email);
        formData.append("OTP", payload.code)
        const { data } = await OTPCode(formData)
        return data;
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const resendOTPCode = createAsyncThunk("users/otpResend", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("Email", payload.email);
        formData.append("Password", payload.password)
        const { data } = await resendOTP(formData)
        return data;
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const forgotPasswordSend = createAsyncThunk("users/passwordForgort", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("Email", payload.email);
        const { data } = await forgotPassword(formData)
        return {...data, email: payload.email};
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const passwordChanged = createAsyncThunk("users/passwordChange", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("Email", payload.email);
        formData.append("OTP", payload.otp);
        formData.append("NewPassword", payload.password);
        const { data } = await changePassword(formData)
        return data;
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const tokenRefresh = createAsyncThunk("users/refreshToken", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("accessToken", payload.accessToken);
        const { data } = await refreshToken(formData)
        return data;
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const signupUser = createAsyncThunk("users/registration", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("FirstName", payload.firstname);
        formData.append("LastName", payload.lastname)
        formData.append("Email", payload.email)
        formData.append("Password", payload.password)
        formData.append("PhoneNo", payload.number)
        formData.append("Role", payload.role)
        const { data } = await signup(formData)
        return data;
    } catch (err) {
        return rejectWithValue(err)
    }
});

export const profileCompletion = createAsyncThunk("users/completeProfile", async (payload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("UserUuid", payload.UserUuid);
        formData.append("ProfileImage", payload.ProfileImage)
        formData.append("Salutation", payload.Salutation)
        formData.append("AboutMe", payload.AboutMe)
        formData.append("Dob", payload.Dob)
        formData.append("Gender", payload.Gender)
        formData.append("Country", payload.Country)
        formData.append("City", payload.City)
        formData.append("Address", payload.Address)
        formData.append("QualificationUuids", payload.QualificationUuids)
        formData.append("SpecializationUuids", payload.SpecializationUuids)
        formData.append("MedLicense", payload.MedLicense)
        const { data } = await completeProfile(formData)
        return data;
    } catch (err) {
        return rejectWithValue(err)
    }
});
