import React from "react";
import { Link } from "react-router-dom";
import "./this-month.style.css";

const ThisMonth = () => {
  return (
    <div className="this-month">
      <div className="row gy-3">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="this-month-card">
              <div className="this-month-content">
                  <h5>Total Upcoming Appointments</h5>
                  <small>This Month</small>
              </div>
              <div className="d-flex justify-content-between"> 
              <h3>15</h3>
              <Link to="#">View All</Link>
              </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="this-month-card2">
              <div className="this-month-content">
                  <h5>Total Income</h5>
                  <small>This Month</small>
              </div>
              <div className="d-flex justify-content-between"> 
              <h3>PKR 150,000</h3>
              <Link to="#">View All</Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThisMonth;
