import React from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "./calender.style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DashboardCalendar = () => {
  return (
    <>
      <div className="card simple-card">
        <div className="card-body">
          <Calendar
            selectable
            className="custom-calander"
            defaultView={Views.MONTH}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 290 }}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardCalendar;
