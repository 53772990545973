import React from "react";
import './cta.style.css';
import { useNavigate } from 'react-router-dom';

const CTA = () => {
    const navigate = useNavigate();

    const navigateToAppointment = () => {
        navigate('/all-doctor');
    }

    return (
        <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
                <div className="row">
                    <div className="col-lg-9 text-center text-lg-start">
                        <h4>Book Your Appointment Now</h4>
                        <button className="cta-button mt-3" onClick={navigateToAppointment}>Book Now</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTA;
