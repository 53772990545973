import React from "react";
import "./appointment-details.style.css";
import { Link } from "react-router-dom";

const AppointmentDetails = () => {
  return (
    <div className="grey-container">
      <div className="p-3 mt-3 d-flex justify-content-between">
        <h2>Appointment Details</h2>
        <Link style={{ color: "blue" }} to="/prescription-details">
          View Prescription
        </Link>
      </div>
      <h2 className="text-center">Be Curable Medical Complex</h2>
      <div className="row mt-5">
        <div className="ms-5 col-12 col-md-5">
          <h5>Patient Name:</h5>
          <p>Asad</p>

          <h5>Timings:</h5>
          <p>09:15 AM - July 20, 2022</p>

          <h5>Appointment Type:</h5>
          <p>Video Consulation</p>

          <h5>Reason For Cancelled:</h5>
          <p>Network Issue</p>
        </div>
        <div className="ms-5 col-12 col-md-5">
          <div className="">
            <h5>Doctor Name:</h5>
            <p>Dr. Asad Wali</p>
          </div>

          <h5>Appointment Status:</h5>
          <p>Completed</p>

          <h5>Fee:</h5>
          <p>500 PKR</p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetails;
