import React, { useCallback, useEffect } from "react";
import { Card, Grid } from "@material-ui/core";
import { Box, styled } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordSend } from "../../../shared/redux/actions/users.actions";
import Toaster from "../../../shared/toaster";
import Loader from "../../../shared/loader";
import { useForgotPasswordMutation } from "../../../shared/api/rtk"
import { updateUser } from "../../../shared/redux/reducers/users.reducer";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
};
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format!").required("Required!"),
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0)",
}));

const Section = styled(JustifyBox)(() => ({
  background: "#011F4B",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 1013,
    borderRadius: 20,
    margin: "1rem",
  },
}));

const ForgotPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state.users.isLoading);
  // const { isOTPSucceed } = useSelector((state) => state.users);

  const [forgotPassword, { isLoading, isSuccess, data, isError, error }] = useForgotPasswordMutation();



  useEffect(() => {
    if (isSuccess) {
      navigate("/change-password");
    }
  }, [navigate, isSuccess]);


  const onSubmit = useCallback(
    async values => {
      try {
        const formData = new FormData();
        formData.append("Email", values.email);
        await forgotPassword(formData).unwrap();
        dispatch(updateUser({ email: values.email }))
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, forgotPassword]
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.message || error?.data || "Something Went Wrong!")
    }
  }, [isError, error])

  return (
    <>
      <Section>
        <Card className="card">
          <Grid container>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <JustifyBox>
                <div className="auth-logo-container">
                  <img
                    src="./assets/logo.png"
                    alt="logo"
                    className="auth-logo"
                  />
                </div>
              </JustifyBox>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <ContentBox>
                <h1>Password Recovery</h1>
                <p>We’ll send you instruction on email</p>
                {/* form-start */}
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      <Form className="">
                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="email"
                          id="name"
                          placeholder="Email Address"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="email" />
                        </div>
                        <div className="row">
                          <div className="content col-sm">
                            <p>
                              Return to
                              <Link to="/login">
                                <span className="forgot_span ms-1">
                                  Sign In
                                </span>
                              </Link>
                            </p>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <button className="btn form_btn" type="submit">
                              Reset Password
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
                {/* form-end */}
              </ContentBox>
            </Grid>
          </Grid>
        </Card>
        <Toaster />
        <Loader loading={isLoading}/>
      </Section>
    </>
  );
};

export default ForgotPassword;
