import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./patient-profile.style.css";

const PatientInfo = () => {
  const userRole = "patient";
  const [isEdit, setEdit] = useState(false);
  const onEdit = () => {
    setEdit(!isEdit);
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    dob: "",
    gender: "",
    age: "",
    languagespeaks: "",
    email: "",
    phonenumber: "",
    country: "",
    city: "",
    address: "",
    state: "",
    bloodgroup: "",
    allergies: "",
    surgicalhistory: "",
    medicalhistory: "",
    currentmedication: "",
  };
  const onSubmit = () => {};
  const validationSchema = Yup.object({
    // firstname: Yup.string().required("firstname required!"),
    // email: Yup.string().email("Invalid email format!").required("Email is required!"),
    // phonenumber: Yup.string().required("Mobile number is required!"),
  });

  return (
    <>
      {/* About Details */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="p-3 mt-3 d-flex justify-content-between">
            <h2>Basic Details</h2>
            {userRole === "patient" ? (
              <div className="">
                {isEdit === true && (
                  <img
                    className="me-3 edit_icon"
                    src="/assets/icons/save.svg"
                    alt="save"
                  />
                )}
                {isEdit === true ? (
                  <button onClick={onEdit} className="btn">
                    <img
                      className="edit_icon"
                      src="/assets/icons/close.svg"
                      alt="close"
                    />
                  </button>
                ) : (
                  <button onClick={onEdit} className="btn">
                    <img
                      className="edit_icon"
                      src="/assets/icons/edit.svg"
                      alt="edit"
                    />
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mx-5">
            <div className="row">
              {/* First Name Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>First Name</h5>
                {isEdit === false ? (
                  <p>Asad</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="First Name"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="firstname" />
                    </div>
                  </>
                )}
              </div>

              {/* Last Name Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Last Name</h5>
                {isEdit === false ? (
                  <p>Wali</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="lastname" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              {/* DOB Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>DOB</h5>
                {isEdit === false ? (
                  <p>08 March, 1996</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="dob"
                      id="dob"
                      placeholder="Date Of Birth"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="dob" />
                    </div>
                  </>
                )}
              </div>

              {/* Gender Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Gender</h5>
                {isEdit === false ? (
                  <p>Male</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="gender"
                      id="gender"
                      placeholder="Gender"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="gender" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              {/* Age Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Age</h5>
                {isEdit === false ? (
                  <p>26</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="age"
                      id="age"
                      placeholder="Age"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="age" />
                    </div>
                  </>
                )}
              </div>

              {/* Languages Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Languages Speaks</h5>
                {isEdit === false ? (
                  <p>English, Urdu, Punjabi</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="languagespeaks"
                      id="languagespeaks"
                      placeholder="Languages Speaks"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="languagespeaks" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr />
          </div>

          {/* Contact Details */}
          <div className="p-3 mt-3">
            <h2>Contact Details</h2>
          </div>
          <div className="mx-5">
            <div className="row">
              {/* EmailColumn */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Email</h5>
                {isEdit === false ? (
                  <p>asadhamid27@gmail.com</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="email" />
                    </div>
                  </>
                )}
              </div>

              {/* Number Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Mobile Number</h5>
                {isEdit === false ? (
                  <p>+92 306 4077401</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="phonenumber"
                      id="phonenumber"
                      placeholder="Mobile Number"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="phonenumber" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              {/* Country Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Country</h5>
                {isEdit === false ? (
                  <p>Pakistan</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="country"
                      id="country"
                      placeholder="Country"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="country" />
                    </div>
                  </>
                )}
              </div>

              {/* city Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>City</h5>
                {isEdit === false ? (
                  <p>Lahore</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="city" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              {/* address Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Address</h5>
                {isEdit === false ? (
                  <p>Johae Town</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Address"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="address" />
                    </div>
                  </>
                )}
              </div>

              {/* state Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>State/Province</h5>
                {isEdit === false ? (
                  <p>Punjab</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="state"
                      id="state"
                      placeholder="State"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="state" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr />
          </div>

          {/* Medical Details */}
          <div className="p-3 mt-3">
            <h2>Medical Details</h2>
          </div>
          <div className="mx-5">
            <div className="row">
              {/* bloodgroup */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Blood Group</h5>
                {isEdit === false ? (
                  <p>A+</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="bloodgroup"
                      id="bloodgroup"
                      placeholder="Bloog Group"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="bloodgroup" />
                    </div>
                  </>
                )}
              </div>

              {/* allergies Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Allergies</h5>
                {isEdit === false ? (
                  <p>Dust Infection</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="allergies"
                      id="allergies"
                      placeholder="Allergies"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="allergies" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              {/* surgicalhistory Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Surgical History</h5>
                {isEdit === false ? (
                  <p>None</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="surgicalhistory"
                      id="surgicalhistory"
                      placeholder="Surgical History"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="surgicalhistory" />
                    </div>
                  </>
                )}
              </div>

              {/* medicalhistory Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Medical History</h5>
                {isEdit === false ? (
                  <p>None</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="medicalhistory"
                      id="medicalhistory"
                      placeholder="Medical History"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="medicalhistory" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              {/* currentmedication Column */}
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h5>Currentme Dication</h5>
                {isEdit === false ? (
                  <p>None</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="currentmedication"
                      id="currentmedication"
                      placeholder="Current Medication"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="currentmedication" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      {/* <AboutModal /> */}
    </>
  );
};

export default PatientInfo;
