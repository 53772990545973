import React from "react";
import "./diseases-details.style.css";

const DiseasesDetails = () => {
  return (
    <div className="dont-provide">
      <div className="container mt-5 mb-5">
        <div className="content">
          <h1>All Diesease</h1>
        </div>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                General Medicine
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <h5>General Physician</h5>
                <div className="mt-3">
                  <p className="badge">Typhoid Fever</p>
                  <p className="badge">Dengue Fever</p>
                  <p className="badge">Flu</p>
                  <p className="badge">Chicken Pox</p>
                  <p className="badge">Malaria</p>
                  <p className="badge">Cold</p>
                  <p className="badge">Asthma</p>
                  <p className="badge">Fever</p>
                  <p className="badge">Sore Throat</p>
                  <p className="badge">Blood Pressure</p>
                </div>

                <h5>General Surgeon</h5>
                <div className="mt-3">
                  <p className="badge">Typhoid Fever</p>
                  <p className="badge">Dengue Fever</p>
                  <p className="badge">Flu</p>
                  <p className="badge">Chicken Pox</p>
                  <p className="badge">Malaria</p>
                  <p className="badge">Cold</p>
                  <p className="badge">Asthma</p>
                  <p className="badge">Fever</p>
                  <p className="badge">Sore Throat</p>
                  <p className="badge">Blood Pressure</p>
                </div>

              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Gynecology
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
               <div className="accordion-body">
                <h5>General Physician</h5>
                <div className="mt-3">
                  <p className="badge">Typhoid Fever</p>
                  <p className="badge">Dengue Fever</p>
                  <p className="badge">Flu</p>
                  <p className="badge">Chicken Pox</p>
                  <p className="badge">Malaria</p>
                  <p className="badge">Cold</p>
                  <p className="badge">Asthma</p>
                  <p className="badge">Fever</p>
                  <p className="badge">Sore Throat</p>
                  <p className="badge">Blood Pressure</p>
                </div>

                <h5>General Surgeon</h5>
                <div className="mt-3">
                  <p className="badge">Typhoid Fever</p>
                  <p className="badge">Dengue Fever</p>
                  <p className="badge">Flu</p>
                  <p className="badge">Chicken Pox</p>
                  <p className="badge">Malaria</p>
                  <p className="badge">Cold</p>
                  <p className="badge">Asthma</p>
                  <p className="badge">Fever</p>
                  <p className="badge">Sore Throat</p>
                  <p className="badge">Blood Pressure</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiseasesDetails;
