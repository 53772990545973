import React, { useCallback, useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { Box, styled } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../shared/redux/reducers/users.reducer";
import { resendOTPCode } from "../../../shared/redux/actions/users.actions";
import Toaster from "../../../shared/toaster";
import Loader from "../../../shared/loader";
import WaitModal from "./wait-modal/wait-modal.component";
import { useOTPCodeMutation } from "../../../shared/api/rtk";
import { toast } from "react-toastify";
import RejectModal from "./reject-modal/reject-modal.componet";

const initialValues = {
  code: "",
};
const validationSchema = Yup.object({
  code: Yup.string().required("Required!"),
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0)",
}));

const Section = styled(JustifyBox)(() => ({
  background: "#011F4B",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 1013,
    borderRadius: 20,
    margin: "1rem",
  },
}));

const OTPVerfication = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [isWaitApprovalModalOpen, setIsWaitApprovalModalOpen] = useState(false);
  const [isWaitRejectedModalOpen, setIsRejectedModalOpen] = useState(false);
  const [submitOTP, { data, isLoading, isError, error }] = useOTPCodeMutation();

  const [timer, setTimer] = useState(20);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    let modalTimer;

    if (data) {
      dispatch(updateUser(data));

      if (data.isRegistered) {
        switch (data.status) {
          case "Pending":
            setIsWaitApprovalModalOpen(true);
            modalTimer = setTimeout(() => {
              setIsWaitApprovalModalOpen(false);
              navigate("/login");
            }, 5000);
            break;
          case "Approved":
            localStorage.setItem("token", data.token)
            dispatch(updateUser(data))
            navigate("/dashboard");
            break;
          case "Rejected":
            setIsRejectedModalOpen(true);
            modalTimer = setTimeout(() => {
              setIsRejectedModalOpen(false);
              navigate("/login");
            }, 5000);;
            break;
          default:
            break;
        }
      } else {
        navigate("/complete-profile");
      }
    }

    return () => {
      if (modalTimer) {
        clearTimeout(modalTimer)
      }
    }
  }, [data, dispatch, navigate]);

  // useEffect(() => {
  //   console.log("Checking conditions:", isOTPSucceed, user.isRegistered, user.status);
  //   if (
  //     isOTPSucceed &&
  //     user.isRegistered === false &&
  //     user.status === "Pending"
  //   ) {
  //     navigate("/complete-profile");
  //   } else if (
  //     isOTPSucceed &&
  //     user.isRegistered === true &&
  //     user.status === "Pending"
  //   ) {
  //     const modalTimer = setTimeout(() => {
  //       console.log("Closing modal after 10 seconds");
  //       setIsWaitApprovalModalOpen(false);
  //       navigate("/login");
  //     }, 10000);

  //     return () => {
  //       clearTimeout(modalTimer);
  //     };
  //   } else if (
  //     isOTPSucceed &&
  //     user.isRegistered === true &&
  //     user.status === "Approved"
  //   ) {
  //     navigate("/dashboard");
  //   } else {
  //     console.log("Invalid Condition");
  //   }
  // }, [navigate, data]);

  // useEffect(() => {
  //   dispatch(loginFalse());
  // }, [dispatch]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const formData = new FormData();
        formData.append("Email", user.email);
        formData.append("OTP", values.code);
        await submitOTP(formData).unwrap();
      } catch (err) {
        console.log(err);
      }
    },
    [submitOTP, user.email]
  );

  const handleResendClick = useCallback(
    (values) => {
      setTimer(20);
      dispatch(
        resendOTPCode({ ...values, email: user.email, password: user.password })
      );
    },
    [dispatch, user.email, user.password]
  );

  useEffect(() => {
    if (isError) toast.error(error?.message || "Something Went Wrong");
  }, [isError, error]);

  return (
    <Section>
      <Card className="card">
        <Grid container>
          <Grid
            item
            lg={5}
            md={5}
            sm={5}
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <JustifyBox>
              <div className="auth-logo-container">
                <img src="./assets/logo.png" alt="logo" className="auth-logo" />
              </div>
            </JustifyBox>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <ContentBox>
              <h1>OTP Verification</h1>
              <p>The OTP code has been send to you at {user.email}</p>
              {/* form-start */}
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form className="">
                      <Field
                        className="form-input placeholder"
                        type="text"
                        name="code"
                        id="code"
                        placeholder="Enter 6-digits code"
                      />
                      <div className="text-danger text-start">
                        <ErrorMessage name="code" />
                      </div>

                      <div className="row">
                        <div className="content col-sm">
                          {timer > 0 ? (
                            <p>
                              Resend code in{" "}
                              <strong>
                                <span>{timer}</span>
                              </strong>{" "}
                              seconds
                            </p>
                          ) : (
                            <p onClick={handleResendClick}>
                              <img
                                className="otpresend"
                                src="/assets/icons/resend.svg"
                                alt="resend"
                              />{" "}
                              Resend Code
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="content col-sm">
                          <p>
                            Return to
                            <Link to="/login">
                              <span className="forgot_span ms-1">Sign In</span>
                            </Link>
                          </p>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <button className="btn form_btn" type="submit">
                            Verify
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>

              {/* form-end */}
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
      <WaitModal isOpen={isWaitApprovalModalOpen} />
      <RejectModal isOpen={isWaitRejectedModalOpen} />
      <Toaster />
      <Loader loading={isLoading} />
    </Section>
  );
};

export default OTPVerfication;
