import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import EducationModal from "./educations-modal.component";
import "./educations.style.css";

const Education = () => {
  return (
    <>
      <div className="card custom-card">
        <div className="card-header d-flex justify-content-between mb-1">
          <h5 className="card-title align-self-center">Your Education </h5>
          <div className="header-button align-self-center">
            <label>
              <button
                type="button"
                className="btn p-1 ms-1"
                data-bs-toggle="modal"
                data-bs-target="#CertificateModal"
              >
                <BsPlusCircle />
              </button>
            </label>
          </div>
        </div>

        <div className="card-body card-table">
          <div className="table-responsive">
            <table className="">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Provider</th>
                  <th scope="col">Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Neurologist</td>
                  <td>Docotr's Hospital</td>
                  <td>22/07/2021</td>
                  <td>
                        <img className="table-icon me-3" src="/assets/icons/bin.svg" alt="delete"/>
                        <img className="table-icon" src="/assets/icons/edit.svg" alt="edit"/>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <EducationModal />
    </>
  );
};

export default Education;
