import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const API = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://2iyyiu1pe0.execute-api.us-east-1.amazonaws.com/Prod/api",
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if (token)
                return headers.set("Authorization", `Bearer ${token}`);
        }
    }),
    endpoints: builder => ({
        login: builder.mutation({
            query: formData => ({
                url: `/Auth/login`,
                method: "POST",
                body: formData,
                responseHandler: response => response.text()
            }),
        }),
        signUp: builder.mutation({
            query: formData => ({
                url: "/Auth/register",
                method: "POST",
                body: formData
            })
        }),
        OTPCode: builder.mutation({
            query: formData => ({
                url: "/Auth/verify-otp",
                method: "POST",
                body: formData
            })
        }),
        resendOTP: builder.mutation({
            query: formData => ({
                url: "/Auth/resend-otp",
                method: "POST",
                body: formData
            })
        }),
        forgotPassword: builder.mutation({
            query: formData => ({
                url: "/Auth/forgot-password",
                method: "POST",
                body: formData,
                responseHandler: response => response.text()
            })
        }),
        changePassword: builder.mutation({
            query: formData => ({
                url: "/Auth/verify-fp-otp",
                method: "POST",
                body: formData,
                responseHandler: response => response.text()
            })
        }),
        refreshToken: builder.mutation({
            query: formData => ({
                url: "/Auth/refresh-token",
                method: "POST",
                body: formData
            })
        }),
        completeProfile: builder.mutation({
            query: formData => ({
                url: "/Provider",
                method: "PUT",
                body: formData,
                responseHandler: response => response.text()
            })
        }),
        allQualification: builder.query({ query: () => "/Qualification" }),
        allSpecialization: builder.query({ query: () => "/Specialization" }),
        getUser: builder.query({ query: () => '/Admin/user' }),

        updateProviderStatus: builder.mutation({
            query: formData => ({
                url: "/Admin/status-update",
                method: "PUT",
                body: formData,
                responseHandler: response => response.text()
            })
        }),

    })
})

export const { 
    useLoginMutation, 
    useSignUpMutation, 
    useOTPCodeMutation, 
    useResendOTPMutation, 
    useForgotPasswordMutation, 
    useChangePasswordMutation, 
    useRefreshTokenMutation, 
    useCompleteProfileMutation,
    useUpdateProviderStatusMutation, 
    useAllQualificationQuery, 
    useAllSpecializationQuery, 
    useGetUserQuery 
} = API;