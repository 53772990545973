import React from "react";
import "./diseases-treated.style.css";

const DiseasesModal = () => {
  return (
    <div
      className="modal fade"
      id="DiseasesModal"
      tabindex="-1"
      aria-labelledby="AppModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="AppModalLabel">
              Add Diseases
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    General Medicine
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <h5>General Physician</h5>
                    <div className="mt-3">
                      <p className="diseases-treated">Typhoid Fever</p>
                      <p className="diseases-treated">Dengue Fever</p>
                      <p className="diseases-treated">Flu</p>
                      <p className="diseases-treated">Chicken Pox</p>
                      <p className="diseases-treated">Malaria</p>
                      <p className="diseases-treated">Cold</p>
                      <p className="diseases-treated">Asthma</p>
                      <p className="diseases-treated">Fever</p>
                      <p className="diseases-treated">Sore Throat</p>
                      <p className="diseases-treated">Blood Pressure</p>
                    </div>

                    <h5>General Surgeon</h5>
                    <div className="mt-3">
                      <p className="diseases-treated">Typhoid Fever</p>
                      <p className="diseases-treated">Dengue Fever</p>
                      <p className="diseases-treated">Flu</p>
                      <p className="diseases-treated">Chicken Pox</p>
                      <p className="diseases-treated">Malaria</p>
                      <p className="diseases-treated">Cold</p>
                      <p className="diseases-treated">Asthma</p>
                      <p className="diseases-treated">Fever</p>
                      <p className="diseases-treated">Sore Throat</p>
                      <p className="diseases-treated">Blood Pressure</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Gynecology
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <h5>General Physician</h5>
                    <div className="mt-3">
                      <p className="diseases-treated">Typhoid Fever</p>
                      <p className="diseases-treated">Dengue Fever</p>
                      <p className="diseases-treated">Flu</p>
                      <p className="diseases-treated">Chicken Pox</p>
                      <p className="diseases-treated">Malaria</p>
                      <p className="diseases-treated">Cold</p>
                      <p className="diseases-treated">Asthma</p>
                      <p className="diseases-treated">Fever</p>
                      <p className="diseases-treated">Sore Throat</p>
                      <p className="diseases-treated">Blood Pressure</p>
                    </div>

                    <h5>General Surgeon</h5>
                    <div className="mt-3">
                      <p className="diseases-treated">Typhoid Fever</p>
                      <p className="diseases-treated">Dengue Fever</p>
                      <p className="diseases-treated">Flu</p>
                      <p className="diseases-treated">Chicken Pox</p>
                      <p className="diseases-treated">Malaria</p>
                      <p className="diseases-treated">Cold</p>
                      <p className="diseases-treated">Asthma</p>
                      <p className="diseases-treated">Fever</p>
                      <p className="diseases-treated">Sore Throat</p>
                      <p className="diseases-treated">Blood Pressure</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="submit" className="btn modal_btn btn-sm">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiseasesModal;
