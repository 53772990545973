import React from 'react';
import './terms-banner.style.css';

const TermsBanner = () => {
    return (
        <section id="terms-banner">
        <div className="container terms-container" data-aos="fade-up" data-aos-delay="150">
            <h2>Terms Of Service</h2>
        </div>
    </section>
    )
}

export default TermsBanner;