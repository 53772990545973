import React from "react";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import "./settings.style.css";

const Setting = () => {
  return (
    <>
      <div className="list-container">
        <h5 className="p-3">Account Setting</h5>
        <div className="container row">
          <div className="ms-5 col-12 col-md-12">
            <div className="py-3 pe-5">
              <h5 className="mb-3 text-black">Login Email</h5>
              <div className="d-flex justify-content-between">
                <p>asadhamid27@gmail.com</p>
                <Link to="/profile" style={{ color: "blue" }}>
                  Edit Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="list-container">
        <h5 className="p-3">Payment</h5>
        <div className="container row">
          <div className="ms-5 col-12 col-md-12">
            <div className="py-3 pe-5">
              <div className="d-flex justify-content-between">
                <p>No Payment Method Added Yet</p>
                <Link to="" style={{ color: "blue" }}>
                  Add
                </Link>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <p>Transactions</p>
                <Link to="" style={{ color: "blue" }}>
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="list-container">
        <h5 className="p-3">Notifications</h5>
        <p className="ps-3">
          Becurable may still send you important notifications about your
          account outside of your preferred notification settings.
        </p>
        <div className="container row notificationSetting">
          <p className="ps-3">
            Disable to turn off all notifications from these channels
          </p>
          <div className="container row">
            <div className="ms-5 col-lg-1">
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  <span>
                    <MdEmail />
                  </span>
                  <label>Email</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="list-container">
        <h5 className="p-3">About Becurable</h5>
        <Link to="#" style={{ color: "#011F4B" }}>
        <div className="setting-info">
            Terms And Condition
        </div>
        </Link>
        <Link to="#" style={{ color: "#011F4B" }}>
        <div className="setting-info">
            Privacy Policy
        </div>
        </Link>
        <Link to="#" style={{ color: "#011F4B" }}>
        <div className="setting-info">
            Health & Support
            <div class="description">24/7 customer support</div>
        </div>
       
        </Link>
      </div>
    </>
  );
};

export default Setting;
