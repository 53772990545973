import React from 'react'
import "./mobile-navbar.style.css";

const ProviderMobileNavbar = ({ toggleMenu }) => {
  return (
    <>
      <div className='mobile-navbar d-flex d-md-none'>
      <img className="logoimage" src="/assets/logo.png" alt="logo" />
        <img src="/assets/icons/hamburger.svg" alt='hamburger' onClick={toggleMenu} />
      </div>
    </>
  )
}

export default ProviderMobileNavbar;
