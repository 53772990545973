import React from "react";
import "./mail-page.css";
import { useNavigate } from "react-router-dom";

const ResetPasswordMail = () => {
  let navigate = useNavigate();
  return (
    <div className="center-wrapper">
      <div className="container-codes">
        <div className="image-logo">
          <img src="/assets/becurable.png" alt="logo" />
        </div>
        <h1>Reset Your Password</h1>
        <div className="instructions">
          We received a request to reset your password. Click the button below
          to reset your password.
        </div>

        <button className="btn"  onClick={() => navigate("/change-password")}>Reset Password</button>

        <div className="footer">
          If you didn't request a password reset, please ignore this email or
          contact support.
        </div>
        <div className="socialmedia-images mt-4">
          <h4>Please follow us:</h4>
          <img className="me-5" src="/assets/facebook.png" alt="fb" />
          <img className="me-5" src="/assets/instagram.png" alt="insta" />
          <img src="/assets/youtube.png" alt="yt" />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordMail;
