import React from 'react';
import ContactBanner from './contact-banner/contact-banner.component';
import ContactDetails from './contact-details/contact-details.component';

const ContactUs = () => {
    return (
       <>
        <ContactBanner />
        <ContactDetails />
       </>
    )
}

export default ContactUs;