import React from "react";
import "./patient-wallet.style.css";
import TransactionList from "./transactions-list.component";

const PatientWallet = () => {
  return (
    <>
      <div className="wallet-section">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="balance-content">
            <div className="wallet-content-details">
              <h1>Becurable Wallet</h1>
              <p>Total Balance</p>
              <h3>5,000 PKR</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="actions-wallet">
              <h5>Actions</h5>
              <div className="d-flex justify-content-between">
                <div className="card wallet-cards">
                  <img src="/assets/icons/coins.png" alt="disease" />
                  <h6>Top Up</h6>
                </div>
                <div className="card wallet-cards card-color">
                  <img src="/assets/icons/bank.svg" alt="disease" />
                  <h6>Transfer</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TransactionList />
    </>
  );
};

export default PatientWallet;
