import React, { useState, useEffect } from "react";
import "./booking-details-modal.style.css";

const DetailsModal = () => {
  const [selectedDay, setSelectedDay] = useState("Today");
  const [slots, setSlots] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);

  const daySlots = {
    Sunday: {
      Morning: ["10:00 AM", "10:15 AM", "10:30 AM"],
      Afternoon: ["1:00 PM", "1:30 PM", "2:00 PM"],
      Evening: ["5:00 PM", "5:15 PM", "5:30 PM"],
    },
    Monday: {
      Morning: ["9:00 AM", "9:30 AM", "10:00 AM"],
    },
    Tuesday: {
      Morning: ["10:00 AM", "10:15 AM", "10:30 AM"],
      Afternoon: ["1:00 PM", "1:30 PM", "2:00 PM"],
      Evening: ["5:00 PM", "5:15 PM", "5:30 PM"],
    },
  };

  const getWeekDays = () => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const todayIndex = new Date().getDay();

    const daysForWeek = [];
    for (let i = 0; i < 7; i++) {
      daysForWeek.push(weekDays[(todayIndex + i) % 7]);
    }
    return daysForWeek;
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setSlots(daySlots[day] || {});
  };

  const handleSlotClick = (time) => {
    setSelectedSlot(time);
  };

  const computeSlotCount = (day) => {
    const daySlot = daySlots[day] || {};
    let count = 0;
    for (let segment in daySlot) {
      count += daySlot[segment].length;
    }
    return count;
  };

  useEffect(() => {
    setSlots(daySlots["Sunday"]);
  }, []);

  return (
    <>
      <div
        className="modal fade"
        id="bookingModal"
        tabindex="-1"
        aria-labelledby="bookingModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="bookingModalLabel">
                Doctor Avaibility
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="d-flex justify-content-inline custom-list">
              <ul className="list-group list-group-horizontal">
                {getWeekDays().map((day) => (
                  <li
                    className={`list-group-item ${
                      day === selectedDay ? "active-day" : ""
                    }`}
                    onClick={() => handleDayClick(day)}
                    key={day}
                  >
                    <p>{day}</p> {computeSlotCount(day)} slots available
                  </li>
                ))}
              </ul>{" "}
            </div>
            <div className="modal-body">
              {["Morning", "Afternoon", "Evening"].map((segment) => (
                <div className="shifts-section" key={segment}>
                  <p>{segment}</p>
                  <div className="shifts">
                    {(slots[segment] || []).map((time) => (
                      <span
                        className={`timing ${
                          selectedSlot === time ? "selected" : ""
                        }`}
                        key={time}
                        onClick={() => handleSlotClick(time)}
                      >
                        {" "}
                        {time}{" "}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-info"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-success">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsModal;
