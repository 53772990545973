import React, { useEffect } from "react";
import { Modal, Backdrop, Fade, makeStyles } from "@mui/material";
import "./reject-modal.style.css";

const RejectModal = ({ isOpen }) => {

  useEffect(() => {
    // You can add any additional logic here when the modal opens or closes
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className="modal-overlay">
          <div className="reject-modal">
            <div>
              <img
                className="reject-cross"
                src="./assets/icons/rejected.png"
                alt="wait"
              />
              <p>
              <strong className="reject"> OOPS </strong> <br></br>
                Your account is rejected.{" "}
                <strong>Please contact customer support.</strong>
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default RejectModal;
