import React from "react";
import "./reports.style.css";
import { Link } from "react-router-dom";

const Reports = () => {
  return (
    <>
      <div className="reportcard mb-3">
        <div className="reportstext">
          <h2>ECG Report</h2>
          <p>of Asad Wali</p>
        </div>
        <div className="infodetails">
          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/sethoscope.svg"
              alt="sethoscope"
            />
            <p className="report-text">Dr. Iqra</p>
          </div>

          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/time.svg"
              alt="time"
            />
            <p className="report-text">13 Aug, 2023 - 12:00 PM</p>
          </div>

          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/files.svg"
              alt="files"
            />
            <p className="report-text">ECGreport.jpg</p>
          </div>
        </div>
        <div className="bottom">
          <Link to="/report-form" className="details">
            <img className="btniconview me-2" src="/assets/icons/eye.svg" alt="eye"/> View Report
          </Link>
          <button className="outline-btn">
          <img className="btniconshare me-2" src="/assets/icons/share.svg" alt="share"/>Share Report</button>
          <button className="outline-btn-del">
          <img className="btnicondel me-2" src="/assets/icons/bin.svg" alt="del"/>Delete Reports</button>
        </div>
      </div>

      <div className="reportcard mb-3">
        <div className="reportstext">
          <h2>ECG Report</h2>
          <p>of Asad Wali</p>
        </div>
        <div className="infodetails">
          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/sethoscope.svg"
              alt="sethoscope"
            />
            <p className="report-text">Dr. Iqra</p>
          </div>

          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/time.svg"
              alt="time"
            />
            <p className="report-text">13 Aug, 2023 - 12:00 PM</p>
          </div>

          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/files.svg"
              alt="files"
            />
            <p className="report-text">ECGreport.jpg</p>
          </div>
        </div>
        <div className="bottom">
          <Link to="/report-form" className="details">
            <img className="btniconview me-2" src="/assets/icons/eye.svg" alt="eye"/> View Report
          </Link>
          <button className="outline-btn">
          <img className="btniconshare me-2" src="/assets/icons/share.svg" alt="share"/>Share Report</button>
          <button className="outline-btn-del">
          <img className="btnicondel me-2" src="/assets/icons/bin.svg" alt="del"/>Delete Reports</button>
        </div>
      </div>

      <div className="reportcard mb-3">
        <div className="reportstext">
          <h2>ECG Report</h2>
          <p>of Asad Wali</p>
        </div>
        <div className="infodetails">
          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/sethoscope.svg"
              alt="sethoscope"
            />
            <p className="report-text">Dr. Iqra</p>
          </div>

          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/time.svg"
              alt="time"
            />
            <p className="report-text">13 Aug, 2023 - 12:00 PM</p>
          </div>

          <div className="d-flex align-items-center">
            <img
              className="iconsreport me-3"
              src="/assets/icons/files.svg"
              alt="files"
            />
            <p className="report-text">ECGreport.jpg</p>
          </div>
        </div>
        <div className="bottom">
          <Link to="/report-form" className="details">
            <img className="btniconview me-2" src="/assets/icons/eye.svg" alt="eye"/> View Report
          </Link>
          <button className="outline-btn">
          <img className="btniconshare me-2" src="/assets/icons/share.svg" alt="share"/>Share Report</button>
          <button className="outline-btn-del">
          <img className="btnicondel me-2" src="/assets/icons/bin.svg" alt="del"/>Delete Reports</button>
        </div>
      </div>

      <Link class="fixed-icon-button" to="/report-form">
        <img className="plusicon" src="/assets/icons/plus.svg" alt="plus" />
      </Link>
    </>
  );
};

export default Reports;
