import React from "react";
import DontProvide from "./dont-provide/dont-provide.component";
import GetStarted from "./get-started/get-started.component";
import WeProvide from "./our-services/our-services.component";
import ProvideBanner from "./provide-banner/provide-banner.component";

const WhatWeProvide = () => {
    return (
        <>
            <ProvideBanner />
            <WeProvide />
            <DontProvide />
            <GetStarted />
        </>
    )
}

export default WhatWeProvide;