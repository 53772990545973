import React from "react";
import './blog-banner.style.css';

const BlogBanner = () => {
    return (
        <section id="banner-hero">
            <div className="banner-container" data-aos="fade-up" data-aos-delay="150">
                <h1>Healthy advice.</h1>
                <h1><span>Healthier living.</span></h1>
                {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
            </div>
        </section>
    )
}

export default BlogBanner;