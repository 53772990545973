import React from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "./calender.style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Events = [
  {
    start: new Date(),
    end: new Date(),
    title: "Appointment with Dr. Smith",
  },
  {
    start: new Date(new Date().setDate(new Date().getDate() + 2)), // +2 days from today
    end: new Date(new Date().setDate(new Date().getDate() + 2)),
    title: "Meeting with Client",
  },
  // Add more events
];

const DotEvent = () => {
  return <span className="dot-event"></span>;
};

const DashboardCalendar = () => {
  const [selectedAppointment, setSelectedAppointment] = React.useState(null);

  const handleEventSelect = (event) => {
    setSelectedAppointment(event);
  };

  const handleSlotSelect = (slotInfo) => {
    const selectedDate = slotInfo.start;
    const appointmentOnSelectedDate = Events.find(event => 
      format(event.start, "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd")
    );

    if (appointmentOnSelectedDate) {
      setSelectedAppointment(appointmentOnSelectedDate);
    } else {
      setSelectedAppointment({ title: "No appointment on this date." });
    }
  };

  const preventDrillDown = (event) => {
    // This function prevents default drill down behavior
    return false;
  };

  return (
    <>
      <div className="card simple-card">
        <div className="card-body">
          <Calendar
            components={{
              event: DotEvent,
            }}
            selectable
            className="custom-calander"
            defaultView={Views.MONTH}
            localizer={localizer}
            events={Events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleEventSelect}
            onSelectSlot={handleSlotSelect}
            onDrillDown={preventDrillDown}
            style={{ height: 290 }}
          />
          {selectedAppointment && (
            <div className="appointment-details">
              <h3>Appointment Details:</h3>
              <p>Title: {selectedAppointment.title}</p>
              {selectedAppointment.start && <p>Date: {format(selectedAppointment.start, "dd/MM/yyyy")}</p>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};




export default DashboardCalendar;
