import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Reports from "./reports/reports.component";
import Vitals from "./vitals/vitals.component";

const MedicalRecords = () => {
    return (
        <Tabs>
            <div className="list-container">
                <TabList className="px-3">
                    <Tab>Reports</Tab>
                    <Tab>Vitals</Tab>
                </TabList>

                <TabPanel>
                    <Reports />
                </TabPanel>
                <TabPanel>
                    <Vitals />
                </TabPanel>
               
            </div>
        </Tabs>
    )
}

export default MedicalRecords;