import React, { useCallback, useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { Box, styled } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { passwordChanged } from "../../../shared/redux/actions/users.actions";
import { loginFalse } from "../../../shared/redux/reducers/users.reducer";
import Toaster from "../../../shared/toaster";
import Loader from "../../../shared/loader";
import { useChangePasswordMutation } from "../../../shared/api/rtk";
import { updateUser } from "../../../shared/redux/reducers/users.reducer";
import { toast } from "react-toastify";
import SuccessModal from "./success-modal/success-modal.component";

const initialValues = {
  password: "",
  cpassword: "",
  otp: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character."
    ),
  cpassword: Yup.string()
    .required("Confirm Password is required!")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  otp: Yup.string().required("OTP is required!"),
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0)",
}));

const Section = styled(JustifyBox)(() => ({
  background: "#011F4B",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 1013,
    borderRadius: 20,
    margin: "1rem",
  },
}));

const ChangePassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showCPassword, setShowCPassword] = useState(false);
  const handleCPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };
  const [changePassword, { isLoading, isSuccess, isError, error }] =
    useChangePasswordMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessModalOpen(true);
      const modalSuccessTimer = setTimeout(() => {
        setIsSuccessModalOpen(false);
        navigate("/login");
      }, 5000);
      return () => clearTimeout(modalSuccessTimer);
    }
  }, [navigate, isSuccess]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const formData = new FormData();
        formData.append("Email", user.email);
        formData.append("OTP", values.otp);
        formData.append("NewPassword", values.password);
        await changePassword(formData).unwrap();
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, changePassword, user.email]
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.message || "Something Went Wrong!");
    }
  }, [isError, error]);

  return (
    <>
      <Section>
        <Card className="card">
          <Grid container>
            <Grid
              item
              lg={5}
              md={5}
              sm={5}
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <JustifyBox>
                <div className="auth-logo-container">
                  <img
                    src="./assets/logo.png"
                    alt="logo"
                    className="auth-logo"
                  />
                </div>
              </JustifyBox>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <ContentBox>
                <h1>Change Your Password</h1>
                <p>Please fill the fields below</p>
                {/* form-start */}
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      <Form className="">
                        <Field
                          className="form-input placeholder"
                          value={user.email}
                          readOnly
                        />

                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="otp"
                          id="otp"
                          placeholder="Enter 6-digits code"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="otp" />
                        </div>

                        <div className="auth-passwordfield">
                          <Field
                            className="form-input placeholder"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                          />
                          <img
                            className="eyeicon"
                            src={
                              showPassword
                                ? "/assets/icons/eyeopen.svg"
                                : "/assets/icons/eyeclose.svg"
                            }
                            alt="Toggle password visibility"
                            onClick={handlePasswordVisibility}
                          />
                          <div className="text-danger text-start">
                            <ErrorMessage name="password" />
                          </div>
                        </div>

                        <div className="auth-passwordfield">
                          <Field
                            className="form-input placeholder"
                            id="cpassword"
                            type={showCPassword ? "text" : "password"}
                            name="cpassword"
                            placeholder="Re-Type Password"
                          />
                          <img
                            className="eyeicon"
                            src={
                              showCPassword
                                ? "/assets/icons/eyeopen.svg"
                                : "/assets/icons/eyeclose.svg"
                            }
                            alt="Toggle password visibility"
                            onClick={handleCPasswordVisibility}
                          />
                          <div className="text-danger text-start">
                            <ErrorMessage name="cpassword" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="content col-sm">
                            <p>
                              Return to
                              <Link to="/login">
                                <span className="forgot_span ms-1">
                                  Sign In
                                </span>
                              </Link>
                            </p>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <button className="btn form_btn" type="submit">
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>

                {/* form-end */}
              </ContentBox>
            </Grid>
          </Grid>
        </Card>
        <SuccessModal isOpen={isSuccessModalOpen} />
        <Toaster />
        <Loader loading={isLoading} />
      </Section>
    </>
  );
};

export default ChangePassword;
