import React from "react";
import "./why-becurable.style.css";
import whyus from "../../shared/whyus.json";

const WhyBecurable = () => {
  return (
    <section id="why-us">
      <div className="container text-center justify-content-center align-items-center">
        <h1 className="pt-5">Why Be Curable ?</h1>
        <div className="row pt-5 pb-5">
          <div
            className="col-lg-6 order-2 order-lg-2 img"
            style={{ backgroundImage: `url(${"/assets/webImages/whyus.png"})` }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
          <div className="col-lg-6 order-1 order-lg-1 d-flex flex-column justify-content-center align-items-center d-lg-block">
            {whyus.map((data) => (
              <div className="content my-4 mb-md-5 mt-md-3">
                <div
                  key={data.id}
                  className="d-flex flex-row align-items-center"
                >
                  <p className="steps-count">{data.count}</p>
                  <div className="text-container">
                    <p>
                      <strong>{data.title} </strong>
                      <br /> {data.subtitle}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhyBecurable;
