import { Link } from "@mui/material";
import React from "react";
import "./profile-detail.style.css";

const ProfileDetails = () => {
  return (
    <section>
      <div className="container profileSection py-5 px-5 rounded-3">
        {/* Info Part */}
        <div className="row">
          <div className="col-lg-5 col-md-5 text-center text-md-start">
            <img
              className="img-fluid profileimg rounded-3"
              src="/assets/AsadWali.jpg"
              alt=""
            />
          </div>
          <div className="col-lg-7 col-md-7 dr-info">
            <h5>Dr. Asad Wali</h5>
            <p className="tight">Neurologist</p>
            <p className="tight">MBBS, FCPS, DIP, MRCS</p>
            <p className="tight">Languaages Speaks: Urdu, English, Hindi</p>
            <p className="tight">5+ Years Of Expereince</p>
            <p className="tight">Lahore, Pakistan</p>
            <p className="tight fw-bolder">Trusted And Verified By Us 
            <img className="verified-icon" src="/assets/icons/verified.png" alt="verified"/>
            </p>
          </div>
        </div>
        {/* about */}
        <div className="about mt-3">
          <h2>About Dr. Asad Wali</h2>
          <p>
            I have over 15 years of experience as a Registered Dietitian working
            with people like you to reach your health goals.I have over 15 years
            of experience as a Registered Dietitian working with people like you
            to reach your health goals.
          </p>
        </div>
        {/* Specializations */}
        <div className="table-responsive mt-3">
          <h2>Education</h2>
          <table className="profile-table">
            <tr>
              <th>Degree Tilte</th>
              <th>Provider</th>
              <th>Date Completed</th>
            </tr>
            <tr>
              <td>MBBS</td>
              <td>Fatima Memorial Hospital</td>
              <td>July 12, 2020</td>
            </tr>
            <tr>
              <td>FCPS</td>
              <td>Doctor's Hospital</td>
              <td>August 10, 2023</td>
            </tr>
          </table>
        </div>
        {/* Work Experience */}
        <div className="table-responsive mt-3">
          <h2>Work Experience</h2>
          <table className="profile-table">
            <tr>
              <th>Job Tilte</th>
              <th>Provider</th>
              <th>Duration</th>
            </tr>
            <tr>
              <td>Consultant</td>
              <td>Fatima Memorial Hospital</td>
              <td>2020 - 2021</td>
            </tr>
            <tr>
              <td>Surgeon</td>
              <td>Doctor's Hospital</td>
              <td>2021 - 2023</td>
            </tr>
          </table>
        </div>
        {/* Diesease Treated */}
        <div className="mt-3">
          <h2>Diesease Treated By Dr. Asad Wali</h2>
          <p className="badge">Typhoid Fever</p>
          <p className="badge">Dengue Fever</p>
          <p className="badge">Flu</p>
          <p className="badge">Chicken Pox</p>
          <p className="badge">Malaria</p>
          <p className="badge">Cold</p>
          <p className="badge">Asthma</p>
          <p className="badge">Fever</p>
          <p className="badge">Sore Throat</p>
          <p className="badge">Blood Pressure</p>
        </div>
        {/* Instructions for patients */}
        <div className="mt-3 about">
          <h2>Special Instructions For Patients</h2>
          <p>
            Due to the widespread use of elements across third-party widgets
            like calendars and date pickers, Bootstrap’s tables are opt-in. Add
            the base class .table to any , then extend with our optional
            modifier classes or custom styles. All table styles are not
            inherited in Bootstrap, meaning any nested tables can be styled
            independent from the parent. Using the most basic table markup,
            here’s how .table-based tables look in Bootstrap.
          </p>
        </div>
        {/* rating and reviews */}
        <div className="mt-">
          <h2>Rating & Reviews</h2>
          <hr />
          <p className="five">5</p>
          <div className="d-inline-flex">
            <p>⭐⭐⭐⭐⭐ </p>
            <p>132 reviews</p>
          </div>
        </div>
        {/* rating with profile */}

        <div>
          <hr />
          <div className="d-inline-flex">
            <img className="reviewImg" src="/assets/AsadWali.jpg" alt="" />
            <div>
              <div className="d-flex justify-content-between">
                <p>Asad Wali</p>
                <p>Aug 12, 2023</p>
              </div>
              <p>
                He is very expereinced doctor. I recommended him strongly. He
                communicate very well and give very good prescriptions.
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <div className="d-inline-flex">
            <img className="reviewImg" src="/assets/AsadWali.jpg" alt="" />
            <div>
              <div className="d-flex justify-content-between">
                <p>Asad Wali</p>
                <p>Aug 12, 2023</p>
              </div>
              <p>
                He is very expereinced doctor. I recommended him strongly. He
                communicate very well and give very good prescriptions.
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <div className="d-inline-flex">
            <img className="reviewImg" src="/assets/AsadWali.jpg" alt="" />
            <div>
              <div className="d-flex justify-content-between">
                <p>Asad Wali</p>
                <p>Aug 12, 2023</p>
              </div>
              <p>
                He is very expereinced doctor. I recommended him strongly. He
                communicate very well and give very good prescriptions.
              </p>
            </div>
          </div>
          <hr />
        </div>
        <Link to="#" className="linkblog-button">
          View More Reviews
        </Link>
      </div>
    </section>
  );
};

export default ProfileDetails;
