import React from "react";
import './next-generation.style.css';
import info from '../../shared/nextgeneration.json'

const NextGeneration = () => {
    return (
        <section className="next-generation">
            <div className="container pt-5 pb-5 ">
                <div className="content text-center justify-content-center align-items-center">
                    <h1>Be a Part of our healthcare’s next generation</h1>
                    <h6>Thousands of providers are changing healthcare by incorporating telehealth into their practice.</h6>
                </div>
                <div className="row mt-5 g-4 text-center justify-content-center align-items-center">
                    {info.map((data) => {
                        return (
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="card">
                                    <img src={data.image} alt="time"/>
                                    <div className="card-body">
                                        <h5 className="card-title">{data.heading}</h5>
                                        <p className="card-text">{data.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default NextGeneration;