import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import specilaization from "../../../components/shared/specializations.json";
import "./dashboard-specializations.style.css";
import { Link } from "react-router-dom";

const DashboardSpecializations = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="specializations-cards">
        <div className="card-body">
          <div className="justify-content-between mb-3">
            <div className="align-self-center">
              <h4 className="card-title-primary text-center">
                Consult top doctor across all specialities
              </h4>
              <div className="row mt-3">
                {isMobile ? (
                  <Slider {...settings}>
                    {specilaization.slice(0, 6).map((data) => (
                      <div
                        key={data.name}
                        className="d-flex justify-content-center"
                      >
                        <div className="card servicesCards">
                          <div className="d-flex align-items-center">
                            <img
                              src={data.image}
                              alt="specializations"
                              className="card-image"
                            />
                            <div className="card-text-content">
                              <p className="card-title tight"> {data.name} </p>
                              <p className="tight">{data.count}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="row">
                    {specilaization.slice(0, 3).map((data) => (
                      <div
                        key={data.name}
                        className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center"
                      >
                        <div className="card servicesCards">
                          <div className="d-flex align-items-center">
                            <img
                              src={data.image}
                              alt="specializations"
                              className="card-image"
                            />
                            <div className="card-text-content">
                              <p className="card-title tight"> {data.name} </p>
                              <p className="tight">{data.count}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <Link to="/specializations" className="btn mt-5 form_btn">View All specializations</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSpecializations;
