import React from "react";
import "./work-experience.style.css";
import { BsPlusCircle } from "react-icons/bs";
import WorkExperienceModal from "./work-experience-modal.somponent";

const WorkExperience = () => {
  return (
    <>
    <div className="card custom-card">
      <div className="card-header d-flex justify-content-between mb-1">
        <h5 className="card-title align-self-center">Work Experience</h5>
        <div className="header-button align-self-center">
          <label>
            <button
              type="button"
              className="btn p-1 ms-1"
              data-bs-toggle="modal"
              data-bs-target="#WorkExperienceModal"
            >
              <BsPlusCircle />
            </button>
          </label>
        </div>
      </div>

      <div className="card-body card-table">
        <div className="table-responsive">
          <table className="">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Clinic/Hospital</th>
                <th scope="col">Duration</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MBBS</td>
                <td>King Edward</td>
                <td>2021 - 2022</td>
                <td>
                  <img
                    className="table-icon me-3"
                    src="/assets/icons/bin.svg"
                    alt="delete"
                  />
                  <img
                    className="table-icon"
                    src="/assets/icons/edit.svg"
                    alt="edit"
                  />
                </td>
              </tr>
              <tr>
                <td>Pre-Medical</td>
                <td>KIPS College</td>
                <td>2022 - 2023</td>
                <td>
                  <img
                    className="table-icon me-3"
                    src="/assets/icons/bin.svg"
                    alt="delete"
                  />
                  <img
                    className="table-icon"
                    src="/assets/icons/edit.svg"
                    alt="edit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <WorkExperienceModal />
    </>
  );
};

export default WorkExperience;
