import React from "react";
import './medical-team.style.css'
import { useNavigate } from 'react-router-dom'

const MedicalTeam = () => {

    const navigate = useNavigate();

    const navigateToSubjects = () => {
        navigate('/all-doctor');
    };

    return (

        <section className="medical-team">
            <div className="container pt-5 pb-5" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="content">
                            <h1>Our Medical Team</h1>
                            <p className="jusity-content pt-4">
                                Our  Doctors are licensed and verified by us.
                                Our doctors includes, MBBS, Surgeon, physicians,
                                pharmacists, Ditetionist, Physchotherapist, and
                                much more  whose mission is to provide great care
                                at a price you can afford. Your Doctor  is here to
                                help you make informed decisions about which
                                treatments are right for you
                            </p>
                            <div className="btn-center d-flex">
                                <button className="btn btn-find-doctor"
                                    onClick={navigateToSubjects}>Find Your Doctor</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 img" style={{ backgroundImage: `url(${"/assets/webImages/team.jpg"})` }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                </div>

            </div>
        </section>
    )
}

export default MedicalTeam;