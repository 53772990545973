import React from "react";
import CTA from "../common-components/call-to-action/cta.component";
import Banner from "./banner/banner.component";
import OngoingCare from "./ongoing-care/ongoing-care.component";
import OurVision from "./our-vision/our-vision.component";
import QualityCare from "./quality-care/quality-care.component";
import RealDoctor from "./real-doctros/real-doctors.component";

const Aboutus = () => {
    return (
        <>
            <Banner />
            <OurVision />
            <QualityCare />
            <OngoingCare />
            <RealDoctor />
            <CTA />
        </>
    )
}

export default Aboutus;