import React from 'react';
import "./blood-pressure.style.css";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper 
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom"

// Blood Pressure Types
function createData(category, systolic, diastolic) {
    return { category, systolic, diastolic };
  }

const rows = [
  createData('Normal', "Less than 120", "Less than 80"),
  createData('Elevated', "120–129", "Less than 80"),
  createData('Hypertension - Stage 1', "130–139", "80 – 89"),
  createData('Hypertension - Stage 2', "140 or higher", "90 or higher"),
  createData('Hypertensive Crisis – Consult your doctor immediately', "Higher than 180", "Higher than 120"),
];


// Blood Pressure Chart
function createChartData(age, women, men) {
    return { age, women, men };
  }

const chartrows = [
    createChartData('18-39 years', "110/68", "119/70"),
    createChartData('40-59 years', "122/74", "124/77"),
    createChartData('60+ years', "139/68", "133/69"),
];


const useStyles = makeStyles({
    tableHeader: {
      fontWeight: 'bold',  // This will make table header bold
    },
    tableRow: {
      borderBottom: 'none'  // This will remove the border from the rows
    }
  });


const BloodPressureDetails = () => {
    const classes = useStyles();
  return (
    <>
    <div>
      <Link className='btn btn-link mb-3' to="/bp-form">Add BP</Link>
    </div>
    <div className='chartheader'>
        <h5>Blood Pressure Types</h5>
    </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Category</TableCell>
            <TableCell align="left">Systolic&nbsp;(mm Hg)</TableCell>
            <TableCell align="left">Diastolic&nbsp;(mm Hg)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.category}
              className={classes.tableRow}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.category}
              </TableCell>
              <TableCell align="left">{row.systolic}</TableCell>
              <TableCell align="left">{row.diastolic}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <div className='mt-5 mb-2 chartheader'>
        <h5>Blood Pressure Charts</h5>
    </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Age</TableCell>
            <TableCell align="left">Women&nbsp;(mm Hg)</TableCell>
            <TableCell align="left">Men&nbsp;(mm Hg)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chartrows.map((row) => (
            <TableRow
              key={row.age}
              className={classes.tableRow}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.age}
              </TableCell>
              <TableCell align="left">{row.women}</TableCell>
              <TableCell align="left">{row.men}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <div className='mt-5 mb-2 chartheader'>
        <h5>Your History</h5>
    </div>
    </>
  );
}

export default BloodPressureDetails;