import React from "react";
import { Link } from "react-router-dom";

const CancelledAppointment = () => {
  return (
    <>
     <div className="tables-design">
        <div className="desktop-table">
          <div className="table-responsive">
            <table className="table">
              <thead className="header-table">
                <tr>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Fee</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Age</th>
                  <th scope="col">Current Medication</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      className="table-img"
                      src="/assets/AsadWali.jpg"
                      alt="Dr. Asad"
                    />
                    Asad Wali
                  </td>
                  <td>500 PKR</td>
                  <td>13 Aug, 12:00 PM</td>
                  <td>26</td>
                  <td>No</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Link to="/appointment-details" className="btn details">
                        View Details
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      className="table-img"
                      src="/assets/AsadWali.jpg"
                      alt="Dr. Asad"
                    />
                    Sidra Behzad Qureshi
                  </td>
                  <td>500 PKR</td>
                  <td>13 Aug, 12:00 PM</td>
                  <td>35</td>
                  <td>No</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Link to="/appointment-details" className="btn details">
                        View Details
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>

        {/* Mobile View Starts */}
        <div className="table-mobile">
          <div className="mb-4">
            <div className="top-section">
              <div className="img">
                <img src="/assets/AsadWali.jpg" alt="user" />
                <p className="title mt-2">Asad Wali</p>
              </div>
              <div className="appointment-content">
                <p className="title">Fee: 500PKR</p>
              </div>
            </div>
            <div className="middle">
              <div className="spec">
                <p className="title tight">Age</p>
                <p className="tight">26</p>
              </div>
              <div className="date">
                <p className="title tight">Date & Time</p>
                <p className="tight">09 Dec, 2023, 4:00 PM</p>
              </div>
              <div className="rating">
                <p className="title tight">Current Medication</p>
                <p className="tight">No</p>
              </div>
            </div>
            <div className="bottom">
              <Link to="/appointment-details" className="details">
                View Details
              </Link>
            </div>
          </div>
        </div>
        {/* Mobile View Ends */}
      </div>
    </>
  );
};

export default CancelledAppointment;
