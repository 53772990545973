import React, { useState } from "react";
import "./bio.style.css";
import { FaPen } from "react-icons/fa";

const Bio = () => {
  const [isEdit, setEdit] = useState(false);

  const onEdit = () => {
    setEdit(!isEdit);
  };
  return (
    <>
      <div className="card custom-card">
        <div className="card-header d-flex justify-content-between mb-1">
          <h5 className="card-title align-self-center">Bio</h5>
          <div className="header-button align-self-center">
            <button type="button" className="btn p-1 ms-1" onClick={onEdit}>
              <FaPen />
            </button>
          </div>
        </div>
        <div className="card-body" style={{ textAlign: "left" }}>
          {isEdit === false ? (
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          ) : (
             <textarea style = {{width : '100%', minHeight : '150px'}}></textarea>
          )}
        </div>
      </div>
    </>
  );
};
export default Bio;
