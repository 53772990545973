import React from "react";
import './contact-banner.style.css';

const ContactBanner = () => {
    return (
        <section id="contact-banner">
            <div className="banner-container" data-aos="fade-up" data-aos-delay="150">
                <h1>Talk To Us</h1>
                <h1><span>We Are Here To Help You</span></h1>
                {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
            </div>
        </section>
    )
}

export default ContactBanner;