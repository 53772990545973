import React from "react";
import './banner.style.css';

const Banner = () => {
    return(

      <section id="about-us">
            <div className="container about-container" data-aos="fade-up" data-aos-delay="150">
            <h1>We believe everyone deserves <br /><span>easy, affordable primary care </span></h1>
            <h2>Our mission is to help all people live longer, healthier and happier lives.</h2>
            </div>
        </section>
    )
}

export default Banner;