import React from "react";
import "./mail-page.css";

const OTPCodeMail = ({ otp }) => {
  // Assuming you're passing the OTP as a prop
  return (
    <div className="center-wrapper">
      <div className="container-codes">
        <div className="image-logo">
          <img src="/assets/becurable.png" alt="logo" />
        </div>
        <h1>Your Verification Code</h1>
        <div className="otp-code">1234</div>
        <div className="instructions">
          Please enter the above code to verify your email address. The code is
          valid for 30 minutes.
        </div>

        <div className="footer">
          If you didn't request this code, please ignore this email or contact
          support.
        </div>

        <div className="socialmedia-images mt-4">
                <h4>Please follow us:</h4>
            <img className="me-5" src="/assets/facebook.png" alt="fb"/>
            <img className="me-5" src="/assets/instagram.png" alt="insta"/>
            <img src="/assets/youtube.png" alt="yt"/>
        </div>
      </div>
    </div>
  );
};

export default OTPCodeMail;
