import React from "react"
import './works-banner.style.css';

const WorkBanner = () => {
    return (
        <section id="work-banner">
            <div className="container work-container" data-aos="fade-up" data-aos-delay="150">
                <h1>It's Simple</h1>
                <h2>1-2-3 voila. Speaking with a qualified, reliable
                    doctor has never been easier.</h2>
            </div>
        </section>
    )
}

export default WorkBanner;