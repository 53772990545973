import React from "react";
import "./step3.style.css";

const Step3 = () => {
  return (
    <section id="step-3">
      <div className="container">
        <div className="row pt-5 pb-5">
          <div
            className="col-lg-6 order-1 order-lg-2 img"
            style={{
              backgroundImage: `url(${"/assets/webImages/Appointment.png"})`,
            }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="content">
              <div className="number-center">
                <p className="counting">3</p>
              </div>
              <h2>Book Appointment</h2>
              <p>
                Now, you can choose "Date & Time" according to your choice of
                your selected doctor and also choose the appointment type "Video
                Consulation" or "In-clnic".
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step3;
