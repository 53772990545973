import React, { useState } from "react";
import { Link } from "react-router-dom";
import './blog-cards.style.css';
import blogs from '../../shared/allblogs.json'

const BlogsCards = () => {

    const [limit, setLimit] = useState(6);

    return (
        <section id="blogs">
            <div className="container mt-5 mb-5">
                <div className="content text-center">
                    <h1>Latest News, Updates and Blogs</h1>
                </div>
                <div className="row g-4 justify-content-center align-items-center mt-5">
                    {blogs.slice(0, limit).map((data) => {
                        return (
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="card">
                                    <img src={data.image} className="card-img" alt="blogs" />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.title}</h5>
                                        <p className="card-text">{data.description}</p>
                                        <Link to="/blog-details" className="linkblog-button">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="row text-center mt-5">
                    <div className="col-12">
                        <button className="btn btn-find-doctor"
                            onClick={() => setLimit(limit + 6)}
                        >
                            Load More
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogsCards;