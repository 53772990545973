import React from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";

const PrescriptionDetails = () => {
  return (
    <>
      <div className="grey-container">
        <div className="p-3 d-flex justify-content-between">
          <h2>Prescription</h2>
          <div className="header-button align-self-center">
            <button type="button" className="btn">
              <FaCloudDownloadAlt />
            </button>
          </div>
        </div>
        <h2 className="text-center">Be Curable Medical Complex</h2>
        <div className="container row mt-3">
          <div className="ms-5 col-12 col-md-5">
            <h5>Doctor Name</h5>
            <p>Dr. Iqra</p>

            <h5>Medicene</h5>
            <ul>
              <li>
                <p className="tight">Panadol</p>
              </li>
              <li>
                <p className="tight">Lcyn</p>
              </li>
              <li>
                <p className="tight">Pulmanol</p>
              </li>
            </ul>

            <h5>Lab Tests</h5>
            <p>CBC</p>

            <h5>Date</h5>
            <p>12 Nov, 2022</p>
          </div>

          <div className="ms-5 col-12 col-md-5">
            <h5>Patient Name</h5>
            <p>Asad</p>

            <h5>Timing</h5>
            <p>Subha - Dophar - Shaam</p>

            <h5>Instructions</h5>
            <p>Please take this Medicene 5 Days</p>
          </div>
          {/* <hr /> */}
        </div>
      </div>
    </>
  );
};

export default PrescriptionDetails;
