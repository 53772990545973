import React from "react";
import "./reports.style.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ReportForm = () => {
  const initialValues = {
    reportTitle: "",
    date: "",
    time: "",
    drName: "",
    patientName: "",
    notes: "",
  };
  const onSubmit = () => {};
  const validationSchema = Yup.object({
    // firstname: Yup.string().required("firstname required!"),
    // email: Yup.string().email("Invalid email format!").required("Email is required!"),
    // phonenumber: Yup.string().required("Mobile number is required!"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="mx-5">
          {/* File Uploading Start */}
          <h5 className="text-black">Add Report Files</h5>
                        <div class="file-upload mb-2">
                        <div class="image-upload-wrap">
                          <input
                            class="file-upload-input"
                            type="file"
                            onchange="readURL(this);"
                            accept="image/*"
                          />
                          <div class="drag-text">
                            <h3>Drag and drop a file or select add Image</h3>
                          </div>
                        </div>
                        <div class="file-upload-content">
                          <img
                            class="file-upload-image"
                            src="#"
                            alt="yourfiles"
                          />
                          <div class="image-title-wrap">
                            <button
                              type="button"
                              onclick="removeUpload()"
                              class="remove-image"
                            >
                              Remove{" "}
                              <span class="image-title">Uploaded Image</span>
                            </button>
                          </div>
                        </div>
                      </div>
                        {/* File Uploading End */}
          <div className="row">
            {/* First Name Column */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5 className="text-black">Add Report Title</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="reportTitle"
                id="reportTitle"
                placeholder="Such as blood report, ultrasound report"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="reportTitle" />
              </div>
            </div>
          </div>
          <div className="row">
            {/* DOB Column */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="text-black">Date</h5>
              <Field
                className="form-input placeholder"
                type="date"
                name="date"
                id="date"
                placeholder="Date Of Report"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="date" />
              </div>
            </div>

            {/* Gender Column */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="text-black">Time</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="time"
                id="time"
                placeholder="Time"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="time" />
              </div>
            </div>
          </div>
          <div className="row">
            {/* Age Column */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="text-black">Doctor Name</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="drName"
                id="drName"
                placeholder="Dr. Name"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="drName" />
              </div>
            </div>

            {/* Languages Column */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h5 className="text-black">Patient Name</h5>
              <Field
                className="form-input placeholder"
                type="text"
                name="patientName"
                id="patientName"
                placeholder="Patient Name"
              />
              <div className="text-danger text-start">
                <ErrorMessage name="patientName" />
              </div>
            </div>
          </div>
          <div>
            <h5 className="text-black">Notes</h5>
            <Field
              as="textarea"
              name="notes"
              className="form-input placeholder textarea"
              id="notes"
              minHeight="100px"
              placeholder="Notes"
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ReportForm;
