import React from "react";
import { Box, styled } from "@mui/system";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 1000;
`;

const ModalBackdrop = styled(Box)(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
  zIndex: 999,
}));

const ModalContent = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
}));

const Loader = ({ loading }) => {
  if (!loading) return null;

  return (
    <>
      <ModalBackdrop />
      <ModalContent>
        <PulseLoader
          css={override}
          size={20}
          color={"#011F4B"}
          loading={loading}
        />
      </ModalContent>
    </>
  );
};

export default Loader;
