import React, { useState } from "react";
import "./profile-form.style.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaPen } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsFillCameraFill } from "react-icons/bs";

const ProfileForm = () => {
  const [isEdit, setEdit] = useState(false);

  const initialValues = {
    salutation: "",
    fristname: "",
    lastname: "",
    dob: "",
    gender: "",
    languagesSpeak: "",
    email: "",
    number: "",
    country: "",
    city: "",
    address: "",
    province: "",
    specializations: "",
    qualification: "",
    license: "",
    experience: "",
    fee: "",
    clincname: "",
    clinicaddress: "",
  };
  const onSubmit = () => {};
  const validationSchema = Yup.object({
    username: Yup.string().required("username required!"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is required!"),
    number: Yup.string().required("Mobile number is required!"),
  });

  const onEdit = () => {
    setEdit(!isEdit);
  };

  return (
    <div className="card custom-card border-top-0">
      <div className="card-header text-end border-bottom-0">
        <div className="header-button align-self-center">
          <button onClick={onEdit} className="btn">
            {isEdit === true ? <AiOutlineCloseCircle /> : <FaPen />}
          </button>
        </div>
      </div>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="">
            <div class="container-img">
              <div class="outer">
                <img
                  className="img-fluid profilePIc"
                  src="/assets/doc.png"
                  alt="avatar"
                />
                {isEdit === false ? (
                  ""
                ) : (
                  <div class="inner">
                    <input
                      class="inputfile"
                      type="file"
                      name="pic"
                      accept="image/*"
                    />
                    <label>
                      <BsFillCameraFill />
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="text-center mt-3">
              <div className="d-flex justify-content-between mb-2">
                <div>
                  <p className="tight">⭐</p>
                  <h6 className="form-heading">Rating</h6>
                  <p className="tight">5.0</p>
                </div>
                <div>
                  <p className="tight">⭐</p>
                  <h6 className="form-heading">Fee</h6>
                  <p className="tight">500 PKR</p>
                </div>
                <div>
                  <p className="tight">⭐</p>
                  <h6 className="form-heading">Experience</h6>
                  <p className="tight">2 Years</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {/* Basic Details */}
                {isEdit === false ? (
                  <p>Dr. Asad Wali</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="salutation"
                      id="salutation"
                      placeholder="Dr./Prof./Mr./Ms"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="salutation" />
                    </div>

                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="fristname"
                      id="fristname"
                      placeholder="First Name"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="fristname" />
                    </div>

                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="lastname" />
                    </div>
                  </>
                )}

                {isEdit === false ? (
                  <p>08 March, 1996</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="date"
                      name="dob"
                      id="dob"
                      placeholder="dob"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="dob" />
                    </div>
                  </>
                )}

                {isEdit === false ? (
                  <p>Male</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="gender"
                      id="gender"
                      placeholder="Select Your Gender"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="gender" />
                    </div>
                  </>
                )}

                {isEdit === false ? (
                  <p>English, Urdu, Punjabi, Hindi</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="languagesSpeak"
                      id="languagesSpeak"
                      placeholder="Languages Speaks"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="languagesSpeak" />
                    </div>
                  </>
                )}
                {/* Basic Details Ends */}

                {/* Contact Details Starts */}
                {isEdit === false ? (
                  <p>asadhamid27@gmail.com</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="email" />
                    </div>
                  </>
                )}

                {isEdit === false ? (
                  <p>+92 306 4077 401</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="number"
                      id="number"
                      placeholder="Mobile Number"
                    />
                    <div className="text-danger text-start">
                      <ErrorMessage name="number" />
                    </div>
                  </>
                )}

                {isEdit === false ? (
                  <p>Pakistan</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="country"
                      id="country"
                      placeholder="Country"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>Lahore</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>house no. 22 Block E-2, Johar Town</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Address"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>Punjab</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="province"
                      id="province"
                      placeholder="Province / State"
                    />
                  </>
                )}

                {/* Contact Details Ends */}

                {/* Clinincal Details Starts */}

                {isEdit === false ? (
                  <p>Cardiologist</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="text"
                      id="specializations"
                      placeholder="Specializations"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>FPMC, FCPS</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="qualification"
                      id="qualification"
                      placeholder="Qualification"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>P-123456</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="license"
                      id="license"
                      placeholder="Medical License"
                    />
                  </>
                )}

                {isEdit === false ? (
                    ""
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="experience"
                      id="experience"
                      placeholder="Experience in Year"
                    />
                  </>
                )}

                {isEdit === false ? (
                  ""
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="fee"
                      id="Fee"
                      placeholder="Fee"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>Asad Medical Complex</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="text"
                      id="clinicname"
                      placeholder="Clinic Name"
                    />
                  </>
                )}

                {isEdit === false ? (
                  <p>22 E-2 Johar Town</p>
                ) : (
                  <>
                    <Field
                      className="form-input placeholder"
                      type="text"
                      name="clinicaddress"
                      id="clinicaddress"
                      placeholder="Clinic Address"
                    />
                  </>
                )}

                {isEdit === false ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <button className="btn form_btn">Update</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ProfileForm;
