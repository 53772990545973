import React from "react";
import './join-us.style.css';

const JoinUs = () => {
    return (
        <section className="join-us">
            <div className="container pt-5 pb-5 ">
                <div className="content-join-us text-center justify-content-center align-items-center">
                    <h1>Join the Telehealth Movement</h1>
                    <p>Thousands of providers are changing healthcare by incorporating telehealth into their practice.</p>
                </div>
            </div>
        </section>
    )
}

export default JoinUs;