import React from "react";
import "./all-notifications.style.css";

const AllNotifications = () => {
  return (
    <div className="all-notifications">
      <div className="list-card">
        <div className="list-card-content d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              className="transactionimage me-3"
              src="/assets/AsadWali.jpg"
              alt="tasks"
            />
            <div>
              <h5>
                Your Appointment with Dr. Iqra has been booked today at
                5:00 PM
              </h5>
              <small>30 min ago</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;
