import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './info-chart.style.css';

const ChartInfo = () => {
  const options = {
    chart: {
      type: "column",
      plotBorderWidth: 2,
      plotBorderRadius: 50,
    },
    plotOptions: {
      series: {
        pointWidth: 8,
        borderRadius: 4,
      },
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      floating: true,
      align: "high",
      x: 380,
      verticalAlign: "top",
      y: 5,
    },
    title: "something is this",
    xAxis: {
      labels: {
        style: {
          fontSize: "10px",
          fontFamily: "Verdana, sans-serif",
        },
      },
      categories: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    yAxis: [
      {
        title: "something is this",
      },
    ],

    series: [
      {
        name: "cancelled",
        data: [5, 1, 2, 10, 0, 0, 6, 5],
      },
      {
        name: "Completed",
        data: [15, 8, 28, 21, 40, 19, 45, 3],
      },
    ],
    annotations: [
      {
        shapes: [
        ],
      },
    ],
    colors: ["#FF0000", "#3ABD6F"],
  };
  return (
    <>
            <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default ChartInfo;
