import React from "react";
import { Link } from "react-router-dom";
import "./patient-sidebar.style.css";
import { MdDashboard, MdEditNote } from "react-icons/md";
import { FaUserAlt, FaCalendarAlt, FaListAlt, FaUsers } from "react-icons/fa";

const PatientSidebar = () => {

  //const location = useLocation();
  const isActive = (path) => window.location.pathname === path;

  return (
    <div className="sidebar">
      <div className="mb-4">
      <Link to="/">
        <img className="iconlogoimage" src="/assets/logo2.png" alt="logo" />
        <img className="iconlogoimage" src="/assets/iconlogo2.png" alt="logo" />
        </Link>
      </div>
      <nav className="d-flex flex-column gap-2">
        <Link className={`sidebar-link ${isActive('/dashboard') ? 'active' : ''}`} to="/dashboard">
          <MdDashboard />
          <span className="sidebar-text d-none d-lg-inline-block">
            Dashboard
          </span>
        </Link>

        <Link className={`sidebar-link ${isActive('/profile') ? 'active' : ''}`} to="/profile">
          <FaUserAlt />
          <span className="sidebar-text d-none d-lg-inline-block">Profile</span>
        </Link>

        <Link className={`sidebar-link ${isActive('/appointments') ? 'active' : ''}`} to="/appointments">
          <FaListAlt />
          <span className="sidebar-text d-none d-lg-inline-block">
            Appointment
          </span>
        </Link>

        <Link className={`sidebar-link ${isActive('/my-doctors') ? 'active' : ''}`} to="/my-doctors">
          <FaUsers />
          <span className="sidebar-text d-none d-lg-inline-block">
            My Doctors
          </span>
        </Link>

        <Link className="sidebar-link" to="/all-doctor">
          <FaUsers />
          <span className="sidebar-text d-none d-lg-inline-block">
            Search Doctors
          </span>
        </Link>

        <Link className="sidebar-link" to="/specializations">
          <MdEditNote />
          <span className="sidebar-text d-none d-lg-inline-block">
            Specializations
          </span>
        </Link>

        <Link className={`sidebar-link ${isActive('/calender') ? 'active' : ''}`} to="/calender">
          <FaCalendarAlt />
          <span className="sidebar-text d-none d-lg-inline-block">
            Calender
          </span>
        </Link>

        <Link className={`sidebar-link ${isActive('/prescriptions') ? 'active' : ''}`} to="/prescriptions">
          <MdEditNote />
          <span className="sidebar-text d-none d-lg-inline-block">
            My Prescriptions
          </span>
        </Link>

        <Link className={`sidebar-link ${isActive('/medical-records') ? 'active' : ''}`} to="/medical-records">
          <MdEditNote />
          <span className="sidebar-text d-none d-lg-inline-block">
            Medical Records
          </span>
        </Link>

        <Link className={`sidebar-link ${isActive('/my-wallet') ? 'active' : ''}`} to="/my-wallet">
          <MdEditNote />
          <span className="sidebar-text d-none d-lg-inline-block">
            My Wallet
          </span>
        </Link>
      </nav>
    </div>
  );
};

export default PatientSidebar;
