import React from "react";
import GreetingCard from './greetings-card/greetings-card.component'
import DashboardCalendar from './calender/calender.component'
import UpcomingAppointment from "./upcoming-appointment/upcoming-appointment.component";
import DashboardSpecializations from "./dashboard-specializations/dashboard-specializations.component";
import DashboardDiseases from "./dashboard-diasease/dashboard-diasease.component";

const PatientDashboard = () => {
  return (
    <>
    <GreetingCard />
    <div className="row">
      <div className="col-lg-8">
        <UpcomingAppointment />
        <DashboardSpecializations />
        <DashboardDiseases />
      </div>
      <div className="col-lg-4">
        <DashboardCalendar />
      </div>
    </div>
    </>
  );
};

export default PatientDashboard;
