import React from "react";
import DashboardCalendar from "./calender/calender.component";
import GreetingCard from "./greetings-card/greetings-card.component";
import ChartInfo from "./info-chart/info-chart.component";
import ProfileStatus from "./profile-status/profile-status.component";
import ThisMonth from "./this-month/this-month.component";
import ProviderUpcomingAppointment from "./upcoming-appointment/upcoming-appointment.component";

const DashboardComponent = () => {
  return (
    <>
      <GreetingCard />
      <div className="row">
        <div className="col-lg-8">
          <ProviderUpcomingAppointment />
          <ThisMonth />
          <ChartInfo />
        </div>
        <div className="col-lg-4">
          <ProfileStatus />
          <DashboardCalendar />
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;
