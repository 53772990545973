import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div
        className="modal fade"
        id="termsandconditions"
        tabindex="-1"
        aria-labelledby="AppModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AppModalLabel">
                Terms And Conditions
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ol>
                <li>
                <p className="text-justify">
                  I am a health or wellness professional or administrator and
                  will be using Becurable for the management of a clinic,
                  practice, or other health or wellness-related business. I
                  understand that Becurable may revoke unauthorized uses of
                  its platform at any time.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
