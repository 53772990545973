import React from "react";
import "./book-appointment.style.css";
import { Formik, Form, Field } from "formik";
//import DetailsModal from "../booking-details-modal/booking-details-modal.component";

const BookAppointment = () => {
  // const [modalVisible, setModalVisible] = useState(false);

  // const handleFieldClick = () => {
  //   setModalVisible(true);
  // };

  // const handleCloseModal = () => {
  //   setModalVisible(false);
  // };

  return (
    <section className="appointment rounded-5">
      <div className="heading-banner">
        <h6 className="app-head">Appointment Details</h6>
      </div>
      <div className="container">
        <div className="mt-3 d-flex justify-content-between">
          <p>Fee : 500 PKR</p>
          <p>⭐ 5</p>
        </div>
        <div>
          <Formik
            initialValues={{
              select: "",
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              alert(JSON.stringify(values, null, 2));
            }}
          >
            {({ values }) => (
              <Form>
                <div role="group" aria-labelledby="my-radio-group">
                  <label className="radio-button-label">
                    <Field type="radio" name="select" value="Physical" />
                    Medical Complex
                  </label>
                  <label className="radio-button-label">
                    <Field type="radio" name="select" value="Online" />
                    Video Consulation
                  </label>
                </div>
                <h3>Date & Time</h3>
                <button
                  type="button"
                  className="btn-modal-open"
                  data-bs-toggle="modal"
                  data-bs-target="#bookingModal"
                  style={{ border: 'none', padding: '0', width: '100%' }}
                >
                  <Field
                    className="form-input placeholder clickable-input"
                    type="text"
                    name="dateTime"
                    id="dateTime"
                    placeholder="Schedule Appointment"
                  />
                </button>
                <button className="btn form_btn" type="submit">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default BookAppointment;
