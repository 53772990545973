import React, { useState, useEffect } from "react";
import "../hoc.style.css";
import { useNavigate } from "react-router-dom";
import ProviderSidebar from "../../../providers-components/provider-sidebar/provider-sidebar.component";
import ProviderNavbar from "../../../providers-components/provider-navbar/provider-navbar.component";
import ProviderMobileNavbar from "../../../providers-components/provider-mobile-navbar/mobile-navbar.component";
import ProviderMobileMenu from "../../../providers-components/provider-mobile-navbar/mobile-menu.component";

const ProviderLayout = ({ Component }) => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
  }, [navigate]);

  const toggleMenu = () => {
    setMenuVisible(prevMenuVisible => !prevMenuVisible);
  };

  return (
    <>
      <div className="d-flex hoc-container">
        <div className="d-none d-md-flex">
          <ProviderSidebar />
        </div>
        <div className="second-col">
        <ProviderMobileNavbar toggleMenu={toggleMenu} />
          <ProviderNavbar />
          <div className="main-wrapper">
            <Component />
          </div>
        </div>
      </div>
      {menuVisible && <ProviderMobileMenu toggleMenu={toggleMenu} />}
    </>
  );
};

export default ProviderLayout;
