import React from "react";
import './doctor-banner.style.css';

const AllDoctorBanner = () => {
    return (
        <section className="container">
            <div className="doctor-container" data-aos="fade-up" data-aos-delay="150">
                <h1>Not Just a Doctor - <span>Your Doctor</span></h1>
                <p>Healthcare Services in Your Area</p>
            </div>
        </section>
    )
}

export default AllDoctorBanner;