import React from 'react';
import './our-services.style.css';
import Data from '../../../shared/whatweprovide.json'

const WeProvide = () => {
    return (
        <section id="we-provide">
            <div className='container mt-5 mb-5'>
                <div className='provider-content'>
                    <h1>For Specific Need</h1>
                    <p>Our primary care doctors can help you with a broad range of health issues,
                        medications and lab orders by video appointment.</p>
                </div>

                <div className='row d-flex align-items-center justify-content-center g-4'>
                    {Data.map((data) => {
                        return (
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div class="card">
                                    <div class="card-body provider-card">
                                        <h3>{data.title}</h3>
                                        {data.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>

            </div>

        </section>
    )
}

export default WeProvide;