import React from "react";
import Navbar from "../../navbar/navbar.component";
import Footer from "../../footer/footer.component";
import Copyright from "../../copyirghts/copyrights.component";

const WithHeaderLayout = ({ Component }) => {
  return (
    <>
      <Navbar />
      <Component />
      <Footer />
      <Copyright />
    </>
  );
};

export default WithHeaderLayout;
