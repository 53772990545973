import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './blogs.style.css';
import blogs from '../../shared/allblogs.json';
import { useNavigate, Link } from 'react-router-dom'

const Blogs = () => {

    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const navigateToSubjects = () => {
        navigate('/blogs');
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // show 3 slides in the default view (desktop)
        slidesToScroll: 3, // scroll 3 slides at a time
        responsive: [
            {
                breakpoint: 1024, // tablet view
                settings: {
                    slidesToShow: 2, // show 2 slides
                    slidesToScroll: 2, // scroll 2 slides at a time
                },
            },
            {
                breakpoint: 767, // mobile view
                settings: {
                    slidesToShow: 1, // show 1 slide
                    slidesToScroll: 1, // scroll 1 slide at a time
                },
            },
        ],
    };
    
    

    return (
        <section id="blogs">
            <div className="container mt-5 mb-5">
                <div className="content text-center justify-content-center align-items-center">
                    <h1>Latest News, Updates and Blogs</h1>
                </div>
                {isMobile ? (
                    <Slider {...settings}>
                        {blogs.slice(0, 6).map((data) => (
                            <div className="slide-wrapper">
                            <div className="card">
                                <img src={data.image} className="card-img" alt="blogs" />
                                <div className="card-body">
                                    <h5 className="card-title">{data.title}</h5>
                                    <p className="card-text">{data.description}</p>
                                    <Link to="/blog-details" className="linkblog-button">Learn More</Link>
                                </div>
                            </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className="row g-4 align-item-center mt-5">
                        {blogs.slice(0, 3).map((data) => (
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="card">
                                    <img src={data.image} className="card-img" alt="blogs" />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.title}</h5>
                                        <p className="card-text">{data.description}</p>
                                        <Link to="/blog-details" className="linkblog-button">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="text-center mt-5">
                    <button className="btn btn-find-doctor"
                    onClick={navigateToSubjects}>See More</button>
                </div>
            </div>
        </section>
    )
}

export default Blogs;
