import React from "react";
//import Pagination from "../../../shared/pagination/pagination.component";
import { Link } from "react-router-dom";

const UpcomingAppointments = () => {
  return (
    <>
      <div className="tables-design">
        <div className="desktop-table">
          <div className="table-responsive">
            <table className="table">
              <thead className="header-table">
                <tr>
                  <th scope="col">Dr. Name</th>
                  <th scope="col">Fee</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Specialization</th>
                  <th scope="col">Rating</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      className="table-img"
                      src="/assets/AsadWali.jpg"
                      alt="Dr. Asad"
                    />
                    Dr. Asad
                  </td>
                  <td>500 PKR</td>
                  <td>13 Aug, 12:00 PM</td>
                  <td>Cardiologist</td>
                  <td>5.0</td>
                  <td>
                    <div className="d-flex">
                      <Link to="#" className="btn join me-2">
                        Join
                      </Link>{" "}
                      <Link to="#" className="btn cancel me-2">
                        Cancel
                      </Link>{" "}
                      <Link to="/appointment-details" className="btn details">
                        View Details
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      className="table-img"
                      src="/assets/AsadWali.jpg"
                      alt="Dr. Asad"
                    />
                    Dr. Asad
                  </td>
                  <td>500 PKR</td>
                  <td>13 Aug, 12:00 PM</td>
                  <td>Cardiologist</td>
                  <td>5.0</td>
                  <td>
                    <div className="d-flex">
                      <Link to="#" className="btn join me-2">
                        Join
                      </Link>{" "}
                      <Link to="#" className="btn cancel me-2">
                        Cancel
                      </Link>{" "}
                      <Link to="/appointment-details" className="btn details">
                        View Details
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>

        {/* Mobile View Starts */}
        <div className="table-mobile">
          <div className="mb-4">
            <div className="top-section">
              <div className="img">
                <img src="/assets/AsadWali.jpg" alt="user" />
                <p className="title mt-2">Dr. Asad</p>
              </div>
              <div className="appointment-content">
                <p className="title">Fee: 500PKR</p>
              </div>
            </div>
            <div className="middle">
              <div className="spec">
                <p className="title tight">Specialization</p>
                <p className="tight">Family Physician</p>
              </div>
              <div className="date">
                <p className="title tight">Date & Time</p>
                <p className="tight">09 Dec, 2023, 4:00 PM</p>
              </div>
              <div className="rating">
                <p className="title tight">Rating</p>
                <p className="tight">5.0</p>
              </div>
            </div>
            <div className="bottom">
              <Link to="/appointment-details" className="details">
                View Details
              </Link>
              <button className="join">Join</button>
              <button className="cancel">Cancel</button>
            </div>
          </div>
        </div>
        {/* Mobile View Ends */}
      </div>
    </>
  );
};

export default UpcomingAppointments;
