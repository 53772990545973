import React from "react";

const UpcomingAppointment = () => {
  return (
    <div className="card simple-card card-border">
      <div className="card-body">
        <div className="mb-3">
          <div className="align-self-center">
            <h5>Upcoming Appointment</h5>

            <div className="d-flex justify-content-between">
              <div>
                <h6 className="card-title">Dr. Asad Wali</h6>
              </div>
              <div>
                <p>⭐ 5.0 (132 reviews)</p>
              </div>
            </div>
            <div className="d-inline-flex">
              <p>Specializations:</p>
              <p className="badge ms-2 text-dark rounded-3 ps-2 pe-2">MBBS</p>
              <p className="badge ms-2 text-dark rounded-3 ps-2 pe-2">FPFS</p>
            </div>
            <p className="card-subtitle">
              Online | Today at 3:00 pm | 48 Minutes ago
            </p>
          </div>
        </div>
        <p className="card-text">
          I have the confidence and knowledge to fight against the deadliest
          disease.Only the healing art enables one to make a name for himself
          and at the same time give benefit to others
        </p>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <button className="btn btn_join">
          <i class="fas fa-check"></i> Join
        </button>
        <button href="/" className="btn btn-outline ms-3">
          <i class="fas fa-times"></i> Cancel
        </button>
      </div>
    </div>
  );
};

export default UpcomingAppointment;
