import React from 'react';
import NextGeneration from '../../landingPage/next-generation/next-generation.component';
import JoinUs from './join-us/join-us.component';
import ProviderBanner from './provider-banner/provider-banner.component';

const BecomeProvider = () => {
    return (
        <>
            <ProviderBanner />
            <JoinUs />
            <NextGeneration />
        </>
    )
}
export default BecomeProvider;