import React, { useCallback, useState, useEffect } from "react";
import "./complete-profile.style.css";
import { Card, Grid } from "@material-ui/core";
import { Box, styled } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { BsFillCameraFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileCompletion } from "../../../shared/redux/actions/users.actions";
import Toaster from "../../../shared/toaster";
import Loader from "../../../shared/loader";
import { allQualification, allSpecialization } from "../../../shared/api/index";
import SuccessModal from "./success-modal/success-modal.component";
import { loginFalse } from "../../../shared/redux/reducers/users.reducer";
import {
  useCompleteProfileMutation,
  useAllQualificationQuery,
  useAllSpecializationQuery,
} from "../../../shared/api/rtk";
import { updateUser } from "../../../shared/redux/reducers/users.reducer";
import { toast } from "react-toastify";

const initialValues = {
  ProfileImage: "",
  Salutation: "",
  AboutMe: "",
  Dob: "",
  Gender: "",
  Country: "",
  City: "",
  Address: "",
  QualificationUuids: "",
  SpecializationUuids: "",
  MedLicense: "",
};

const validationSchema = Yup.object({
  ProfileImage: Yup.string().required("Required!"),
  Salutation: Yup.string().required("Required!"),
  AboutMe: Yup.string().required("Required!"),
  Dob: Yup.string().required("Required!"),
  Gender: Yup.string().required("Required!"),
  Country: Yup.string().required("Required!"),
  City: Yup.string().required("Required!"),
  Address: Yup.string().required("Required!"),
  QualificationUuids: Yup.string().required("Required!"),
  SpecializationUuids: Yup.string().required("Required!"),
  MedLicense: Yup.string().required("Required!"),
});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const Section = styled(JustifyBox)(() => ({
  background: "#011F4B",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 1000,
    borderRadius: 20,
    margin: "1rem",
  },
}));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0)",
}));

const CompleteProfile = () => {
  let navigate = useNavigate();
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { user } = useSelector((state) => state.users);
  const [selectedCountry, setSelectedCountry] = useState("");
  const API_USERNAME = "Asad_Wali";
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [completeProfile, { isLoading, isSuccess, isError, error }] =
    useCompleteProfileMutation();
  const {
    data: qualificationData,
  } = useAllQualificationQuery();
  const {
    data: specializationData,
  } = useAllSpecializationQuery();

  useEffect(() => {
    // Fetch list of countries
    fetch(`http://api.geonames.org/countryInfoJSON?username=${API_USERNAME}`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.geonames) {
          setCountries(data.geonames);
        }
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch cities for the selected country
      fetch(
        `http://api.geonames.org/searchJSON?country=${selectedCountry}&maxRows=50&username=${API_USERNAME}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data && data.geonames) {
            setCities(data.geonames);
          }
        });
    }
  }, [selectedCountry]);

  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessModalOpen(true);
      const modalSuccessTimer = setTimeout(() => {
        setIsSuccessModalOpen(false);
        navigate("/login");
      }, 5000);
      return () => clearTimeout(modalSuccessTimer);
    }
  }, [isSuccess]);

  const onSubmit = useCallback(
    async (values) => {
      const qualificationUuids = selectedQualification
        ? selectedQualification
            .map((qualification) => qualification.value)
            .join(";")
        : "";

      const specializationUuids = selectedSpecialization
        ? selectedSpecialization
            .map((specialization) => specialization.value)
            .join(";")
        : "";

      const payload = {
        ...values,
        QualificationUuids: qualificationUuids,
        SpecializationUuids: specializationUuids,
        UserUuid: user.userUuid,
      };

      try {
        const formData = new FormData();
        formData.append("UserUuid", payload.UserUuid);
        formData.append("ProfileImage", values.ProfileImage);
        formData.append("Salutation", values.Salutation);
        formData.append("AboutMe", values.AboutMe);
        formData.append("Dob", values.Dob);
        formData.append("Gender", values.Gender);
        formData.append("Country", values.Country);
        formData.append("City", values.City);
        formData.append("Address", values.Address);
        formData.append("QualificationUuids", payload.QualificationUuids);
        formData.append("SpecializationUuids", payload.SpecializationUuids);
        formData.append("MedLicense", values.MedLicense);
        await completeProfile(formData).unwrap();
      } catch (err) {
        console.log(err);
      }
    },
    [
      dispatch,
      completeProfile,
      selectedQualification,
      selectedSpecialization,
      user.userUuid,
    ]
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.message || error?.data || "Something Went Wrong!");
    }
  }, [isError, error]);

  return (
    <Section>
      <Card className="card">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <JustifyBox>
              <h1 className="mt-5">Complete Your Profile</h1>
            </JustifyBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ContentBox>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {(formikProps) => (
                      <Form className="" enctype="multipart/form-data">
                        <div className="row text-center pb-3">
                          <div className="col-lg-12 col-md-12">
                            <div className="container-img">
                              <div className="outer">
                                <img
                                  className="img-fluid profilePIc"
                                  src={imagePreview || "/assets/doc.png"}
                                  alt="avatar"
                                />
                                <div className="inner">
                                  <input
                                    className="inputfile"
                                    type="file"
                                    name="ProfileImage"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={(event) => {
                                      const selectedFile =
                                        event.target.files[0];
                                      formikProps.setFieldValue(
                                        "ProfileImage",
                                        selectedFile
                                      );
                                      setImagePreview(
                                        URL.createObjectURL(selectedFile)
                                      );
                                    }}
                                  />

                                  <label>
                                    <BsFillCameraFill />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Field
                          as="select"
                          name="Salutation"
                          className="form-input placeholder"
                          id="Salutation"
                        >
                          <option value="" selected disabled hidden>
                            Salutation
                          </option>
                          <option value="Dr.">Dr.</option>
                          <option value="Prof.">Prof.</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Miss">Miss</option>
                        </Field>
                        <div className="text-danger text-start">
                          <ErrorMessage name="Salutation" />
                        </div>

                        <Field
                          as="textarea"
                          name="AboutMe"
                          className="form-input placeholder textarea"
                          id="AboutMe"
                          minheight="100px"
                          placeholder="About Me"
                        />

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Field
                              className="form-input placeholder"
                              type="date"
                              name="Dob"
                              id="name"
                              placeholder="Dob"
                            />
                            <div className="text-danger text-start">
                              <ErrorMessage name="Dob" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Field
                              as="select"
                              name="Gender"
                              className="form-input placeholder"
                              id="Gender"
                            >
                              <option value="" selected disabled hidden>
                                Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </Field>
                            <div className="text-danger text-start">
                              <ErrorMessage name="Gender" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Field
                              as="select"
                              name="Country"
                              className="form-input placeholder"
                              id="Country"
                              value={selectedCountry}
                              onChange={(e) => {
                                const selected = e.target.value;
                                setSelectedCountry(selected);
                                formikProps.setFieldValue("Country", selected);
                              }}
                            >
                              <option value="" disabled>
                                Select Country
                              </option>
                              {countries.map((country) => (
                                <option
                                  key={country.countryCode}
                                  value={country.countryCode}
                                >
                                  {country.countryName}
                                </option>
                              ))}
                            </Field>

                            <div className="text-danger text-start">
                              <ErrorMessage name="Country" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Field
                              as="select"
                              name="City"
                              className="form-input placeholder"
                              id="City"
                            >
                              <option value="" disabled>
                                Select City
                              </option>
                              {cities.map((city) => (
                                <option key={city.geonameId} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                            </Field>
                            <div className="text-danger text-start">
                              <ErrorMessage name="City" />
                            </div>
                          </div>
                        </div>

                        <Field
                          className="form-input placeholder"
                          type="text"
                          name="Address"
                          id="Address"
                          placeholder="Address"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="Address" />
                        </div>

                        {/* <Field
                          as="select"
                          name="SpecializationUuids"
                          className="form-input placeholder"
                          id="SpecializationUuids"
                        >
                          {specilaization.map((data) => (
                            <option key={data.id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </Field> */}

                        <Select
                          className="form-input"
                          options={specializationData?.data.map(
                            ({ uuid, name }) => ({
                              value: uuid,
                              label: name,
                            })
                          )}
                          placeholder={`Specializations`}
                          onChange={setSelectedSpecialization}
                          isClearable
                          isMulti
                        />

                        <Select
                          className="form-input"
                          options={qualificationData?.data.map(
                            ({ uuid, name }) => ({
                              value: uuid,
                              label: name,
                            })
                          )}
                          placeholder={`Qualifications`}
                          onChange={setSelectedQualification}
                          isClearable
                          isMulti
                        />

                        <Field
                          className="form-input placeholder"
                          id="MedLicense"
                          type="text"
                          name="MedLicense"
                          placeholder="Meidcal Licsence"
                        />
                        <div className="text-danger text-start">
                          <ErrorMessage name="MedLicense" />
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <p className="signUp_para">
                              <input className="me-1" type="checkbox" />I am
                              health or wellness professional and will be using
                              Be Curable for the health or wellness-related. I
                              added all my information is correctly and
                              understand that Be Curable may revoke unauthorized
                              uses of its platform at any time.
                            </p>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-12 col-md-12">
                            <button className="btn form_btn" type="submit">
                              Submit For Approval
                            </button>
                          </div>
                        </div>
                        {/* File Uploading Start */}
                        {/* <div class="file-upload">
                        <div class="image-upload-wrap">
                          <input
                            class="file-upload-input"
                            type="file"
                            onchange="readURL(this);"
                            accept="image/*"
                          />
                          <div class="drag-text">
                            <h3>Drag and drop a file or select add Image</h3>
                          </div>
                        </div>
                        <div class="file-upload-content">
                          <img
                            class="file-upload-image"
                            src="#"
                            alt="yourfiles"
                          />
                          <div class="image-title-wrap">
                            <button
                              type="button"
                              onclick="removeUpload()"
                              class="remove-image"
                            >
                              Remove{" "}
                              <span class="image-title">Uploaded Image</span>
                            </button>
                          </div>
                        </div>
                      </div> */}
                        {/* File Uploading End */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
      <SuccessModal isOpen={isSuccessModalOpen} />
      <Toaster />
      <Loader loading={isLoading} />
    </Section>
  );
};

export default CompleteProfile;
