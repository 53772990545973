import React from 'react'
import './dont-provide.style.css';

const DontProvide = () => {
    return (
        <div className='dont-provide'>
            <div className='container mt-5 mb-5'>
                <div className='content'>
                    <h1>What we Don't Provide</h1>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                We don't provide Virtual Visit in case of medical emergencies
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Always call 1122 or your local emergency service immediately
                                if you think you have an emergency. If you experience any of the following medical issues
                                or any other critical or life-threatening situations, go immediately to an emergency room:
                                <div className='row mt-3 d-flex text-center justify-content-center align-items-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <ul>
                                            <li>Chest Pain or Pressure</li>
                                            <li>Mental Health or Substance Abuse Urgency</li>
                                            <li>Blood Pressure Issues</li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <ul>
                                            <li>Moderate to Severe Burn</li>
                                            <li>Poisoning</li>
                                            <li>Serious Head, Neck, or Back Injury</li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <ul>
                                            <li>Suicidal Ideation</li>
                                            <li>Significant, Uncontrolled Bleeding</li>
                                            <li>Unconsciousness</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                What we can't Prescirbe
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                There are some medications that telemedicine providers,
                                including HealthTap doctors, rarely if ever prescribe or refill.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DontProvide;