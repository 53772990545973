import React from 'react';
//import ProfileBanner from './profile-banner/profile-banner.component';
import ProfileDetails from './profile-detail/profile-detail.component';
import BookAppointment from './book-appointment/book-appointment.component';
import DetailsModal from './booking-details-modal/booking-details-modal.component';


const DoctorsProfile = () => {
    return (
        <>
            {/* <ProfileBanner /> */}
            <div className="container py-5 mt-5">
                <div className="row g-4">
                    <div className="col-lg-9 col-md-12">
                        <ProfileDetails />
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <BookAppointment />
                        <DetailsModal />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DoctorsProfile;