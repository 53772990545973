import React from "react";
import { Link } from "react-router-dom";
import './real-doctors.style.css';

const RealDoctor = () => {
    return (
        <section id="qualit-care">
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-6  order-2 order-lg-1">
                        <div className="content">
                            <h2>We believe real doctors have the best answers</h2>
                            <p>
                            Becurable has the country's largest network of volunteer doctors across 140+ 
                            specialties to create a database of millions of trustworthy doctor answers. 
                            No more wading through confusing articles on the internet.
                            </p>
                            <div className="d-flex">
                                <Link to="/blogs" className="link-button">Search Doctor’s Blogs</Link>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 order-1 order-lg-2 img" style={{ backgroundImage: `url(${"/assets/webImages/qualitycare.jpg"})` }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                </div>
            </div>
        </section>
    )
}

export default RealDoctor;