import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import {
  useGetUserQuery,
  useUpdateProviderStatusMutation,
} from "../../../shared/api/rtk";
import { useDispatch, useSelector } from "react-redux";
import "./providers.style.css";

const Providers = () => {
  const { data } = useGetUserQuery();
  const { user } = useSelector((state) => state.users);
  const [updateProviderStatus, { isLoading, isError, error }] =
    useUpdateProviderStatusMutation();
  const providersData = data?.providers || [];

  // const decodeBase64Image = (base64Image, imageType = "jpeg") => {
  //   if (!base64Image) return null;
  
  //   try {
  //     // Remove data URI scheme and URL encoding
  //     const cleanedBase64 = base64Image
  //       .replace(/^data:image\/[a-z]+;base64,/, '')
  //       .replace(/%[0-9A-Fa-f]{2}/g, '');
  
  //     // Decode the cleaned base64-encoded image string
  //     const decodedImage = atob(cleanedBase64);
  
  //     return `data:image/${imageType};base64,${decodedImage}`;
  //   } catch (error) {
  //     console.error("Error decoding image:", error);
  //     return null;
  //   }
  // };
  
  const decodeBase64Image = (base64Image, imageType = "jpeg") => {
    if (!base64Image) return null;
  
    try {
      // Decode the base64-encoded image string
      const decodedImage = atob(base64Image);
  
      // Create a data URI with the specified image type
      return `data:image/${imageType};base64,${btoa(decodedImage)}`;
    } catch (error) {
      console.error("Error decoding image:", error);
      return null;
    }
  };
  
  

  const onSubmit = useCallback(
    async (values) => {
      try {
        const formData = new FormData();
        formData.append("Email", values.email);
        formData.append("Status", values.status);
        formData.append("ReasonForRejection", values.code);
        await updateProviderStatus(formData).unwrap();
      } catch (err) {
        console.log(err);
      }
    },
    [updateProviderStatus]
  );

  return (
    <>
      <div className="tables-design">
        {/* Desktop View */}
        <div className="desktop-table">
          <div className="table-responsive">
            <table className="table">
              <thead className="header-table">
                <tr>
                  <th scope="col">Dr. Name</th>
                  <th scope="col">Medical License</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {providersData.map((provider) => (
                  <tr key={provider.id}>
                    <td>
                      <img
                        className="table-img"
                        src={provider.profileImage}
                        // src={decodeBase64Image(provider.profileImage, "jpeg")}
                        alt="ProviderPic"
                      />
                      {provider.fullName}
                    </td>
                    <td>{provider.medLicense}</td>
                    <td>{provider.phoneNo}</td>
                    <td>{provider.email}</td>
                    <td
                      className={`status-cell ${provider.status.toLowerCase()}`}
                    >
                      {provider.status}
                    </td>
                    <td>
                      <div className="d-flex">
                        {provider.status === "Pending" && (
                          <>
                            <Link
                              to="#"
                              className="btn join me-2"
                              onClick={() =>
                                onSubmit({
                                  email: provider.email,
                                  status: "Approved",
                                })
                              }
                            >
                              Approve
                            </Link>{" "}
                            <Link
                              to="#"
                              className="btn cancel me-2"
                              onClick={() =>
                                onSubmit({
                                  email: provider.email,
                                  status: "Rejected",
                                })
                              }
                            >
                              Reject
                            </Link>{" "}
                          </>
                        )}
                        {provider.status === "Rejected" && (
                          <>
                            <Link
                              to="#"
                              className="btn join me-2"
                              onClick={() =>
                                onSubmit({
                                  email: provider.email,
                                  status: "Approved",
                                })
                              }
                            >
                              Approve
                            </Link>{" "}
                            <Link to="#" className="btn cancel me-2 disabled">
                              Reject
                            </Link>{" "}
                          </>
                        )}
                        {provider.status === "Approved" && (
                          <>
                            <Link to="#" className="btn join me-2 disabled">
                              Approve
                            </Link>{" "}
                            <Link
                              to="#"
                              className="btn cancel me-2"
                              onClick={() =>
                                onSubmit({
                                  email: provider.email,
                                  status: "Rejected",
                                })
                              }
                            >
                              Reject
                            </Link>{" "}
                          </>
                        )}
                        <Link to="#" className="btn details">
                          View Details
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>

        {/* Mobile View */}
        <div className="table-mobile">
          {providersData.map((provider) => (
            <div key={provider.id} className="mb-4 mobile-card">
              <div className="top-section">
                <div className="img">
                  <img
                    src={decodeBase64Image(provider.profileImage)}
                    alt="ProviderPic"
                  />
                  <p className="title mt-2">{provider.fullName}</p>
                </div>
                <div className="appointment-content">
                  <p className="title">Status: {provider.status}</p>
                </div>
              </div>
              <div className="middle">
                <div className="spec">
                  <p className="title tight">Medical License</p>
                  <p className="tight">{provider.medLicense}</p>
                </div>
                {/* <div className="date">
                  <p className="title tight">Date & Time</p>
                  <p className="tight">{provider.dateTime}</p>
                </div> */}
                <div className="rating">
                  <p className="title tight">Phone Number</p>
                  <p className="tight">{provider.phoneNo}</p>
                </div>
              </div>
              <div className="bottom">
                <Link to="/appointment-details" className="details">
                  View Details
                </Link>
                <button className="join">Approve</button>
                <button className="cancel">Reject</button>
              </div>
            </div>
          ))}
        </div>
        {/* Mobile View Ends */}
      </div>
    </>
  );
};

export default Providers;
