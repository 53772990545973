import React from 'react'
import './get-started.style.css';
import { useNavigate } from 'react-router-dom'

const GetStarted = () => {
    const navigate = useNavigate();

    const navigateToStarted = () => {
        navigate('/specializations');
    }

    return (
        <div className='get-started'>
            <div className='container get-started-container p-5'>
                <div className='content-text'>
                    <h3 className='mb-3'>READY TO GET STARTED?</h3>
                    <h1 className='mb-5'>Tap in to the best and most <br />affordable virtual healthcare</h1>
                    <button className="btn btn-find-doctor" onClick={navigateToStarted}>Get Started</button>
                </div>
            </div>
        </div>
    )
}

export default GetStarted;