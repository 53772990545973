import React from "react";
import { Link } from "react-router-dom";
import './footer.style.css';

const Footer = () => {
    return (
        <footer className="footer-section text-light">
        <div className="container pb-3">
              <h1 className="text-center justify-content-center align-items-center"><img className="logo2image" src="/assets/logo2.png" alt="logo"/></h1>
          <div className="row gy-1 gx-1 py-4 center-align">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2>Product</h2>
              <div>
                <ul className="d-inline-block">
                  <Link className="footer-links" to="/how-it-works"><li>How it Works</li></Link>
                  <Link className="footer-links" to="/what-we-provide"><li>What we provide</li></Link>
                  <Link className="footer-links" to="/become-a-provider"><li>Become a provider</li></Link>
                  <Link className="footer-links" to="/all-doctor"><li>Our Doctors</li></Link>
                  <Link className="footer-links" to="/blogs"><li>Blogs</li></Link>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2>Company</h2>
              <div>
                <ul className="d-inline-block">
                  <Link className="footer-links" to="/about-us"><li>About Us</li></Link>
                  <Link className="footer-links" to="/terms-of-service"><li>Terms Of Service</li></Link>
                  <Link className="footer-links" to="/privacy-policy"><li>Privacy Policy</li></Link>
                  <Link className="footer-links" to="/contact-us"><li>Contact Us</li></Link>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2>Resources</h2>
              <div>
                <ul className="d-inline-block">
                  <Link className="footer-links" to="#"><li>Terms and Conditions for Providers</li></Link>
                  <Link className="footer-links" to="#"><li>Terms and Conditions for Patients</li></Link>
                  <Link className="footer-links" to="#"><li>Help Center</li></Link>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2>Social Media</h2>
              <div>
                <ul className="d-inline-block">
                <Link className="footer-links" to="#"><li>Facebook</li></Link>
                <Link className="footer-links" to="#"><li>Instagaram</li></Link>
                <Link className="footer-links" to="#"><li>Youtube</li></Link>
                <Link className="footer-links" to="#"><li>Twitter</li></Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer;