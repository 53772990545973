import React from 'react';

const PrivacyBanner = () => {
    return (
        <section id="terms-banner">
        <div className="container terms-container" data-aos="fade-up" data-aos-delay="150">
            <h2>Privacy Policy</h2>
        </div>
    </section>
    )
}

export default PrivacyBanner;