import React from "react";
import "./diseases-treated.style.css";
import { FaPen } from "react-icons/fa";
import DiseasesModal from "./diseases-treated-modal.component";

const DiseasesTreated = () => {
  return (
    <>
      <div className="card custom-card">
        <div className="card-header d-flex justify-content-between mb-1">
          <h5 className="card-title align-self-center">Diseases Treated</h5>
          <div className="header-button align-self-center">
            <button  type="button"
                className="btn p-1 ms-1"
                data-bs-toggle="modal"
                data-bs-target="#DiseasesModal">
              <FaPen />
            </button>
          </div>
        </div>
        <div className="card-body" style={{ textAlign: "left" }}>
          <p className="diseases-treated">Typhoid Fever</p>
          <p className="diseases-treated">Fever</p>
          <p className="diseases-treated">Flu</p>
          <p className="diseases-treated">Cold</p>
        </div>
      </div>
      <DiseasesModal />
    </>
  );
};

export default DiseasesTreated;
