import React from "react";
import "./greetings-card.style.css";
import { useSelector } from "react-redux";

const GreetingCard = () => {
  // Get current date
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()} ${currentDate.toLocaleString(
    "default",
    { month: "short" }
  )}, ${currentDate.getFullYear()}`;

  // Determine greeting based on the hour
  const currentHour = currentDate.getHours();
  let greetingMessage;
  if (currentHour < 12) {
    greetingMessage = "Good Morning";
  } else if (currentHour < 18) {
    greetingMessage = "Good Afternoon";
  } else {
    greetingMessage = "Good Evening";
  }

  const { user } = useSelector((state) => state.users);

  return (
    <div className="greeting-card">
      <div className="content-card ps-3 d-flex justify-content-between">
        <div>
          <h2>{greetingMessage}</h2>
          <span>{user.username}</span>
        </div>
        <div className="greeting-date">
          <p>{formattedDate}</p>
        </div>
      </div>
    </div>
  );
};

export default GreetingCard;
