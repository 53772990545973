import React, { useState, useEffect } from "react";
import "../hoc.style.css";
import { useNavigate } from "react-router-dom";
import PatientNavbar from "../../../patients-components/patient-navbar/patient-navbar.component";
import PatientSidebar from "../../../patients-components/patient-sidebar/patient-sidebar.component";
import MobileNavbar from "../../../patients-components/mobile-navbar/mobile-navbar.component";
import MobileMenu from "../../../patients-components/mobile-navbar/mobile-menu.component";

const PatientLayout = ({ Component }) => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
  }, [navigate]);


  const toggleMenu = () => {
    setMenuVisible(prevMenuVisible => !prevMenuVisible);
  };



  return (
    <>
      <div className="d-flex hoc-container">
        <div className="d-none d-md-flex">
          <PatientSidebar />
        </div>
        <div className="second-col">
        <MobileNavbar toggleMenu={toggleMenu} />
          <PatientNavbar />
          <div className="main-wrapper">
            <Component />
          </div>
        </div>
      </div>
      {menuVisible && <MobileMenu toggleMenu={toggleMenu} />}
    </>
  );
};

export default PatientLayout;
