import React from "react";
import './appointments.style.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import UpcomingAppointments from "./upcoming-appointments/upcoming-appointments.component";
import CancelledAppointment from "./cancelled-appointments/cancelled-appointments.component";
import CompletedAppointment from "./completed-appointment/completed-appointment.component";

const Appointments = () => {
    return (
        <Tabs>
            <div className="list-container">
                <TabList className="px-3">
                    <Tab>Upcoming</Tab>
                    <Tab>Completed</Tab>
                    <Tab>Cancelled</Tab>
                </TabList>

                <TabPanel>
                    <UpcomingAppointments />
                </TabPanel>
                <TabPanel>
                <CompletedAppointment />
                </TabPanel>
                <TabPanel>
                    <CancelledAppointment />
                </TabPanel>
            </div>
        </Tabs>
    )
}

export default Appointments;