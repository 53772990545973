import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  signupUser,
  otpVerification,
  resendOTPCode,
  forgotPasswordSend,
  passwordChanged,
  tokenRefresh,
  profileCompletion,
  Qualification,
  Specialization
} from "../actions/users.actions";
import { toast } from "react-toastify";

const initialState = {
  user: {},
  email: "",
  isLoading: false,
  isError: false,
  isLogin: false,
  isOTPSucceed: false,
  isSuccessfull: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loginFalse(state, action) {
      state.isLogin = false;
      state.isOTPSucceed = false;
    },
    logoutUser(state, action) {
      state.isLogin = false;
    },
    updateUser(state, action) {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {

    //OTP-Verification
    builder.addCase(otpVerification.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(otpVerification.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isOTPSucceed = true;
      state.isLogin = true;
      state.isSuccessfull = true;
      state.user = action.payload;
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(otpVerification.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      toast.error("Invalid OTP Code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });

    //Resend OTP Code
    builder.addCase(resendOTPCode.pending, (state, action) => {
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(resendOTPCode.fulfilled, (state, action) => {
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(resendOTPCode.rejected, (state, action) => { });

    //Forgot Password
    builder.addCase(forgotPasswordSend.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(forgotPasswordSend.fulfilled, (state, action) => {
      state.isOTPSucceed = true;
      state.isLoading = false;
      state.user.email = action.payload.email;
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(forgotPasswordSend.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      toast.error("User not found", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });

    //Password Changed
    builder.addCase(passwordChanged.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(passwordChanged.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessfull = true;
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(passwordChanged.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      toast.error("Invalid OTP Code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });

    //Refresh Token
    builder.addCase(tokenRefresh.pending, (state, action) => {
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(tokenRefresh.fulfilled, (state, action) => {
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(tokenRefresh.rejected, (state, action) => { });

    //Sign-Up
    builder.addCase(signupUser.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(signupUser.fulfilled, (state, action) => {
      state.isSuccessfull = true;
      state.user = action.payload;
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(signupUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;

      toast.error("Record you added is already exists.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });

    //Complete Profile
    builder.addCase(profileCompletion.pending, (state, action) => {
      console.log(
        "🚀 ~ file: users.reducer.js:13 ~ builder.addCase ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(profileCompletion.fulfilled, (state, action) => {
      console.log(
        "🚀 ~ file: users.reducer.js:16 ~ state, action:",
        state,
        action
      );
    });
    builder.addCase(profileCompletion.rejected, (state, action) => { });

  },
});
export const { loginFalse, logoutUser, updateUser } = usersSlice.actions;
export default usersSlice.reducer;
