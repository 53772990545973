import React from 'react'
import BlogBanner from '../blogs-detail/blog-banner/blog-banner.component';
import CTA from '../common-components/call-to-action/cta.component';
import BlogsCards from './blog-cards/blog-cards.component';

const AllBlogs = () => {
    return (
        <>
            <BlogBanner />
            <BlogsCards />
            <CTA />
        </>
    )
}

export default AllBlogs;