import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import specilaization from "../../shared/specializations.json";

const SpecializationCards = () => {
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState("");

  console.log(
    specilaization.filter((data) =>
      data.name.toLowerCase().includes(query.toLowerCase())
    )
  );
  return (
    <section id="main-subjects">
      <div className="container mt-5 mb-5">
        <div className="content text-center">
          <h1>Care for you and your family</h1>
        </div>
        <div className="">
          <div className="col-lg col-md">
            <div className="input-group search-group">
              <button className="btn btn-outline-secondary" type="submit">
                <FaSearch />
              </button>
              <input
                className="form-control"
                type="search"
                placeholder="Search specialization"
                aria-label="Search"
                onChange={(e) => {
                  setQuery(e.targe.name);
                  console.log("Query Updated:", e.target.name);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mt-5 g-3">
          {/* {specilaization.slice(0, limit).map((data) => {
            return (
              <>
                <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-center">
                  <Link to="/all-doctor">
                    <div className="card servicesCards">
                      <div className="image">
                        <img src={data.image} alt="specializations" />
                      </div>
                      <p className="card-title"> {data.name} </p>
                      <p>{data.count}</p>
                    </div>
                  </Link>
                </div>
              </>
            );
          })} */}
          {specilaization.slice(0, limit).map((data) => (
                <div key={data.name} className="d-flex justify-content-center col-lg-2 col-md-4 col-sm-6">
                <Link to="/all-doctor">
                  <div className="card servicesCards">
                    <div className="d-flex align-items-center">
                      <img
                        src={data.image}
                        alt="specializations"
                        className="card-image"
                      />
                      <div className="card-text-content">
                        <p className="card-title tight"> {data.name} </p>
                        <p className="tight">{data.count}</p>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              ))}
        </div>
        <div className="text-center mt-5">
          <button
            className="btn btn-find-doctor"
            onClick={() => setLimit(limit + 6)}
          >
            Load More
          </button>
        </div>
      </div>
    </section>
  );
};

export default SpecializationCards;
