import React from "react";
import { Link } from "react-router-dom";
import './quality-care.style.css';

const QualityCare = () => {
    return (
        <section id="qualit-care">
            <div className="container mt-5 mb-5">
                <h1 className="text-center">Your doctor is always only one tap away</h1>
                <div className="row pt-5">
                    <div className="col-lg-6  order-2 order-lg-1">
                        <div className="content">
                            <h2>We believe in high quality care</h2>
                            <p>
                                Each and every one of our
                                Medical doctors are licensed,
                                board certified, and verified by us.
                                Their experience is matched by their
                                enthusiasm for delivering excellent care
                                in the newest medium in the industry.
                                This means the doctor you choose is
                                with you every step of the way.
                            </p>
                            <div className="d-flex">
                                <Link to="/all-doctor" className="link-button">Find Your Doctor</Link>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 order-1 order-lg-2 img" style={{ backgroundImage: `url(${"/assets/webImages/qualitycare.jpg"})` }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                </div>
            </div>
        </section>
    )
}

export default QualityCare;