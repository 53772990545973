import React from "react";
import "./availibility-timing.style.css";
import { FaPen } from "react-icons/fa";

const AvailibilityTiming = () => {
    return (
        <div className="card custom-card">
        <div className="card-header d-flex justify-content-between mb-1">
          <h5 className="card-title align-self-center">Availability Timing</h5>
          <div className="header-button align-self-center">
            <button  type="button"
                className="btn p-1 ms-1"
                data-bs-toggle="modal"
                data-bs-target="#">
              <FaPen />
            </button>
          </div>
        </div>
        <div className="card-body" style={{ textAlign: "left" }}>
          
        </div>
      </div>
    )
}

export default AvailibilityTiming;