import React, { useEffect, useState } from "react";
import "./dashboard-diasease.style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import diseases from "../../../components/shared/diseases.json";
import { Link } from "react-router-dom";

const DashboardDiseases = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 690);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="Diseases-cards">
        <div className="card-body">
          <div className="mb-3">
            <div className="">
            <div className="d-flex justify-content-between">
              <h4 className="card-title-primary">Diseases</h4>
              <Link to="/diseases">View All</Link>
              </div>
             
              <div className="row">
                {isMobile ? (
                  <Slider {...settings} className={isMobile ? 'hide-arrows' : ''}>
                    {diseases.slice(0, 6).map((data) => (
                      <div
                        key={data.name}
                        className="d-flex justify-content-center"
                      >
                        <div className="card diseasesCardsdashboard">
                          <img src={data.image} alt="disease" />
                          <p className="card-title"> {data.name} </p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="row">
                    {diseases.slice(0, 4).map((data) => (
                      <div
                        key={data.name}
                        className="col-lg-3 col-md-3 col-sm-2 d-flex"
                      >
                        <div className="card diseasesCardsdashboard">
                          <img src={data.image} alt="disease" />
                          <p className="card-title"> {data.name} </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardDiseases;
