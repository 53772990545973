import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./navbar.style.css";
import NotificationList from "../../components/shared/allnotifications.json";
import { FaBell } from "react-icons/fa";
import { useSelector } from "react-redux";

const Navbar = () => {
  const { user } = useSelector((state) => state.users);
  const { isLogin } = useSelector((state) => state.users);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 90) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const [colorChange, setColorchange] = useState(false);
  // const changeNavbarColor = () => {
  //   if (window.scrollY >= 90) {
  //     setColorchange(true);
  //   } else {
  //     setColorchange(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", changeNavbarColor);

  //   return () => {
  //     window.removeEventListener("scroll", changeNavbarColor);
  //   };
  // }, []);

  let activeStyle = {
    textDecoration: "underline",
    color: "#545e6f",
  };


  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isBellIconClicked = event.target.closest(".bell-icon");
      if (
        !event.target.closest(".notification-dropdown") ||
        (isBellIconClicked && isOpen)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light ${isSticky ? "sticky" : ""}`}
        // className={
        //   colorChange
        //     ? "navbar colorChange navbar-expand-lg navbar-light "
        //     : "navbar navbar navbar-expand-lg navbar-light "
        // }

      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img className="logoimage" src="/assets/logo.png" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="ul navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/specializations"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Specializations
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/all-doctor"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Our Doctors
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/blogs"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/about-us"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  About Us
                </NavLink>
              </li>
            </ul>

            {!isLogin ? (
            <div className="auth-buttons">
              <button type="button" className="btn btn-outline-info me-2">
                <Link to="/login" className="login-nav-btn">
                  Login
                </Link>
              </button>
              <button type="button" className="btn btn-success">
                <Link to="/signup" className="nav-btn">
                  SignUp
                </Link>
              </button>
            </div>
          ):(
            <>
            <div className="notification-dropdown">
              <FaBell onClick={() => setIsOpen(!isOpen)} />
              {isOpen && (
                <div className="dropdown-content">
                  <div className="d-flex justify-content-between">
                    <h5 className="notification-title">Notifications</h5>
                    <Link to="/notifications">
                      <p className="notification-all">View All</p>
                    </Link>
                  </div>
                  {NotificationList.map((notification) => (
                    <div
                      key={notification.id}
                      className={`notification-item ${
                        notification.isUnread ? "unread" : ""
                      }`}
                    >
                      <img
                        src={notification.image}
                        alt="Notification Icon"
                        className="notification-image"
                      />
                      <div className="notification-details">
                        <div className="notification-text-container">
                          <small className="notification-text">
                            {notification.text}
                          </small>
                          <div>
                            <small>{notification.time}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="position-relative dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="icon-container">
                  <img src="/assets/AsadWali.jpg" alt="userImage" />
                  <div className="status-circle"></div>
                </div>
                <span className="username">
                  {user.username} <span className="dropdown-arrow"></span>
                </span>
              </a>

              <ul className="dropdown-menu" aria-labelledby="userDropdown">
                <li>
                  <Link className="dropdown-item" to="/profile">
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/settings">
                    Settings
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="/login">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            </>
          )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
