import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import NotificationList from "../../../src/components/shared/allnotifications.json";
import { useLocation } from "react-router-dom";

const AdminNavbar = () => {
  const location = useLocation();

  let headerTitle = "Dashboard";

  switch (location.pathname) {
    case "/dashboard":
      headerTitle = "Dashboard";
      break;
    case "/allusers":
      headerTitle = "All Users";
      break;
      
    // case "/settings":
    //   headerTitle = "Settings";
    //   break;
  }

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isBellIconClicked = event.target.closest(".bell-icon");
      if (
        !event.target.closest(".notification-dropdown") ||
        (isBellIconClicked && isOpen)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <header className="header d-none d-md-flex">
      <div className="header-title me-auto">{headerTitle}</div>
      <div className="notification-dropdown">
        <FaBell onClick={() => setIsOpen(!isOpen)} />
        {/* <div className="notification-badge">10</div> */}

        {isOpen && (
          <div className="dropdown-content">
            <div className="d-flex justify-content-between">
              <h5 className="notification-title">Notifications</h5>
              <Link to="/notifications">
                <p className="notification-all">View All</p>
              </Link>
            </div>
            {NotificationList.map((notification) => (
              <div
                key={notification.id}
                className={`notification-item ${
                  notification.isUnread ? "unread" : ""
                }`}
              >
                <img
                  src={notification.image}
                  alt="Notification Icon"
                  className="notification-image"
                />
                <div className="notification-details">
                  <div className="notification-text-container">
                    <small className="notification-text">
                      {notification.text}
                    </small>
                    <div>
                      <small>{notification.time}</small>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="position-relative dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="userDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="icon-container">
            <img src="/assets/AsadWali.jpg" alt="userImage" />
            <div className="status-circle"></div>
          </div>
          <span className="username">
            Asad Wali <span className="dropdown-arrow"></span>
          </span>
        </a>

        <ul className="dropdown-menu" aria-labelledby="userDropdown">
          {/* <li>
            <Link className="dropdown-item" to="/settings">
              Settings
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li> */}
          <li>
            <Link className="dropdown-item" to="/login">
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default AdminNavbar;
