import React from "react";
import { Link } from "react-router-dom";
import { MdDashboard, MdEditNote } from "react-icons/md";
import { FaUserAlt, FaCalendarAlt, FaListAlt, FaUsers } from "react-icons/fa";

const AdminSidebar = () => {
    const isActive = (path) => window.location.pathname === path;

    return (
      <div className="sidebar">
        <div className="mb-4">
        <Link to="/">
          <img className="iconlogoimage" src="/assets/logo2.png" alt="logo" />
          <img className="iconlogoimage" src="/assets/iconlogo2.png" alt="logo" />
          </Link>
        </div>
        <nav className="d-flex flex-column gap-2">
          <Link className={`sidebar-link ${isActive('/dashboard') ? 'active' : ''}`} to="/dashboard">
            <MdDashboard />
            <span className="sidebar-text d-none d-lg-inline-block">
              Dashboard
            </span>
          </Link>
  
          <Link className={`sidebar-link ${isActive('/allusers') ? 'active' : ''}`} to="/allusers">
            <FaUserAlt />
            <span className="sidebar-text d-none d-lg-inline-block">All Users</span>
          </Link>

        </nav>
      </div>
    );
}

export default AdminSidebar;