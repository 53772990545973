import React from 'react';
import Bio from './bio/bio.component';
import Instructions from './doctor-instruction/doctor-instruction.component';
import ProfileForm from './profile-form/profile-form.component';
import Education from './educations/educations.component';
import WorkExperience from './work-experience/work-experience.component';
import DiseasesTreated from './diseases-treated/diseases-treated.component';
import AvailibilityTiming from './availibility-timing/availibility-timing.component';

const Profile = () => {
    return (
        <div className='row'>
            <div className='col-lg-8'>
                <Bio />
                <Education />    
                <WorkExperience />
                <DiseasesTreated />
                <AvailibilityTiming />
                <Instructions />
            </div>
            <div className="col-lg-4">
                <ProfileForm />
            </div>
        </div>
    )
}

export default Profile;