import React from "react";

const ProviderUpcomingAppointment = () => {
  return (
    <div className="card simple-card card-border">
      <div className="card-body">
        <div className="mb-3">
          <div className="align-self-center">
            <h5>Upcoming Appointment</h5>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  className="table-img"
                  src="/assets/AsadWali.jpg"
                  alt="patient"
                />
                <h6 className="card-title">Asad Wali</h6>
              </div>
              <div>
                <p>View Patient Profile</p>
              </div>
            </div>

            <p className="card-subtitle mt-3">
              Online | Today at 3:00 pm | 48 Minutes left
            </p>
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <button className="btn btn_join">
          <i class="fas fa-check"></i> Join
        </button>
        <button href="/" className="btn btn-outline ms-3">
          <i class="fas fa-times"></i> Cancel
        </button>
      </div>
    </div>
  );
};

export default ProviderUpcomingAppointment;
